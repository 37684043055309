import { IRangeData } from '@local/src/Components/Inputs/Range/Common/IRangeData'
import { useCallback, useState } from 'react'
import { IRange } from '@local/src/Models/Misc/Range.model'
import { mapIRangeFromUrl, mapIRangeToUrl, useUrlSync } from '@local/src/Utils/helpers/Url/useUrlSync'
import { IUrlSyncedData } from '@local/src/Utils/helpers/Url/IUrlSyncedData'

export interface IWhiteCollarFilterData extends IUrlSyncedData, IRangeData {
  reset: () => void
}

const WHITE_COLLAR_RANGE_MIN = 0
const WHITE_COLLAR_RANGE_MAX = 10000

const DEFAULT_VALUE = { from: WHITE_COLLAR_RANGE_MIN, to: WHITE_COLLAR_RANGE_MAX } as IRange

export const useWhiteCollarFilter = (): IWhiteCollarFilterData => {
  const [value, setValue] = useState<IRange>(DEFAULT_VALUE)
  const { wasLoadedFromUrl, updateValue } = useUrlSync<IRange>(
    'whiteCollar',
    setValue,
    (r) => mapIRangeToUrl(r, DEFAULT_VALUE),
    (s) => mapIRangeFromUrl(s, DEFAULT_VALUE),
    []
  )

  const reset = useCallback(() => {
    updateValue(DEFAULT_VALUE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    value,
    bounds: DEFAULT_VALUE,
    setValue: updateValue,
    wasLoadedFromUrl,
    reset,
  }
}
