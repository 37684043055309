import React from 'react'
import { Box, Button, Checkbox, Collapse, Divider, Stack, Typography } from '@mui/material'
import { Clear, KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material'
import { CountySelectionInput } from '@local/src/Components/Inputs/Selection/CountySelectionInput'
import { MunicipalitySelectionInput } from '@local/src/Components/Inputs/Selection/MunicipalitySelectionInput'
import { WorkplaceTypeSelectionInput } from '@local/src/Components/Inputs/Selection/WorkplaceTypeSelectionInput'
import { TrrIndustrySelectionInput } from '@local/src/Components/Inputs/Selection/TrrIndustrySelectionInput'
import { NationalManagerSelectionInput } from '@local/src/Components/Inputs/Selection/NationalManagerSelectionInput'
import { LocalManagerSelectionInput } from '@local/src/Components/Inputs/Selection/LocalManagerSelectionInput'
import { LocalContactSelectionInput } from '@local/src/Components/Inputs/Selection/LocalContactSelectionInput'
import { EligibilitySelectionInput } from '@local/src/Components/Inputs/Selection/EligibilitySelectionInput'
import { WhiteCollarRangeInput } from '@local/src/Components/Inputs/Range/WhiteCollarRangeInput'
import { PayrollRangeInput } from '@local/src/Components/Inputs/Range/PayrollRangeInput'
import { WorkplaceStatusSelectionInput } from '@local/src/Components/Inputs/Selection/WorkplaceStatusSelectionInput'

import { IWorkplaceSearchFilterData } from './Hooks/Filters/useWorkplaceSearchFilters'
import { FreeTextSearchField } from './FreeTextSearchField'



export interface IWorkplaceSearchFilterProps {
    filterData: IWorkplaceSearchFilterData,
    isSearching: boolean,
    onSearch: () => void
}

export const WorkplaceSearchFilter = ({ filterData, isSearching, onSearch }: IWorkplaceSearchFilterProps) => {
    const { value: showFilters, setValue: setShowFilters } = filterData.filterVisibilityData

    const resetFilters = () => {
        filterData.resetFilters()
    }

    return (
        <Stack marginBottom={2}>
            <Box display='grid' gridTemplateColumns='repeat(4, 1fr)' gap={2} alignItems='center'>
                <FreeTextSearchField {...filterData.freeTextSearchData} onSearch={onSearch} disabled={isSearching} />
                <Button
                    variant='text'
                    onClick={() => setShowFilters(!showFilters)}
                    endIcon={showFilters ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    sx={{ gridColumn: '3' }}
                >
                    Förfina din sökning
                </Button>
            </Box>
            <Collapse in={showFilters}>
                <Box display='grid' gridTemplateColumns='repeat(4, 1fr)' gap={2} marginBottom={2} marginTop={2}>
                    <CountySelectionInput {...filterData.countyData} disabled={isSearching} />
                    <MunicipalitySelectionInput {...filterData.municipalityData} disabled={isSearching} />
                    <WorkplaceTypeSelectionInput {...filterData.workplaceTypeData} disabled={isSearching} />
                    <TrrIndustrySelectionInput {...filterData.trrIndustryData} disabled={isSearching} />
                    <NationalManagerSelectionInput {...filterData.nationalManagerData} disabled={isSearching} />
                    <LocalManagerSelectionInput {...filterData.localManagerData} disabled={isSearching} />
                    <LocalContactSelectionInput {...filterData.localContactData} disabled={isSearching} />
                    <EligibilitySelectionInput {...filterData.eligibilityData} disabled={isSearching} />
                    <WhiteCollarRangeInput {...filterData.whiteCollarData} disabled={isSearching} />
                    <PayrollRangeInput {...filterData.payrollData} disabled={isSearching} />
                    <WorkplaceStatusSelectionInput {...filterData.workplaceStatusData} disabled={isSearching} />

                    <Stack direction='row' alignItems='center'>
                        <Checkbox checked={filterData.isLocallyImportantData.value} onChange={(_, checked) => filterData.isLocallyImportantData.setValue(checked)} disabled={isSearching} />
                        <Typography variant='body1'>Lokalt strategisk</Typography>
                    </Stack>

                    <Stack direction='row' sx={{ gridColumn: '4', justifySelf: 'end' }} gap={1}>
                        <Button variant='contained' startIcon={<Search />} onClick={onSearch} disabled={isSearching}>Sök</Button>
                        <Button variant='outlined' startIcon={<Clear />} onClick={resetFilters} disabled={isSearching}>Rensa</Button>
                    </Stack>
                </Box>
                <Divider />
            </Collapse>
        </Stack >
    )
}