import { formatOrgNr, formatUtcDate } from '@local/src/Utils/helpers'
import { tableTooltip } from '@local/src/Utils/helpers/tooltips'
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import React from 'react'

import { ICase } from '../Case.types'

export const casesColumns = (deleteFunc: (arendeId: string) => void, arendeIdUnderDeletion: string): GridColDef<ICase>[] => {

  return [
    {
      headerName: 'Namn',
      field: 'klientNamn',
      flex: 1,
      valueGetter: ({ row }) => row?.klient?.klientNamn,
      renderCell: ({ row }) => tableTooltip(row.klient.klientNamn),
    },
    {
      headerName: 'Ärendenummer',
      field: 'arendeNummer',
      flex: 1,
      renderCell: ({row}) => tableTooltip(row?.arendeNummer)
    },
    {
      headerName: 'Org.nr',
      field: 'foretagOrganisationsnummer',
      flex: 1,
      valueGetter: ({ row }) => row?.foretag?.foretagOrganisationsnummer,
      renderCell: ({ row }) => tableTooltip(formatOrgNr(row.foretag.foretagOrganisationsnummer)),
    },
    {
      headerName: 'Arbetsställe',
      field: 'foretagNamn',
      flex: 1,
      valueGetter: ({ row }) => row?.foretag?.foretagNamn,
      renderCell: ({ row }) => tableTooltip(row?.foretag?.foretagNamn),
    },
    {
      headerName: 'Team',
      field: 'team.namn',
      flex: 1,
      valueGetter: ({ row }) => row?.team?.namn,
      renderCell: ({ row }) => tableTooltip(row?.team?.namn),
    },
    {
      headerName: 'Rådgivare',
      field: 'radgivarNamn',
      flex: 1,
      valueGetter: ({ row }) => row?.radgivare?.radgivarNamn,
      renderCell: ({ row }) => tableTooltip(row?.radgivare?.radgivarNamn),
    },
    {
      headerName: 'Komplett',
      field: 'ansokanKomplettDatum',
      flex: 1,
      renderCell: ({ row }) => tableTooltip(formatUtcDate(row?.ansokanKomplettDatum)),
    },
    {
      headerName: 'Uppsägning',
      field: 'uppsagningsDatum',
      flex: 1,
      renderCell: ({ row }) => tableTooltip(formatUtcDate(row?.uppsagningsDatum)),
    },
    {
      headerName: 'Avslutskod',
      field: 'avslutsKod',
      flex: 1,
      valueGetter: ({ row }) => row?.avslutsInformation?.avslutsKod,
      renderCell: ({ row }) => tableTooltip(row?.avslutsInformation?.avslutsKod),
    },
    {
      headerName: 'Avslutsdatum',
      field: 'avslutsInformation.avslutsDatum',
      flex: 1,
      valueGetter: ({ row }) => row?.avslutsInformation?.avslutsDatum,
      renderCell: ({ row }) => tableTooltip(formatUtcDate(row?.avslutsInformation?.avslutsDatum)),
    },
    {
      headerName: '',
      field: 'remove',
      renderCell: ({ row }) => {
        return (
          <>
          { arendeIdUnderDeletion === row.arendeId ? (
            <CircularProgress />
          ) : (
            <Tooltip title='Ta bort ärendet från projektet'>
              <IconButton onClick={() => deleteFunc(row.arendeId)}>
                <DeleteIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          )}
          </>
        )
      },
      flex: 1,
      maxWidth: 60,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ]
}


export interface CaseColumnVisibility extends GridColumnVisibilityModel {
  klientNamn: boolean
  foretagOrganisationsnummer: boolean
  foretagNamn: boolean
  arendeNummer: boolean
  radgivarNamn: boolean
  ansokanKomplettDatum: boolean
  uppsagningsDatum: boolean
  avslutsKod: boolean
  avslutsDatum: boolean
  remove: boolean
}

export const getCaseColumnVisibilityModel = (canEditCase: boolean): CaseColumnVisibility => ({
  klientNamn: canEditCase,
  foretagOrganisationsnummer: true,
  foretagNamn: true,
  arendeNummer: true,
  radgivarNamn: true,
  ansokanKomplettDatum: true,
  uppsagningsDatum: true,
  avslutsKod: true,
  avslutsDatum: true,
  remove: canEditCase
})