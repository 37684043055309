import React, { useState } from 'react'
import { Stack, TextField, Box, Slider, IconButton, Popover, Typography, InputAdornment } from '@mui/material'
import { Expand } from '@mui/icons-material'

import { IRangeData } from './IRangeData'



interface Props extends IRangeData {
    label: string
    unit?: string
    disabled?: boolean
}

export const RangeInput = ({ label, value, bounds, setValue, unit, disabled }: Props) => {
    const [sliderParent, setSliderParent] = useState<Element>(null);

    const handleOnInputChange = (newValue: string, fromOrTo: 'from' | 'to') => {
        const parsedValue = Number(newValue)
        if (isNaN(Number(newValue)))
            return

        if (fromOrTo === 'from')
            setValue({ ...value, from: Number(parsedValue) })
        else if (fromOrTo === 'to')
            setValue({ ...value, to: Number(parsedValue) })
    }

    const orderFromAndTo = () => {
        const min = Math.min(value.from, value.to)
        const max = Math.max(value.from, value.to)
        setValue({ from: min, to: max })
    }

    return (
        <Stack direction='column' rowGap={0.5}>
            <Typography fontSize={12.5}>{label}</Typography>
            <Stack direction='row' justifyContent='space-between' columnGap={2}>
                <TextField
                    value={value.from}
                    onChange={e => handleOnInputChange(e.currentTarget.value, 'from')}
                    onBlur={orderFromAndTo}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter')
                            e.currentTarget.blur()
                    }}
                    inputProps={{ style: { textAlign: 'center', height: 0 } }}
                    InputProps={{
                        endAdornment: unit !== undefined && <InputAdornment position='end'>{unit}</InputAdornment>,
                        onKeyDown: (e) => {
                            if (e.key === 'Enter')
                                e.currentTarget.blur()
                        }
                    }}
                    onFocus={e => e.target.select()}
                    disabled={disabled}
                />

                <IconButton size='small' color='primary' onClick={e => setSliderParent(e.currentTarget)} disabled={disabled} >
                    <Expand sx={{ transform: 'rotate(90deg)' }} />
                </IconButton>
                <Popover
                    open={sliderParent !== null}
                    onClose={() => setSliderParent(null)}
                    anchorEl={sliderParent}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <Box width={300} paddingX={3} paddingY={1} display='flex'>
                        <Slider
                            min={bounds.from}
                            max={bounds.to}
                            value={[value.from, value.to]}
                            disableSwap
                            onChange={(_, values: number[]) => {
                                setValue({ from: values[0], to: values[1] })
                            }}
                            disabled={disabled} />
                    </Box>
                </Popover>

                <TextField
                    value={value.to}
                    onChange={e => handleOnInputChange(e.currentTarget.value, 'to')}
                    onBlur={orderFromAndTo}
                    inputProps={{ style: { textAlign: 'center', height: 0 } }}
                    InputProps={{
                        endAdornment: unit !== undefined && <InputAdornment position='end'>{unit}</InputAdornment>,
                        onKeyDown: (e) => {
                            if (e.key === 'Enter')
                                e.currentTarget.blur()
                        }
                    }}
                    onFocus={e => e.target.select()}
                    disabled={disabled}
                />
            </Stack>
        </Stack>
    )
}