import React from 'react'
import { useParams } from 'react-router-dom'
import { ITabItem, TabsContainer } from '@local/Scenes/Home/Tabs/TabsContainer/TabsContainer'
import {
  activitiesUrl,
  companyContactUrl,
  companyDetailsUrl,
  companyGroupUrl,
  companyProjectUrl,
} from '@local/src/basename'

const CompanyTabs = () => {
  const { companyGuid } = useParams<{ companyGuid: string }>()

  const tabItems: ITabItem[] = [
    {
      heading: 'Händelser',
      urls: [
        `${companyDetailsUrl}/:companyGuid/handelser/:activityGuid/`,
        `${companyDetailsUrl}/:companyGuid/handelser/`,
        `${companyDetailsUrl}/:companyGuid/`,
      ],
      exact: true,
      route: activitiesUrl(companyGuid),
    },
    {
      heading: 'Kontaktpersoner',
      urls: [`${companyDetailsUrl}/:companyGuid/kontakt/`],
      route: companyContactUrl(companyGuid),
    },
    {
      heading: 'Projekt',
      urls: [`${companyDetailsUrl}/:companyGuid/projekt/`],
      route: companyProjectUrl(companyGuid),
    },
    {
      heading: 'Anslutna i koncernen',
      urls: [`${companyDetailsUrl}/:companyGuid/koncern/`],
      route: companyGroupUrl(companyGuid),
    },
  ]

  return <TabsContainer items={tabItems} />
}

export default CompanyTabs
