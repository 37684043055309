import React from "react"
import { IWorkplaceEligibility } from "@local/src/Models/Domain/WorkplaceEligibility.model"

import { ISelectionData } from "./Common/ISelectionData"
import { SelectionInput } from "./Common/SelectionInput"

interface Props extends ISelectionData<IWorkplaceEligibility> {
    disabled?: boolean
}

export const EligibilitySelectionInput = React.memo((props: Props) => {
    return <SelectionInput
        {...props}
        name="Kund"
        getDisplayValue={o => o.description}
    />
})