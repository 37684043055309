import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { companyDetailsUrl } from '@local/src/basename'
import Activities from '@local/Scenes/Company/CompanyTabs/Activities/Activities.container'
import HandleActivity from '@local/Scenes/Company/CompanyTabs/Activities/HandleActivity/HandleActivity.container'
import DetailedActivity from '@local/Scenes/Company/CompanyTabs/Activities/DetailedActivity/DetailedActivity'
import { Box } from '@mui/material'
import HandleContact from '@local/Scenes/Company/CompanyTabs/Contacts/HandleContact/HandleContact'
import Contacts from '@local/Scenes/Company/CompanyTabs/Contacts/Contacts.container'
import DetailedContact from '@local/Scenes/Company/CompanyTabs/Contacts/DetailedContact/DetailedContact'
import HandleProject from '@local/Scenes/Company/CompanyTabs/Projects/HandleProject/HandleProject'
import Projects from '@local/Scenes/Company/CompanyTabs/Projects/Projects.container'
import DetailedProject from '@local/Scenes/Company/CompanyTabs/Projects/DetailedProject/DetailedProject'
import CompanyGroup from '@local/Scenes/Company/CompanyTabs/CompanyGroup'
import CompanyOverview from '@local/Scenes/Company/Overview/CompanyOverview'
import EditCompany from '@local/Scenes/Company/EditCompany/EditCompany'

import CompanyTabs from './CompanyTabs'

const Company = () => {
  return (
    <>
      <CompanyOverview />
      <CompanyTabs />
      <Box marginY={5}>
        <Switch>
          <Route path={`${companyDetailsUrl}/:companyGuid/redigera/`} exact>
            <EditCompany />
          </Route>
          <Route path={`${companyDetailsUrl}/:companyGuid/kontakt/`} exact>
            <Contacts />
          </Route>
          <Route path={`${companyDetailsUrl}/:companyGuid/kontakt/skapa/`} exact>
            <HandleContact />
          </Route>
          <Route path={`${companyDetailsUrl}/:companyGuid/kontakt/:contactGuid/`} exact>
            <DetailedContact />
          </Route>
          <Route path={`${companyDetailsUrl}/:companyGuid/kontakt/:contactGuid/redigera/`} exact>
            <HandleContact />
          </Route>
          <Route path={`${companyDetailsUrl}/:companyGuid/projekt/`} exact>
            <Projects />
          </Route>
          <Route path={`${companyDetailsUrl}/:companyGuid/projekt/:projectGuid/redigera`} exact>
            <HandleProject />
          </Route>
          <Route path={`${companyDetailsUrl}/:companyGuid/projekt/skapa`} exact>
            <HandleProject />
          </Route>
          <Route path={`${companyDetailsUrl}/:companyGuid/projekt/:projectGuid`} exact>
            <DetailedProject />
          </Route>
          <Route path={`${companyDetailsUrl}/:companyGuid/koncern/`} exact>
            <CompanyGroup />
          </Route>
          <Route path={`${companyDetailsUrl}/:companyGuid/handelser/skapa/`} exact>
            <HandleActivity />
          </Route>
          <Route path={`${companyDetailsUrl}/:companyGuid/handelser/:activityGuid/redigera/`} exact>
            <HandleActivity />
          </Route>
          <Route path={`${companyDetailsUrl}/:companyGuid/handelser/:activityGuid/`} exact>
            <DetailedActivity />
          </Route>
          <Route path={[`${companyDetailsUrl}/:companyGuid/handelser/`, `${companyDetailsUrl}/:companyGuid/`]} exact>
            <Activities />
          </Route>
        </Switch>
      </Box>
    </>
  )
}

export default Company
