import React, { useEffect } from 'react'
import { Alert, Box, Button } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import LinkWithIcon from '@local/src/Components/LinkWithIcon/LinkWithIcon'
import Loading from '@local/src/Components/Loading/Loading'
import { projectEditUrl, projectsUrl } from '@local/src/basename'
import { useHistory, useParams } from 'react-router-dom'
import { useAppDispatch } from '@local/Store/configureStore'
import { getProjectById } from '@local/Scenes/Company/CompanyTabs/Projects/Projects.actions'
import { useStateSelector } from '@local/src/Store/useStateSelector'

import CasesTable from '../CasesTable/CasesTable'
import DocumentProject from '../DocumentProject/DocumentProject'
import ProjectDetails from '../ProjectDetails/ProjectDetails'
import { deleteAttachmentFailureSelector, detailedProjectFetchFailureSelector, downloadAttachmentFailureSelector, fetchAttachmentsFailureSelector, fetchCasesFailureSelector, fileNameValidationFailureSelector, projectSelector, uploadAttachmentErrorSelector } from '../Projects.selectors'

const DetailedProject = () => {

  const detailedProjectFetchFailure = useStateSelector(detailedProjectFetchFailureSelector)
  const deleteAttachmentFailure = useStateSelector(deleteAttachmentFailureSelector)
  const fetchAttachmentsFailure = useStateSelector(fetchAttachmentsFailureSelector)
  const downloadAttachmentFailure = useStateSelector(downloadAttachmentFailureSelector)
  const fileNameValidationFailure = useStateSelector(fileNameValidationFailureSelector)
  const caseFailure = useStateSelector(fetchCasesFailureSelector)
  const uploadAttachmentError = useStateSelector(uploadAttachmentErrorSelector)
  const project = useStateSelector(projectSelector)

  const history = useHistory()
  const dispatch = useAppDispatch()
  const { companyGuid, projectGuid } = useParams<{ companyGuid: string, projectGuid: string }>()

  useEffect(() => {
    dispatch(getProjectById(companyGuid, projectGuid))
  }, [companyGuid, dispatch, projectGuid])

  const handleEditProject = () => {
    history.push(projectEditUrl(companyGuid, projectGuid))
  }

  const handleShowAllProjects = () => {
    history.push(projectsUrl(companyGuid))
  }

  const attachmentError: boolean =
    deleteAttachmentFailure ||
    uploadAttachmentError ||
    fetchAttachmentsFailure ||
    downloadAttachmentFailure ||
    fileNameValidationFailure

  const attachmentErrorContent = () => {
    if (uploadAttachmentError) {
      return 'Fel vid uppladdning av dokument. Kontrollera filformatet samt filens storlek (max 49 MB) och försök igen.'
    }
    if (deleteAttachmentFailure) {
      return 'Fel vid radering av dokument'
    }
    if (downloadAttachmentFailure) {
      return 'Fel vid nedladdning av dokument. Vänligen försök igen'
    }
    if (fileNameValidationFailure) {
      return 'Filnamnet kan inte vara längre än 256 tecken'
    }
    return 'Fel vid hämtning av dokument'
  }

  if (detailedProjectFetchFailure) {
    return <Alert severity="error">Fel vid hämtning av projekt</Alert>
  }

  if (!project) {
    return <Loading />
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" paddingBottom={3}>
        <LinkWithIcon
          text="Företagets alla projekt"
          onClick={handleShowAllProjects}
          icon={<ChevronLeftIcon fontSize="small" />}
        />
      </Box>

      {attachmentError && <Alert severity="error">{attachmentErrorContent()}</Alert>}

      {caseFailure && <Alert severity="error">Fel vid hämtning av ärenden</Alert>}

      <ProjectDetails project={project} />
      {project.canEdit && (
        <Button variant='outlined' onClick={handleEditProject} sx={{ marginTop: 3 }} data-testid='edit-project'>Redigera</Button>
      )}
      <DocumentProject projektId={project.projektId} huvudForetagId={project.huvudForetag.foretagId} />
      <CasesTable projektId={project.projektId} huvudForetagId={project.huvudForetag.foretagId} />
    </>
  )
}

export default DetailedProject
