export const formatPhoneNumber = (probablyAPhoneNumber: string) => {
  const MISSING_OR_INVALID_PHONE_NUMBER = 'Saknas'

  if (!probablyAPhoneNumber) {
    return MISSING_OR_INVALID_PHONE_NUMBER
  }
  const isSwedishMobilePhoneNumber =
    (probablyAPhoneNumber.startsWith('+467') && probablyAPhoneNumber.length === 12) ||
    (probablyAPhoneNumber.startsWith('07') && probablyAPhoneNumber.length === 10)
  if (!isSwedishMobilePhoneNumber) {
    return probablyAPhoneNumber
  }
  const significantPartOfPhoneNumber = probablyAPhoneNumber.substring(probablyAPhoneNumber.indexOf('7'))
  const formattedPhoneNumber = `0${significantPartOfPhoneNumber.substring(
    0,
    2
  )}-${significantPartOfPhoneNumber.substring(2, 5)} ${significantPartOfPhoneNumber.substring(
    5,
    7
  )} ${significantPartOfPhoneNumber.substring(7, 9)}`
  return formattedPhoneNumber
}
