import { Alert, Stack, Switch, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { GridDensity } from '@mui/x-data-grid'
import InformationPopover from '@local/src/Components/InformationPopover/InformationPopover'
import { useLocalStorage } from '@local/src/hooks/useLocalStorage'
import { useLazySearchContactsQuery } from '@local/src/Utils/network/endpoints/ContactsSearch/contactsSearchApi'
import { IContactsSearchApiModel } from '@local/src/Utils/network/endpoints/ContactsSearch/IContactsSearchApiModel'
import { IPagingModel } from '@local/src/Models/Misc/Paging.model'
import { ISortingModel } from '@local/src/Models/Misc/Sorting.model'
import { useUser } from '@trr/app-shell-data'

import { DefaultMyContactsColumns, MyContactsColumns } from '../Columns/contactSearchColumns'
import { DEFAULT_PAGING } from '../Hooks/Paging/useContactSearchPaging'

import { ContactsTable } from './Table/ContactsTable'


export const MyContacts = () => {
    const [searchContacts, { data: result, isFetching: isSearching, isError: didSearchFail }] = useLazySearchContactsQuery()
    const { mdid: responsibleId } = useUser()

    const [visibleColumns, setVisibleColumns] = useLocalStorage('my-contacts-visible-columns', DefaultMyContactsColumns)
    const [density, setDensity] = useLocalStorage('my-contacts-density', 'standard' as GridDensity)
    const [showInactiveContacts, setShowInactiveContact] = useLocalStorage('my-contacts-show-inactive-contacts', false)
    const [showOnlyMainContacts, setShowOnlyMainContacts] = useLocalStorage('my-contacts-show-only-main-contacts', false)
    const [apiModel, setApiModel] = useState<IContactsSearchApiModel>()
    const [paging, setPaging] = useLocalStorage('my-contacts-paging', DEFAULT_PAGING)
    const [sorting, setSorting] = useLocalStorage('my-contacts-sorting', {
        property: 'Namn',
        order: 'ascending'
    } as ISortingModel)


    useEffect(() => {
        const mappedApiModel = mapToContactsSearchApiModel(showInactiveContacts, showOnlyMainContacts, responsibleId, paging, sorting)
        void searchContacts(mappedApiModel)
        setApiModel(mappedApiModel)
    }, [paging, searchContacts, showInactiveContacts, showOnlyMainContacts, sorting, responsibleId])


    const handleOnPagingChanged = (newPaging: IPagingModel) => {
        setPaging(newPaging)
    }

    const handleOnSortingChanged = (newSorting: ISortingModel) => {
        setSorting(newSorting)
        setPaging({ skip: 0, take: paging.take })
    }



    return (
        <Stack direction='column' spacing={2}>
            <Typography variant='h4'>Mina kontaktpersoner {result?.totalCount >= 0 ? `(${result.totalCount.toLocaleString('sv-SE')})` : ''}</Typography>
            {didSearchFail && <Alert severity='error'>Kunde inte hämta kontaktpersoner - vänligen försök igen</Alert>}
            <Stack direction='row' gap={3} alignItems='center'>
                <Stack direction='row' alignItems='center'>
                    <Switch color='primary' checked={showInactiveContacts} onChange={(_, checked) => setShowInactiveContact(checked)} />
                    <Typography>Visa även inaktiva kontakter</Typography>
                </Stack>
                <Stack direction='row' alignItems='center'>
                    <Switch color='primary' checked={showOnlyMainContacts} onChange={(_, checked) => setShowOnlyMainContacts(checked)} />
                    <Typography>Visa endast huvudkontakter</Typography>
                    <InformationPopover
                        infoText='Syftet med att märka upp en kontakt som en huvudkontakt är att du snabbt kan få fram de personer som du jobbar med mest frekvent, exempelvis vid gruppmejl.'
                    />
                </Stack>
            </Stack>
            {useMemo(() => <ContactsTable
                result={result}
                columns={MyContactsColumns}
                isSearching={isSearching}
                paging={paging}
                onPagingChanged={handleOnPagingChanged}
                sorting={sorting}
                onSortingChanged={handleOnSortingChanged}
                visibleColumns={visibleColumns}
                onVisibleColumnsChanged={setVisibleColumns}
                density={density}
                onDensityChanged={setDensity}
                apiModel={apiModel}
            // eslint-disable-next-line react-hooks/exhaustive-deps
            />, [density, isSearching, paging, result, sorting, visibleColumns, apiModel])}
        </Stack >
    )
}

const mapToContactsSearchApiModel = (showInactiveContacts: boolean, showOnlyMainContacts: boolean, userId: string, paging: IPagingModel, sorting: ISortingModel): IContactsSearchApiModel => ({
    arHuvudkontakt: showOnlyMainContacts ? true : undefined,
    statusar: showInactiveContacts ? [0, 1, 2] : [0],
    arbetsstalle: {
        kundkontaktIdn: [userId]
    },
    skip: paging.skip,
    count: paging.take,
    orderBy: [{
        property: sorting.property,
        order: sorting.order
    }]
})