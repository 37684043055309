import { useCallback } from 'react'

import { ICountyFilterData, useCountyFilter } from '../../../../Common/Hooks/Filters/useCountyFilter'
import { IEligibilityFilterData, useEligibilityFilter } from '../../../../Common/Hooks/Filters/useEligibilityFilter'
import {
  IFilterVisibilityToggleData,
  useFilterVisibilityToggle,
} from '../../../../Common/Hooks/Filters/useFilterVisibilityToggle'
import { IMunicipalityFilterData, useMunicipalityFilter } from '../../../../Common/Hooks/Filters/useMunicipalityFilter'
import {
  IWorkplaceTypeFilterData,
  useWorkplaceTypeFilter,
} from '../../../../Common/Hooks/Filters/useWorkplaceTypeFilter'
import {
  IFreeTextSearchFieldData,
  useFreeTextSearchField,
} from '../../../../Common/Hooks/Filters/useFreeTextSearchField'
import { ITrrIndustryFilterData, useTrrIndustryFilter } from '../../../../Common/Hooks/Filters/useTrrIndustryFilter'

import { IIsLocallyImportantFieldData, useIsLocallyImportantField } from './useIsLocallyImportantField'
import { ILocalContactFilterData, useLocalContactFilter } from './useLocalContactFilter'
import { ILocalManagerFilterData, useLocalManagerFilter } from './useLocalManagerFilter'
import { INationalManagerFilterData, useNationalManagerFilter } from './useNationalManagerFilter'
import { IPayrollFilterData, usePayrollFilter } from './usePayrollFilter'
import { IWhiteCollarFilterData, useWhiteCollarFilter } from './useWhiteCollarFilter'
import { IWorkplaceStatusFilterData, useWorkplaceStatusFilter } from './useWorkplaceStatusFilter'

export interface IWorkplaceSearchFilterData {
  filterVisibilityData: IFilterVisibilityToggleData
  freeTextSearchData: IFreeTextSearchFieldData
  countyData: ICountyFilterData
  municipalityData: IMunicipalityFilterData
  workplaceTypeData: IWorkplaceTypeFilterData
  trrIndustryData: ITrrIndustryFilterData
  nationalManagerData: INationalManagerFilterData
  localManagerData: ILocalManagerFilterData
  localContactData: ILocalContactFilterData
  eligibilityData: IEligibilityFilterData
  whiteCollarData: IWhiteCollarFilterData
  payrollData: IPayrollFilterData
  workplaceStatusData: IWorkplaceStatusFilterData
  isLocallyImportantData: IIsLocallyImportantFieldData
  wasAnyFilterLoadedFromUrl: boolean
  resetFilters: () => void
}

export const useWorkplaceSearchFilters = (): IWorkplaceSearchFilterData => {
  const filterVisibilityData = useFilterVisibilityToggle()
  const freeTextSearchData = useFreeTextSearchField()
  const countyData = useCountyFilter()
  const municipalityData = useMunicipalityFilter(countyData.selection)
  const workplaceTypeData = useWorkplaceTypeFilter()
  const trrIndustryData = useTrrIndustryFilter()
  const nationalManagerData = useNationalManagerFilter()
  const localManagerData = useLocalManagerFilter()
  const localContactData = useLocalContactFilter()
  const eligibilityData = useEligibilityFilter()
  const whiteCollarData = useWhiteCollarFilter()
  const payrollData = usePayrollFilter()
  const workplaceStatusData = useWorkplaceStatusFilter()
  const isLocallyImportant = useIsLocallyImportantField()

  const wasAnyFilterLoadedFromUrl =
    freeTextSearchData.wasLoadedFromUrl ||
    countyData.wasLoadedFromUrl ||
    municipalityData.wasLoadedFromUrl ||
    workplaceTypeData.wasLoadedFromUrl ||
    trrIndustryData.wasLoadedFromUrl ||
    nationalManagerData.wasLoadedFromUrl ||
    localManagerData.wasLoadedFromUrl ||
    localContactData.wasLoadedFromUrl ||
    eligibilityData.wasLoadedFromUrl ||
    whiteCollarData.wasLoadedFromUrl ||
    payrollData.wasLoadedFromUrl ||
    workplaceStatusData.wasLoadedFromUrl ||
    isLocallyImportant.wasLoadedFromUrl

  const resetFilters = useCallback(() => {
    freeTextSearchData.setValue('')
    countyData.setSelection([])
    municipalityData.setSelection([])
    workplaceTypeData.setSelection([])
    trrIndustryData.setSelection([])
    nationalManagerData.setSelection([])
    localManagerData.setSelection([])
    localContactData.setSelection([])
    eligibilityData.setSelection([])
    whiteCollarData.reset()
    payrollData.reset()
    workplaceStatusData.setSelection([])
    isLocallyImportant.setValue(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    filterVisibilityData,
    freeTextSearchData,
    countyData,
    municipalityData,
    workplaceTypeData,
    trrIndustryData,
    nationalManagerData,
    localManagerData,
    localContactData,
    eligibilityData,
    whiteCollarData,
    payrollData,
    workplaceStatusData,
    isLocallyImportantData: isLocallyImportant,
    wasAnyFilterLoadedFromUrl,
    resetFilters,
  }
}
