const org = 'organisationsregistret'
export const orgBasename = '/medarbetare/' + org
export const orgBasepath = orgBasename
export const orgUrl = `${orgBasepath}`
export const companyUrl = `${orgUrl}/foretag`
export const contactUrl = `${orgUrl}/kontakt`
export const projectUrl = `${orgUrl}/projekt`
export const companyDetailsUrl = `${companyUrl}/detaljer`

export const companyOverviewUrl = (companyId: string) => `${companyDetailsUrl}/${companyId}`
export const companyProjectUrl = (companyId: string) => `${companyDetailsUrl}/${companyId}/projekt`
export const companyContactUrl = (companyId: string) => `${companyDetailsUrl}/${companyId}/kontakt`
export const companyGroupUrl = (companyId: string) => `${companyDetailsUrl}/${companyId}/koncern`
export const companyEditUrl = (companyId: string) => `${companyDetailsUrl}/${companyId}/redigera`
export const activitiesUrl = (companyId: string) => `${companyDetailsUrl}/${companyId}/handelser`
export const activityDetailsUrl = (companyId: string, activityGuid: string) =>
  `${companyDetailsUrl}/${companyId}/handelser/${activityGuid}`
export const activityEditUrl = (companyId: string, activityGuid: string) =>
  `${companyDetailsUrl}/${companyId}/handelser/${activityGuid}/redigera`
export const activityCreateUrl = (companyId: string) => `${companyDetailsUrl}/${companyId}/handelser/skapa`
export const contactDetailsUrl = (companyId: string, contactId: string) =>
  `${companyDetailsUrl}/${companyId}/kontakt/${contactId}`
export const contactDetailsEditUrl = (companyId: string, contactId: string) =>
  `${companyDetailsUrl}/${companyId}/kontakt/${contactId}/redigera`
export const createContactUrl = (companyId: string) => `${companyDetailsUrl}/${companyId}/kontakt/skapa`
export const projectsUrl = (companyId: string) => `${companyDetailsUrl}/${companyId}/projekt`
export const projectDetailsUrl = (companyId: string, projectId: string) =>
  `${companyDetailsUrl}/${companyId}/projekt/${projectId}`
export const projectEditUrl = (companyId: string, projectId: string) =>
  `${companyDetailsUrl}/${companyId}/projekt/${projectId}/redigera`
export const projectCreateUrl = (companyId: string) => `${companyDetailsUrl}/${companyId}/projekt/skapa`
