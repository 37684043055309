import { IContact } from '@local/src/Models/Domain/Contact.model'
import { IContactCategory } from '@local/src/Models/Domain/ContactCategory.model'
import { IContactRole } from '@local/src/Models/Domain/ContactRole.model'
import { ICounty } from '@local/src/Models/Domain/County.model'
import { IMunicipality } from '@local/src/Models/Domain/Municipality.model'
import { ITrrIndustry } from '@local/src/Models/Domain/TrrIndustry.model'
import { IUnion } from '@local/src/Models/Domain/Union.model'
import { IWorkplaceManager } from '@local/src/Models/Domain/WorkplaceManager.model'
import { IWorkplaceType } from '@local/src/Models/Domain/WorkplaceType.model'

export interface IContactsSearchResponseApiModel {
  id: string
  arHuvudkontakt: boolean
  namn: string
  epost: string
  mobilnummer: string
  roll?: Roll
  fackforbund?: Fackforbund
  foretagsStatus: {
    id: string
    beskrivning: string
  }
  senastAndrad: {
    tid: string
  }
  arbetsstalle: {
    id: string
    organisationsnummer: string
    komplettNamn: string
    kontorstyp?: Kontorstyp
    omfattningsstatus: {
      kod: string
      namn: string
    }
    lan?: Lan
    kommun?: Kommun
    nationellKontakt?: NationellKontakt
    trrBranschKoder?: TrrBranschKoder[]
  }
}

interface Kontorstyp {
  id: string
  namn: string
}

interface Roll {
  rollNiva1Id: string
  rollNiva1Text: string
  rollNiva2Id: string
  rollNiva2Text: string
}

interface Fackforbund {
  id: string
  namn: string
}
interface NationellKontakt {
  id: string
  namn: string
}

interface Lan {
  kod: string
  namn: string
}

interface Kommun {
  kod: string
  namn: string
}

interface TrrBranschKoder {
  kod: string
  beskrivning: string
  arHuvudverksamhet: boolean
}

export const mapToContact = (data: IContactsSearchResponseApiModel): IContact => ({
  id: data.id,
  name: data.namn,
  isMainContact: data.arHuvudkontakt,
  email: data.epost,
  mobileNumber: data.mobilnummer,
  category: mapCategory(data.roll),
  role: mapRole(data.roll),
  status: {
    id: data.foretagsStatus.id,
    description: data.foretagsStatus.beskrivning,
  },
  union: mapUnion(data.fackforbund),
  workplace: {
    id: data.arbetsstalle.id,
    organizationalNumber: data.arbetsstalle.organisationsnummer,
    name: data.arbetsstalle.komplettNamn,
    type: mapWorkplaceType(data.arbetsstalle.kontorstyp),
    eligibility: {
      id: data.arbetsstalle.omfattningsstatus.kod,
      description: data.arbetsstalle.omfattningsstatus.namn,
    },
    responsible: mapResponsible(data.arbetsstalle.nationellKontakt),
    county: mapCounty(data.arbetsstalle.lan),
    municipality: mapMunicipality(data.arbetsstalle.kommun, data.arbetsstalle.lan),
    trrIndustries: mapTrrIndustries(data.arbetsstalle.trrBranschKoder),
  },
  lastUpdated: data.senastAndrad.tid,
})

const mapCategory = (roll: Roll): IContactCategory =>
  roll
    ? {
        id: roll.rollNiva1Id,
        description: roll.rollNiva1Text,
      }
    : undefined

const mapRole = (roll: Roll): IContactRole =>
  roll
    ? {
        id: roll.rollNiva2Id,
        description: roll.rollNiva2Text,
        categoryId: roll.rollNiva1Id,
      }
    : undefined

const mapUnion = (fackforbund?: Fackforbund): IUnion =>
  fackforbund
    ? {
        id: fackforbund.id,
        name: fackforbund.namn,
      }
    : undefined

const mapResponsible = (nationellKontakt?: NationellKontakt): IWorkplaceManager =>
  nationellKontakt
    ? {
        id: nationellKontakt.id,
        fullName: nationellKontakt.namn,
      }
    : undefined

const mapCounty = (lan?: Lan): ICounty =>
  lan
    ? {
        id: lan.kod,
        name: lan.namn,
      }
    : undefined

const mapMunicipality = (kommun?: Kommun, lan?: Lan): IMunicipality =>
  kommun
    ? {
        id: kommun.kod,
        name: kommun.namn,
        countyId: lan.kod,
      }
    : undefined

const mapTrrIndustries = (trrBranschKoder?: TrrBranschKoder[]): ITrrIndustry[] =>
  trrBranschKoder?.map((tbk) => ({
    id: tbk.kod,
    description: tbk.beskrivning,
    isMain: tbk.arHuvudverksamhet,
  })) ?? []

const mapWorkplaceType = (workplaceType?: Kontorstyp): IWorkplaceType =>
  workplaceType
    ? {
        id: workplaceType.id,
        name: workplaceType.namn,
      }
    : undefined
