import { IUrlSyncedData } from '@local/src/Utils/helpers/Url/IUrlSyncedData'
import { useUrlSync } from '@local/src/Utils/helpers/Url/useUrlSync'
import { useState } from 'react'

export interface IFreeTextSearchFieldData extends IUrlSyncedData {
  value: string
  setValue: (value: string) => void
}

export const useFreeTextSearchField = (): IFreeTextSearchFieldData => {
  const [value, setValue] = useState<string>('')
  const { wasLoadedFromUrl, updateValue } = useUrlSync<string>(
    'search',
    setValue,
    (s) => s,
    (s) => s,
    []
  )

  return {
    value,
    setValue: updateValue,
    wasLoadedFromUrl,
  }
}
