import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@local/Store/configureStore'

export const companySelector = createSelector(
  (state: RootState) => state.company.company,
  company => company
)
export const companyLoadedSelector = createSelector(
  (state: RootState) => state.company,
  company => company.companyLoaded && !!company.company
)

export const fetchCompanyFailedSelector = createSelector(
  (state: RootState) => state.company,
  company => company.fetchCompanyFailed
)

export const updateCompanyErrorMessageSelector = createSelector(
  (state: RootState) => state.company,
  company => company.updateCompanyErrorMessage
)
export const updateCompanyInProgressSelector = createSelector(
  (state: RootState) => state.company,
  company => company.updateCompanyInProgress
)

export const detailedContactSelector = createSelector(
  (state: RootState) => state.company,
  company => company.detailedContact
)

export const companyContactsSelector = createSelector(
  (state: RootState) => state.company,
  company => company.contacts
)

export const handleContactErrorMessageSelector = createSelector(
  (state: RootState) => state.company,
  company => company.createOrUpdateContactErrorMessage
)

export const createOrUpdateContactFailedSelector = createSelector(
  (state: RootState) => state.company,
  company => company.createOrUpdateContactFailed
)