import { contactDetailsUrl } from "@local/src/basename"
import { IContact } from "@local/src/Models/Domain/Contact.model"
import { tableTooltip } from "@local/src/Utils/helpers/tooltips"
import { Star } from "@mui/icons-material"
import { Link, Stack, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import { GridColDef } from "@mui/x-data-grid"
import React from "react"

export const nameColumn: GridColDef<IContact> = {
    headerName: 'Namn',
    field: 'Namn',
    hideable: false,
    valueGetter: ({ row }) => row?.name,
    renderHeader: () => NameHeader(),
    renderCell: ({ formattedValue, row }) => <NameField name={formattedValue} isMainContact={row?.isMainContact} contactId={row?.id} workplaceId={row?.workplace.id} />,
    minWidth: 150,
    flex: 1
}

const NameHeader = () => {
    return <Stack direction='column'>
        <Typography fontSize='14px' fontWeight='500'>Namn</Typography>
        <Stack direction='row' alignItems='center' gap={0.5}>
            <Star sx={{ color: grey[600], width: '12px', height: '12px' }} />
            <Typography fontSize='12px' fontWeight='400' color={grey[600]}>Huvudkontakt</Typography>
        </Stack>
    </Stack>
}


interface Props {
    name: string
    isMainContact: boolean
    workplaceId: string
    contactId: string
}
const NameField = React.memo(({ name, isMainContact, contactId, workplaceId }: Props) => (
    <Stack direction='row' gap={0.5} alignItems='center' width='100%'>
        <Star sx={{ width: '12px', visibility: `${isMainContact ? 'visible' : 'hidden'}` }} />
        <Link data-testid="contact-name-link" href={contactDetailsUrl(workplaceId, contactId)} onClick={e => e.stopPropagation()} sx={{ width: 'calc(100% - 12px)' }}>
            {tableTooltip(name)}
        </Link>
    </Stack>)
)