import { IContactSearchFilterData, useContactSearchFilters } from './Filters/useContactSearchFilters'
import { IContactSearchPagingData, useContactSearchPaging } from './Paging/useContactSearchPaging'
import { IContactSearchQueryData, useContactSearchQuery } from './Query/useContactSearchQuery'
import { IContactSearchSortingData, useContactSearchSorting } from './Sorting/useContactSearchSorting'

export interface IContactSearchData {
  filterData: IContactSearchFilterData
  pagingData: IContactSearchPagingData
  sortingData: IContactSearchSortingData
  queryData: IContactSearchQueryData
}

export const useContactSearch = (): IContactSearchData => {
  const filterData = useContactSearchFilters()
  const pagingData = useContactSearchPaging()
  const sortingData = useContactSearchSorting()
  const queryData = useContactSearchQuery()

  return {
    filterData,
    pagingData,
    sortingData,
    queryData,
  }
}
