import React from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import useTabs from '@local/Scenes/Home/Tabs/useTabs'

export interface ITabItem {
  heading: React.ReactNode
  urls: string[]
  route: string
  exact?: boolean
}

export interface ITabsContainer extends Partial<JSX.IntrinsicElements['div']> {
  items: ITabItem[]
  purpose?: string
}

export const TabsContainer = ({ items, purpose }: ITabsContainer) => {
  const { currentIndex, handleChange, getA11yProps } = useTabs(items)

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={currentIndex} onChange={handleChange} aria-label={purpose} variant="scrollable">
        {items.map((tabItem, index) => (
          <Tab
            onClick={event => handleChange(event, index)}
            sx={{ textTransform: 'none', fontSize: 16 }}
            key={tabItem.urls[0]}
            label={tabItem.heading}
            {...getA11yProps(index)}
          />
        ))}
      </Tabs>
    </Box>
  )
}
