import { connect } from 'react-redux'
import { RootState } from '@local/Store/configureStore'

import Projects from './Projects'
import { IProjectList } from './Projects.types'

const mapStateToProps = (state: RootState) => ({
  projects: state.project.projectsLoaded
    ? filterSearchResult(state.project.projects, state.project.filterOnActiveProjects)
    : [],
  projectsLoaded: state.project.projectsLoaded,
  filterOnActiveProjects: state.project.filterOnActiveProjects,
  getProjectsByWorkplaceError: state.project.getProjectsByWorkplaceError,
  deleteFail: state.project.deleteFail,
  deleteInProgress: state.project.deleteInProgress,
})

const filterSearchResult = (searchResult: IProjectList[], filterOnActiveProjects: boolean) => {
  return filterOnActiveProjects ? searchResult.filter(r => r.projektstatus.projektstatusId === '01') : searchResult
}

const connectedComponent = connect(mapStateToProps)(Projects)

export default connectedComponent
