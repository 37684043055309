import Loading from '@local/src/Components/Loading/Loading'
import { createCompany } from '@local/src/Scenes/Home/Home.actions'
import { createCompanyReset } from '@local/src/Scenes/Home/Home.reducers'
import { companyCreationErrorMessageSelector, companyCreationFailedSelector, companyCreationInitiatedSelector, companyCreationSuccessSelector } from '@local/src/Scenes/Home/Home.selectors'
import { useAppDispatch } from '@local/src/Store/configureStore'
import { useStateSelector } from '@local/src/Store/useStateSelector'
import { orgBasepath } from '@local/src/basename'
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

export const CreateCompanyButton = () => {

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (<>
    <Button variant="outlined" onClick={() => setIsModalOpen(true)}>
      Skapa företag
    </Button>
    <CreateCompanyModal active={isModalOpen} close={() => setIsModalOpen(false)} />
  </>)
}


interface CreateCompanyModalProps {
  active: boolean
  close: () => void
}

const CreateCompanyModal = ({
  active,
  close,
}: CreateCompanyModalProps) => {

  const dispatch = useAppDispatch()
  const history = useHistory()
  const errorMessage = useStateSelector(companyCreationErrorMessageSelector)
  const createFailed = useStateSelector(companyCreationFailedSelector)
  const createSuccess = useStateSelector(companyCreationSuccessSelector)
  const createInitiated = useStateSelector(companyCreationInitiatedSelector)

  const [orgnr, setOrgnr] = useState('')

  const onSubmit = () => {
    dispatch(createCompany(orgnr))
  }

  const onClose = () => {
    dispatch(createCompanyReset())
    close()
  }

  useEffect(() => {
    if (createSuccess) {
      close()
      history.push(`${orgBasepath}/foretag?search=${encodeURIComponent(orgnr)}`)
    }
  }, [close, createSuccess, history, orgnr])

  return (
    <Dialog open={active} onClose={onClose} sx={{
      '& .MuiDialog-container': {
        '& .MuiPaper-root': {
          width: '100%'
        }
      }
    }}>
      <DialogTitle>Skapa nytt företag</DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={2}>
          <Typography>Ange organisationsnummer för det företag du vill lägga till.</Typography>
          <TextField
            label='Organisationsnummer'
            value={orgnr}
            fullWidth
            onChange={(event) => setOrgnr(event.target.value)}
          />
        </Box>
        {createFailed && (
          <Alert severity='error' sx={{ mt: 2 }}>{errorMessage}</Alert>
        )}
        {createInitiated && (
          <Box display='flex' flexDirection='column' gap={2} mt={2}>
            <Alert severity="info">Företaget hämtas från D&B.</Alert>
            <Loading />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={onSubmit}>OK</Button>
        <Button variant='text' onClick={onClose}>Avbryt</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateCompanyModal