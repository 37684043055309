import { Tooltip, Typography } from '@mui/material'
import React from 'react'

import { convertToMSek, formatCompanyTypeName, prettyPrintAmount } from '.'

export const tableTooltip = (value?: string | number) => <MemoizedTableTooltip value={value} />

interface Props {
  value?: string | number
}
const MemoizedTableTooltip = React.memo(({ value }: Props) => (
  <Tooltip title={value}>
    <Typography variant='body2' overflow='hidden' textOverflow='ellipsis' noWrap>{value}</Typography>
  </Tooltip>
))

export const tablePrettyPrintTooltip = (row: { value: number }) => (
  <span title={String(row.value)}>{prettyPrintAmount(row.value)}</span>
)

export const payrollTooltip = (value?: number) => {
  if (value === null) {
    return (<Tooltip title='Lönesumma saknas'>
      <span>-</span>
    </Tooltip>)
  }

  const convertedValue = value / 1000000;
  let displayValue;

  if (convertedValue === 0) {
    displayValue = '0'
  } else if (convertedValue < 1) {
    displayValue = '<1'
  } else {
    displayValue = convertToMSek(value);
  }

  return (
    <Tooltip title={`${prettyPrintAmount(value)} SEK`}>
      <span>{displayValue}</span>
    </Tooltip>
  );
};

export const companyTypeNameTooltip = (name: string) => (
  <Tooltip title={name}>
    <span>{formatCompanyTypeName(name)}</span>
  </Tooltip>
)