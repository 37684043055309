import ConfirmationModal from '@local/src/Components/ConfirmationModal/ConfirmationModal'
import React from 'react'

interface DeleteProjectModalProps {
  open: boolean
  onSubmit: () => void
  onCancel: () => void
}

const DeleteProjectModal = ({
  open,
  onSubmit,
  onCancel,
}: DeleteProjectModalProps) => {

  return (
    <ConfirmationModal
      active={open}
      close={onCancel}
      submit={onSubmit}
      message='Är du säker på att du vill ta bort denna händelse?'
      title='Bekräfta borttagning'
    />
  )
}

export default DeleteProjectModal