export enum FetchingState {
  IDLE,
  LOADING,
  SUCCESS,
  ERROR,
}

export interface ISelectOption {
  value: string
  label: string
  id?: string
}

export interface UserProfileContent {
  uploadImage?: string
  changeImage?: string
  removeImage?: string
  profileInformation?: string
  preferredFirstName?: string
  contactInformation?: string
  addressInformation?: string
  socialMedia?: string
}

export const validConsentKeys = ['health', 'matching'] as const

export type ConsentKeys = (typeof validConsentKeys)[number]

export type AllowedErrorKeys =
  | null
  | 'userProfileImageFileSize'
  | 'userProfileImageFileExtention'
  | 'userProfileUploadError'

export interface ConsentBody {
  consent: boolean
}

export interface ContactInformationBody {
  email?: string
  mobilePhone?: string
}

export interface ProfileInformationBody {
  preferredFirstName?: string
}

export type UpdateConsentActionRequest = Partial<Record<ConsentKeys, boolean>>

export interface Address {
  streetAddress: string
  city: string
  postalCode: string
}

export interface Consent {
  health: boolean
  matching: boolean
}

export interface EditModal {
  contact: boolean
  profile: boolean
  socialMedia: boolean
}

export interface ISocialMediaItem {
  type: string
  url: string | undefined
}

export interface AppShellUserProfile {
  address: Address
  firstName: string
  lastName: string
  email: string
  mdid: string
  mobilePhone: string
  preferredFirstName: string
  socialSecurityNumber: string
  imageFileName: string
  hasImageFileName: boolean
  uploadImageErrorKey: AllowedErrorKeys
  isLoading: boolean
  imageUploaded: boolean
  isLoaded: boolean
  consent: Consent
  consentUpdated: boolean
  consentFailure: boolean
  userInformationUpdated: boolean
  userInformationFailure: boolean
  isEditInformationModalOpen: EditModal
  socialMedia: ISocialMediaItem[] | []
}

export interface ExtendedAppShellUserProfile extends AppShellUserProfile {
  fullName: string
}

export interface CommonContent {
  modalAbortButton: string
}

export interface UserConsentBlock {
  cancelConsentButton: string
  cancelConsentModalEditor: string
  cancelConsentModalHeading: string
  consentKey: ConsentKeys
  consentModalEditor: string
  consentModalHeading: string
  consentStatusText: string
  giveConsentButton: string
  heading: string
  preamble: string
  readMoreLinkText: string
  readmeModalHeading: string
}

export interface UserConsentContent {
  modalAbortButton: string
  healthConsent: UserConsentBlock
  matchingConsent: UserConsentBlock
}

export interface ILink {
  id: string
  name: string
  url: string
}
export interface AppShellContent {
  content: {
    profil: UserProfileContent
    samtycken: UserConsentContent
    links: ILink[]
  }
}

export interface AppShellAuthentication {
  userRoles: string[]
  isLoggedIn: boolean
  userSub: string
}
export interface IDevice {
  isMobile: boolean
  isTablet?: boolean
  isDesktop?: boolean
  isXL?: boolean
}

export interface AppShellConfiguration extends AppShellContent {
  currentKey: string
  user: ExtendedAppShellUserProfile
  authentication: AppShellAuthentication
  device: IDevice
  enabledFeatures: string[]
}

export interface IMunicipality {
  label: string
  value: string
  county: string
}

export interface ICompanyType {
  value: string
  label: string
  companyTypeId: string
  name: string
}

export interface IEligibilityStatus {
  value: string
  label: string
  eligibilityStatusCode: string
  eligibilityStatusDescription: string
}

export interface ICounty {
  value: string
  label: string
}

export interface ICompanyManager {
  value: string
  label: string
  email: string
}

export interface ICompanyStatus {
  value: string
  label: string
}

export interface IContactStatus {
  value: string
  label: string
}

export interface IRoleLevelTwo {
  value: string
  label: string
  level1: string
}

export interface ActivitySeminarCodes extends ISelectOption {
  customerSpecificSeminarCode: string
  customerSpecificSeminarDescription: string
  isActive: boolean
}

export interface ActivityStatuses extends ISelectOption {
  statusCode: string
  statusDescription: string
  processTypeCode: string
}

export interface ActivityCategories extends ISelectOption {
  processType: string
  categoryCode: string
  categoryDescription: string
}

export interface SubjectMatters extends ISelectOption {
  subjectMatterCode: string
  subjectMatterDescription: string
}

export interface CountryCode extends ISelectOption {
  phoneCode: string
}

export interface CountryName extends ISelectOption {
  phoneCode: string
}

export interface ITrrIndustry {
  value: string
  description: string
  isMain: boolean
}
