import React, { useEffect, useRef } from 'react'
import { Formik, FormikProps } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import Loading from '@local/src/Components/Loading/Loading'
import {
  FormControlLabel,
  Typography,
  Checkbox,
  Button,
  Box,
  Alert,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  Select,
  Stack,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { companyOverviewUrl } from '@local/src/basename'
import { formControlHasError, getValidationError, isNotNullOrEmpty } from '@local/src/Utils/helpers'
import Autocomplete from '@local/src/Components/Autocomplete/Autocomplete'
import { useAppDispatch } from '@local/Store/configureStore'
import { object, string } from 'yup'
import { useGetCompanyManagersQuery, useLazyGetCompanyDetailsQuery } from '@local/src/Utils/network/endpoints/workplacesApi'
import { useGetCountryCodesQuery } from '@local/src/Utils/network/endpoints/regionsApi'
import { ICompanyManager } from '@local/src/App.types'

import { updateCompany } from '../Company.actions'
import {
  updateCompanyErrorMessageSelector,
  updateCompanyInProgressSelector,
} from '../Company.selectors'
import { Employee, ICompany } from '../Company.model'

export interface EditCompanyFormValues {
  whiteCollarNumberTRR: string
  phoneNumber: string
  phoneCountryCode: string
  omMagasin: string
  locallyImportant: boolean
  localCustomerManager: Employee
  nationalCustomerManager: Employee
  localContact: Employee
  email: string
  homepage: string
}

const EditCompany = (): JSX.Element => {
  const { companyGuid } = useParams<{ companyGuid: string }>()
  const updateCompanyErrorMessage = useSelector(updateCompanyErrorMessageSelector)
  const updateInProgress = useSelector(updateCompanyInProgressSelector)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const previousUpdateInProgress = useRef(updateInProgress)

  const [fetchCompany, { data: company, isFetching: isLoadingCompanyDetails }] = useLazyGetCompanyDetailsQuery()
  const { data: companyManagersFromQuery, isLoading: isLoadingCompanyManagers } = useGetCompanyManagersQuery()
  const { data: countryCodes, isLoading: isLoadingCountryCodes } = useGetCountryCodesQuery()



  useEffect(() => {
    if ((companyGuid && !company) || company?.workplaceId !== companyGuid) {
      void fetchCompany(companyGuid)
    }
  }, [company, companyGuid, dispatch])

  useEffect(() => {
    if (previousUpdateInProgress.current && !updateInProgress && !isNotNullOrEmpty(updateCompanyErrorMessage)) {
      history.push(companyOverviewUrl(companyGuid))
    }
    previousUpdateInProgress.current = updateInProgress
  }, [updateInProgress, history, companyGuid, updateCompanyErrorMessage])



  if (isLoadingCompanyDetails || !company || typeof companyGuid !== 'string' || isLoadingCompanyManagers || isLoadingCountryCodes) return <Loading />

  const companyManagers = companyManagersFromQuery.map(cm => ({ value: cm.id, label: cm.fullName, email: cm.email }) as ICompanyManager)

  const mappedCompanyManagers = companyManagers?.map(item => ({
    employeeId: item.value,
    employeeName: item.label,
  }))

  const submitForm = (values: EditCompanyFormValues) => {
    const updatedWorkplace: ICompany = {
      ...company,
      email: values.email,
      homepage: values.homepage,
      localContact: values.localContact,
      localCustomerManager: values.localCustomerManager,
      locallyImportant: values.locallyImportant,
      nationalCustomerManager: values.nationalCustomerManager,
      omMagasin: values.omMagasin,
      phoneCountryCode: values.phoneCountryCode,
      phoneNumber: values.phoneNumber,
      whiteCollarTrr: {
        ...company.whiteCollarTrr,
        trrNumber: parseInt(values.whiteCollarNumberTRR, 10),
      },
    }
    dispatch(updateCompany(updatedWorkplace))
  }

  const isMainOffice = company.companyType?.companyTypeId?.toString() === '2'

  const getInitialValues = (): EditCompanyFormValues => {
    return {
      whiteCollarNumberTRR: company.whiteCollarTrr?.trrNumber.toString() || '0',
      phoneNumber: company.phoneNumber || '',
      phoneCountryCode: company.phoneCountryCode || '',
      email: company.email || '',
      homepage: company.homepage || '',
      omMagasin: company.omMagasin,
      locallyImportant: company.locallyImportant,
      localCustomerManager:
        company.localCustomerManager.employeeName === null ? undefined : company.localCustomerManager,
      nationalCustomerManager:
        company.nationalCustomerManager.employeeName === null ? undefined : company.nationalCustomerManager,
      localContact: company.localContact.employeeName === null ? undefined : company.localContact,
    }
  }


  return (
    <Box maxWidth="500px">
      <Typography variant="h4" marginBottom={2}>
        Redigera företagsinformation
      </Typography>

      {updateCompanyErrorMessage && (
        <Alert severity="error">{`Fel vid uppdatering av företag: ${updateCompanyErrorMessage}`}</Alert>
      )}

      <Formik
        initialValues={getInitialValues()}
        validationSchema={validationSchema}
        onSubmit={submitForm}
        validateOnMount
      >
        {(formikProps: FormikProps<EditCompanyFormValues>) => (
          <Stack component='form' onSubmit={formikProps.handleSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="h6">Kundstatus</Typography>

              {isMainOffice && (
                <Autocomplete
                  value={formikProps.values.nationalCustomerManager ?? undefined}
                  error={formControlHasError(formikProps, 'nationalCustomerManager')}
                  label="Nationellt ansvarig"
                  onChange={(value: Employee) => {
                    void formikProps.setFieldValue('nationalCustomerManager', value)
                  }}
                  getOptionLabel={(companyManager: Employee) => companyManager.employeeName}
                  getOptionKey={(companyManager: Employee) => companyManager.employeeId}
                  options={mappedCompanyManagers}
                />
              )}

              <Autocomplete
                value={formikProps.values.localCustomerManager ?? undefined}
                error={formControlHasError(formikProps, 'localCustomerManager')}
                label="Lokalt ansvarig"
                onChange={(value: Employee) => {
                  void formikProps.setFieldValue('localCustomerManager', value)
                }}
                getOptionLabel={(companyManager: Employee) => companyManager.employeeName}
                getOptionKey={(companyManager: Employee) => companyManager.employeeId}
                options={mappedCompanyManagers}
              />

              <Autocomplete
                value={formikProps.values.localContact ?? undefined}
                error={formControlHasError(formikProps, 'localContact')}
                label="Lokal kontakt"
                onChange={(value: Employee) => {
                  void formikProps.setFieldValue('localContact', value)
                }}
                getOptionLabel={(companyManager: Employee) => companyManager.employeeName}
                getOptionKey={(companyManager: Employee) => companyManager.employeeId}
                options={mappedCompanyManagers}
              />

              <TextField
                fullWidth
                helperText={getValidationError(formikProps, 'whiteCollarNumberTRR')}
                error={formControlHasError(formikProps, 'whiteCollarNumberTRR')}
                label="Antal tjänstemän (TRR)"
                name="whiteCollarNumberTRR"
                onBlur={formikProps.handleBlur}
                onChange={formikProps.handleChange}
                value={formikProps.values.whiteCollarNumberTRR}
              />

              <FormControlLabel
                label="Lokalt strategiskt"
                control={
                  <Checkbox
                    checked={formikProps.values.locallyImportant}
                    onChange={formikProps.handleChange}
                    name="locallyImportant"
                  />
                }
              />
            </Box>

            <Box display="flex" flexDirection="column" gap={2} marginTop={3}>
              <Typography variant="h6">Kontaktuppgifter</Typography>

              <Box display="flex" gap={2}>
                <FormControl>
                  <InputLabel>Landskod</InputLabel>
                  <Select
                    defaultValue="SE"
                    name="phoneCountryCode"
                    error={formControlHasError(formikProps, 'phoneCountryCode')}
                    value={
                      countryCodes.find(val => val.value === formikProps.values.phoneCountryCode)?.value
                    }
                    label="Landskod"
                    onChange={(event: SelectChangeEvent<string>) =>
                      void formikProps.setFieldValue('phoneCountryCode', event.target.value)
                    }
                  >
                    {countryCodes.map((cc, index: number) => (
                      <MenuItem key={index} value={cc.value}>
                        {`${cc.label} (+${cc.phoneCode})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  helperText={getValidationError(formikProps, 'phoneNumber')}
                  error={formControlHasError(formikProps, 'phoneNumber')}
                  label="Telefon"
                  name="phoneNumber"
                  onBlur={formikProps.handleBlur}
                  onChange={formikProps.handleChange}
                  value={formikProps.values.phoneNumber}
                />
              </Box>

              <TextField
                fullWidth
                helperText={getValidationError(formikProps, 'email')}
                error={formControlHasError(formikProps, 'email')}
                label="Epost"
                name="email"
                onBlur={formikProps.handleBlur}
                onChange={formikProps.handleChange}
                value={formikProps.values.email}
              />

              <TextField
                fullWidth
                helperText={getValidationError(formikProps, 'homepage')}
                error={formControlHasError(formikProps, 'homepage')}
                label="Webbsida"
                name="homepage"
                onBlur={formikProps.handleBlur}
                onChange={formikProps.handleChange}
                value={formikProps.values.homepage}
              />
            </Box>
            <Box display="flex" justifyContent="flex-start" gap={3} marginTop={3}>
              <Button type='submit' disabled={updateInProgress}>
                {updateInProgress ? <Loading size="small" /> : 'Spara'}
              </Button>
              <Button variant="outlined" onClick={() => history.goBack()} disabled={updateInProgress}>
                Avbryt
              </Button>
            </Box>
          </Stack>
        )}
      </Formik>
    </Box>
  )
}

export default EditCompany

const validationSchema = object({
  whiteCollarNumberTRR: string()
    .required('Antal tjänstemän måste anges.')
    .matches(/^[0-9]*$/, 'Antal tjänstemän måste anges med siffror.')
    .max(5, 'Antal tjänstemän kan inte överskrida 99 999.'),
  phoneNumber: string()
    .nullable()
    .max(12, 'Telefonnummer får max innehålla 12 tecken.')
    .matches(/^[0-9-+\s]*$/, 'Telefonnummer får endast innehålla siffror, bindesstreck, plustecken samt mellanslag.'),
  email: string()
    .nullable()
    .matches(/^[^åäöÅÄÖ]+$/, 'Får inte innehålla åäö.')
    .email('Epost-adressen måste ha korrekt format.')
    .max(64, 'Epost-adressen får som mest vara 64 tecken.'),
  homepage: string()
    .nullable()
    .max(132, 'Webbsidan är begränsad till 132 tecken.')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^((https?|ftp):\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
      'Webbsidan måste vara korrekt formaterad.'
    ),
})
