import React, { useEffect, useState } from 'react'
import ConfirmationModal from '@local/Components/ConfirmationModal/ConfirmationModal'
import { GridEventListener, GridRowParams, MuiEvent } from '@mui/x-data-grid'
import DataGridWrapper from '@local/src/Components/DataGridWrapper/DataGridWrapper'
import { useAppDispatch } from '@local/Store/configureStore'
import { filterProjects } from '@local/Scenes/Company/CompanyTabs/Projects/Projects.reducers'
import Loading from '@local/src/Components/Loading/Loading'
import { Alert, Box, Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'
import { projectCreateUrl, projectDetailsUrl, projectEditUrl } from '@local/src/basename'
import { deleteProject, getProjectsByWorkplaceId } from '@local/Scenes/Company/CompanyTabs/Projects/Projects.actions'

import { IProjectList } from './Projects.types'
import { projectsColumns } from './Projects.columns'

export interface IProjectsProps {
  projects?: IProjectList[]
  projectsLoaded?: boolean
  filterOnActiveProjects?: boolean
  getProjectsByWorkplaceError: boolean
  deleteFail?: boolean
  deleteInProgress?: boolean
}

const Projects = ({
  projects,
  projectsLoaded,
  filterOnActiveProjects,
  getProjectsByWorkplaceError,
  deleteFail,
  deleteInProgress,
}: IProjectsProps) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const [isDeleteProjectModalVisible, setIsDeleteProjectModalVisible] = useState(false)
  const [projectToDelete, setProjectToDelete] = useState<string>(null)
  const { companyGuid } = useParams<{ companyGuid: string }>()

  useEffect(() => {
    projectToDelete ? setIsDeleteProjectModalVisible(true) : setIsDeleteProjectModalVisible(false)
  }, [projectToDelete])

  useEffect(() => {
    dispatch(getProjectsByWorkplaceId(companyGuid))
  }, [companyGuid, getProjectsByWorkplaceId])

  const editProject = (row: IProjectList, event: MuiEvent<React.MouseEvent<HTMLElement>>) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    history.push(projectEditUrl(companyGuid, row.projektId))
  }

  const createProject = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()

    history.push(projectCreateUrl(companyGuid))
  }

  const displayProjectDetailsView: GridEventListener<'rowClick'> = (params: GridRowParams<IProjectList>) => {
    history.push(projectDetailsUrl(companyGuid, params.row.projektId))
  }

  const handleShowActiveProjectsChange = () => {
    dispatch(filterProjects(!filterOnActiveProjects))
  }

  const prepareProjectDeletion = (row: IProjectList, event: MuiEvent<React.MouseEvent<HTMLElement>>) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    setProjectToDelete(row.projektId)
  }

  const cancelProjectDeletion = () => {
    setProjectToDelete(null)
  }

  const handleDeleteProject = () => {
    dispatch(deleteProject(companyGuid, projectToDelete))
    setProjectToDelete(null)
  }

  if (!projectsLoaded || deleteInProgress) {
    return <Loading />
  }

  return (
    <Stack>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <FormControlLabel
          label="Visa även inaktiva projekt"
          control={<Checkbox checked={!filterOnActiveProjects} onChange={handleShowActiveProjectsChange} />}
        />
        <Button onClick={event => createProject(event)}>Skapa nytt projekt</Button>
      </Box>

      <Typography variant="subtitle1" marginBottom={1}>
        {projects.length} projekt
      </Typography>

      {getProjectsByWorkplaceError ? (
        <Alert severity="error">Fel vid hämtning av projekt</Alert>
      ) : deleteFail ? (
        <Alert severity="error">Det gick inte att radera projektet</Alert>
      ) : null}

      <DataGridWrapper
        rows={projects}
        columns={projectsColumns(editProject, prepareProjectDeletion)}
        getRowId={(row: IProjectList) => row.projektId}
        labelRowsPerPage="Projekt per sida"
        onRowClick={displayProjectDetailsView}
      />

      <ConfirmationModal
        active={isDeleteProjectModalVisible}
        close={cancelProjectDeletion}
        submit={handleDeleteProject}
        message="Är du säker på att du vill ta bort detta projekt?"
        title="Bekräfta borttagning"
      />
    </Stack>
  )
}

export default Projects
