import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContactModel } from './CompanyTabs/Contacts/Contacts.model'
import { ICompany } from './Company.model'

export interface CompanyStore {
  company: ICompany | null
  fetchCompanyFailed: boolean
  companyLoaded: boolean
  contacts: ContactModel[]
  contactsLoaded: boolean
  countryCodesLoaded: boolean
  createOrUpdateContactErrorMessage: string
  createOrUpdateContactFailed: boolean
  createOrUpdateContactStarted: boolean
  createContactInProgress: boolean
  updateContactInProgress: boolean
  deleteContactInProgress: boolean
  detailedContact: ContactModel | null
  managersLoaded: boolean
  statusesLoaded: boolean
  updateCompanyErrorMessage: string
  updateCompanyInProgress: boolean
}

export const initialState: CompanyStore = {
  company: null,
  fetchCompanyFailed: false,
  companyLoaded: false,
  contacts: [],
  contactsLoaded: false,
  countryCodesLoaded: false,
  createOrUpdateContactErrorMessage: '',
  createOrUpdateContactFailed: false,
  createOrUpdateContactStarted: false,
  updateContactInProgress: false,
  createContactInProgress: false,
  deleteContactInProgress: false,
  detailedContact: null,
  managersLoaded: false,
  statusesLoaded: false,
  updateCompanyErrorMessage: '',
  updateCompanyInProgress: false,
}

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    updateCompanyStarted: state => {
      state.updateCompanyInProgress = true
      state.updateCompanyErrorMessage = ''
    },
    updateCompanySuccess: state => {
      state.updateCompanyInProgress = false
    },
    updateCompanyFailure: (state, action: PayloadAction<string>) => {
      state.updateCompanyInProgress = false
      state.updateCompanyErrorMessage = action.payload
    },
    fetchCompanyStarted: state => {
      state.companyLoaded = false
      state.fetchCompanyFailed = false
      state.updateCompanyErrorMessage = ''
      state.company = null
    },
    fetchCompanySuccess: (state, action: PayloadAction<ICompany>) => {
      state.companyLoaded = true
      state.fetchCompanyFailed = false
      state.company = action.payload
    },
    fetchCompanyFailure: (state) => {
      state.fetchCompanyFailed = true
      state.company = null
    },
    fetchContactStarted: state => {
      state.contactsLoaded = false
    },
    fetchContactSuccess: (state, action: PayloadAction<ContactModel[]>) => {
      state.contactsLoaded = true
      state.contacts = action.payload
    },
    fetchContactFailed: state => {
      state.contacts = []
      state.contactsLoaded = true
    },
    deleteContactStarted: (state, action: PayloadAction<boolean>) => {
      state.contactsLoaded = !action.payload
      state.deleteContactInProgress = true
    },
    deleteContactSuccess: state => {
      state.contactsLoaded = true
      state.deleteContactInProgress = false
    },
    deleteContactFailed: state => {
      state.contactsLoaded = true
      state.deleteContactInProgress = false
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fetchDetailedContactStarted: () => {},
    fetchDetailedContactSuccess: (state, action: PayloadAction<ContactModel>) => {
      state.detailedContact = action.payload
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fetchDetailedContactFailure: () => {},
    updateContactStarted: state => {
      state.createOrUpdateContactStarted = true
      state.updateContactInProgress = true
    },
    updateContactSuccess: state => {
      state.createOrUpdateContactStarted = false
      state.updateContactInProgress = false
    },
    updateContactFailure: (state, action: PayloadAction<string>) => {
      state.createOrUpdateContactFailed = true
      state.createOrUpdateContactStarted = false
      state.createOrUpdateContactErrorMessage = action.payload
      state.updateContactInProgress = false
    },
    createContactStarted: state => {
      state.createOrUpdateContactStarted = true
      state.createContactInProgress = true
    },
    createContactSuccess: state => {
      state.createOrUpdateContactStarted = false
      state.createContactInProgress = false
    },
    createContactFailure: (state, action: PayloadAction<string>) => {
      state.createOrUpdateContactFailed = true
      state.createOrUpdateContactStarted = false
      state.createOrUpdateContactErrorMessage = action.payload
      state.createContactInProgress = false
    },
    resetContactsTab: state => {
      state.contacts = []
      state.contactsLoaded = true
      state.detailedContact = undefined
    },
  },
})

export const { actions, reducer: companyReducer } = companySlice
export const {
  fetchCompanyStarted,
  fetchCompanySuccess,
  fetchCompanyFailure,
  fetchContactStarted,
  fetchContactSuccess,
  fetchContactFailed,
  createContactSuccess,
  createContactFailure,
  deleteContactStarted,
  deleteContactSuccess,
  createContactStarted,
  updateContactStarted,
  updateContactSuccess,
  updateContactFailure,
  deleteContactFailed,
  fetchDetailedContactStarted,
  fetchDetailedContactSuccess,
  fetchDetailedContactFailure,
  updateCompanyStarted,
  updateCompanyFailure,
  updateCompanySuccess,
  resetContactsTab,
} = companySlice.actions

export default companyReducer
