import React from 'react'

import { RangeInput } from './Common/RangeInput'
import { IRangeData } from './Common/IRangeData'

interface Props extends IRangeData {
    disabled?: boolean
}

export const WhiteCollarRangeInput = React.memo((props: Props) => {
    return <RangeInput
        {...props}
        label='Antal tjänstemän enligt TRR'
    />
})