import { axiosInstance } from '@local/Utils/network/axios.config'
import {
  createCompanyFailure,
  createCompanyInitiated,
  createCompanySuccess,
  fetchProjectSearchInitiated,
  fetchProjectSearchSuccess,
  fetchProjectsSearchFailure,
} from '@local/Scenes/Home/Home.reducers'
import { AppDispatch } from '@local/Store/configureStore'

export const displayProjects = () => (dispatch: AppDispatch) => {
  dispatch(fetchAllProjects())
}

const fetchAllProjects = () => (dispatch: AppDispatch) => {
  dispatch(fetchProjectSearchInitiated())

  axiosInstance.get('projects')
    .then(res => {
      dispatch(fetchProjectSearchSuccess({ projectData: res.data, type: 'PROJECT' }))
    })
    .catch(() => {
      dispatch(fetchProjectsSearchFailure())
    })
}

export const createCompany = (organizationalNumber: string) => (dispatch: AppDispatch) => {
  dispatch(createCompanyInitiated())
  axiosInstance.post('workplaces', { organizationalNumber })
    .then(() => {
      dispatch(createCompanySuccess())
    })
    .catch(errorMessage => {
      dispatch(createCompanyFailure(errorMessage as string))
    })
}
