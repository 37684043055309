import { IWorkplaceSearchResult } from '@local/src/Models/WorkplaceSearch/IWorkplaceSearchResult.model'

import { organisationsregistretApi } from '../../organisationsregistretApi'

import { IWorkplaceSearchResponseApiModel, mapToWorkplace } from './IWorkplacesSearchResponseApiModel'
import { IWorkplacesSearchApiModel } from './IWorkplacesSearchApiModel'

export const MAX_NUMBER_OF_WORKPLACE_SEARCH_RESULTS = 100000

export const workplacesSearchApi = organisationsregistretApi.injectEndpoints({
  endpoints: (builder) => ({
    searchWorkplaces: builder.query<IWorkplaceSearchResult, IWorkplacesSearchApiModel>({
      query: (apiModel) => ({
        url: `workplaces/workplaceSearch2`,
        method: 'POST',
        body: apiModel,
      }),
      transformResponse: (data: { results: IWorkplaceSearchResponseApiModel[]; totalCount: number }) => ({
        workplaces: data.results.map(mapToWorkplace),
        totalCount: Math.min(data.totalCount, MAX_NUMBER_OF_WORKPLACE_SEARCH_RESULTS),
      }),
    }),
  }),
})

export const { useLazySearchWorkplacesQuery } = workplacesSearchApi
