import { ISingleSelectionData } from '@local/src/Components/Inputs/Selection/Common/ISingleSelectionData'
import { IUrlSyncedData } from '@local/src/Utils/helpers/Url/IUrlSyncedData'
import { useUrlSync } from '@local/src/Utils/helpers/Url/useUrlSync'
import { useState } from 'react'

export interface IIsMainContactFilterData extends IUrlSyncedData, ISingleSelectionData<IsMainContact> {}

export type IsMainContact = 'Ja' | 'Nej' | ''

export const useIsMainContactFilter = (): IIsMainContactFilterData => {
  const [value, setValue] = useState<IsMainContact>('')
  const { wasLoadedFromUrl, updateValue } = useUrlSync<IsMainContact>(
    'isMainContact',
    setValue,
    (s) => s,
    (s) => {
      if (s === 'Ja') return 'Ja'
      if (s === 'Nej') return 'Nej'

      return ''
    },
    []
  )

  return {
    selection: value === '' ? null : value,
    options: ['Ja', 'Nej'],
    setSelection: (value) => updateValue(value ?? ''),
    wasLoadedFromUrl,
    isLoading: false,
    isError: false,
  }
}
