import { ISelectionData } from '@local/src/Components/Inputs/Selection/Common/ISelectionData'
import { IWorkplaceManager } from '@local/src/Models/Domain/WorkplaceManager.model'
import { IUrlSyncedData } from '@local/src/Utils/helpers/Url/IUrlSyncedData'
import { useUrlSynchedEntityOptionsSelection } from '@local/src/Utils/helpers/Url/useUrlSynchedEntityOptionsSelection'
import { useGetCompanyManagersQuery } from '@local/src/Utils/network/endpoints/workplacesApi'

export interface INationalManagerFilterData extends IUrlSyncedData, ISelectionData<IWorkplaceManager> {}

export const useNationalManagerFilter = (): INationalManagerFilterData =>
  useUrlSynchedEntityOptionsSelection<IWorkplaceManager>('nationalManagers', useGetCompanyManagersQuery)
