import { GridColDef, MuiEvent } from '@mui/x-data-grid'
import { tableTooltip } from '@local/src/Utils/helpers/tooltips'
import { formatUtcDate } from '@local/src/Utils/helpers'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import { ActivityModel } from './Activities.model'

export const activitiesColumns = (
  editFunc: (row: ActivityModel, event: MuiEvent<React.MouseEvent<HTMLElement>>) => void,
  deleteFunc: (row: ActivityModel, event: MuiEvent<React.MouseEvent<HTMLElement>>) => void
): GridColDef<ActivityModel>[] => {
  return [
    {
      headerName: 'Datum',
      field: 'range.start',
      valueGetter: ({ row }) => row?.range?.start,
      renderCell: ({ row }) => tableTooltip(formatUtcDate(row?.range?.start)),
      flex: 1,
      maxWidth: 100,
    },
    {
      headerName: 'Typ av händelse',
      field: 'processType.processTypeDescription',
      valueGetter: ({ row }) => row?.processType?.processTypeDescription,
      renderCell: ({ row }) => tableTooltip(row?.processType?.processTypeDescription),
      flex: 1,
      maxWidth: 225,
    },
    {
      headerName: 'Ämnesområde',
      field: 'subjectMatter.subjectMatterDescription',
      valueGetter: ({ row }) => row?.subjectMatter?.subjectMatterDescription,
      renderCell: ({ row }) => tableTooltip(row?.subjectMatter?.subjectMatterDescription),
      flex: 1,
      maxWidth: 150,
    },
    {
      headerName: 'Notering',
      field: 'text',
      renderCell: ({ row }) => tableTooltip(row?.text),
      flex: 1,
    },
    {
      headerName: '',
      field: 'Redigera',
      renderCell: ({ row }) => {
        return (
          <>
            <Tooltip title="Redigera">
              <IconButton onClick={event => editFunc(row, event)} aria-label="Redigera händelse">
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ta bort">
              <IconButton onClick={event => deleteFunc(row, event)} aria-label="Ta bort händelse">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )
      },
      flex: 1,
      maxWidth: 100,
      sortable: false,
    },
  ]
}
