import React, { useState } from 'react'
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  Typography,
  styled,
  AccordionDetailsProps,
  AccordionProps
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowDownIcon color='primary' />}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    flexGrow: 0,
  },
  justifyContent: 'flex-start',
  padding: 0,
  gap: theme.spacing(1),
  alignSelf: 'baseline',
}))

const AccordionDetails = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails {...props} />
))(() => ({
  padding: 0
}))

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props}/>
))(() => ({
  display: 'flex',
  flexDirection: 'column',
  '&:before': {
    display: 'none'
  }
}))

interface AccordionBoxProps {
  label: string
  expandedLabel?: string
  children: React.ReactNode
  expanded?: boolean
  onToggle?: (open: boolean) => void
}

const AccordionBox: React.FC<AccordionBoxProps> = ({
  label,
  expandedLabel,
  children,
  expanded = false,
  onToggle,
}): JSX.Element => {

  const [isExpanded, setIsExpanded] = useState<boolean>(expanded)

  const handleChange = (_, expanded: boolean) => {
    setIsExpanded(expanded)
    onToggle?.(expanded)
  }

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary>
        <Typography variant='body1' color='primary.main' display='flex' gap={1}>
          { isExpanded && expandedLabel ? <>{expandedLabel}</> : <>{label}</>}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionBox