import { ISelectionData } from '@local/src/Components/Inputs/Selection/Common/ISelectionData'
import { IContactCategory } from '@local/src/Models/Domain/ContactCategory.model'
import { IUrlSyncedData } from '@local/src/Utils/helpers/Url/IUrlSyncedData'
import { useUrlSynchedEntityOptionsSelection } from '@local/src/Utils/helpers/Url/useUrlSynchedEntityOptionsSelection'
import { useGetContactCategoriesQuery } from '@local/src/Utils/network/endpoints/workplacesApi'

export interface IContactCategoryFilterData extends IUrlSyncedData, ISelectionData<IContactCategory> {}

export const useContactCategoryFilter = (): IContactCategoryFilterData =>
  useUrlSynchedEntityOptionsSelection<IContactCategory>('categories', useGetContactCategoriesQuery)
