import { Link, Typography } from '@mui/material'
import React from 'react'


interface LinkWithIconProps {
  icon: JSX.Element
  text: string
  external?: boolean
  onClick?: () => void
  href?: string
  iconPlacement?: 'left' | 'right'
  textVariant?: 'subtitle1' | 'subtitle2' | 'body1' | 'body2'
}

const LinkWithIcon = ({
  icon,
  text,
  external = false,
  onClick,
  href,
  iconPlacement = 'left',
  textVariant = 'body1',
}: LinkWithIconProps): JSX.Element => {

  const isButtonVariant = href === null || href === undefined

  if (href && onClick) {
    console.error('Both href and onClick props should not be provided simultaneously.')
  }

  if (isButtonVariant && !onClick) {
    console.error('onClick prop is required when href is not set.')
  }

  return (
    <Link
      component={isButtonVariant ? 'button' : 'a'}
      href={isButtonVariant ? undefined : href}
      rel={external ? 'noopener noreferrer' : undefined}
      target={external ? '_blank' : undefined}
      onClick={onClick}
      display='flex'
      alignItems='center'
      gap={1}
    >
      {iconPlacement === 'left' && (<>{icon}</>)}
      <Typography variant={textVariant} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</Typography>
      {iconPlacement === 'right' && (<>{icon}</>)}
    </Link>
  )
}

export default LinkWithIcon