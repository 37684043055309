import { GridColDef, MuiEvent } from '@mui/x-data-grid'
import { IconButton } from '@mui/material'
import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import DescriptionIcon from '@mui/icons-material/Description'
import { formatUtcDate } from '@local/src/Utils/helpers'
import LinkWithIcon from '@local/src/Components/LinkWithIcon/LinkWithIcon'

import { IProjectAttachment } from '../Projects.types'

export const documentColumns = (
  downloadFunc: (row: IProjectAttachment) => void,
  deleteFunc: (row: IProjectAttachment, event: MuiEvent<React.MouseEvent<HTMLElement>>) => void
  ): GridColDef<IProjectAttachment>[] => {
  return [
    {
      headerName: 'Dokument',
      field: 'fileName',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <LinkWithIcon
            icon={<DescriptionIcon fontSize='small' />}
            onClick={() => downloadFunc(row)}
            text={row.fileName}
          />
        )
      }
    },
    {
      headerName: 'Uppladdningsdatum',
      field: 'createdAt',
      flex: 1,
      renderCell: ({ row }) => row?.createdAt && formatUtcDate(row?.createdAt),
      maxWidth: 175,
    },
    {
      headerName: '',
      field: 'delete',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <IconButton
            size='small'
            data-testid='delete-attachment-btn'
            onClick={(event) => deleteFunc(row, event)}
            >
            <DeleteIcon/>
          </IconButton>
        )
      },
      maxWidth: 50,
      sortable: false,
    },
  ]
}