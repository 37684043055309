export const durations = [
  {
    value: 15,
    label: '15 min',
  },
  {
    value: 30,
    label: '30 min',
  },
  {
    value: 45,
    label: '45 min',
  },
  {
    value: 60,
    label: '1h',
  },
  {
    value: 75,
    label: '1h 15 min',
  },
  {
    value: 90,
    label: '1h 30 min',
  },
  {
    value: 120,
    label: '2h',
  },
  {
    value: 150,
    label: '2h 30 min',
  },
  {
    value: 180,
    label: '3h',
  },
  {
    value: 240,
    label: '4h',
  },
  {
    value: 300,
    label: '5h',
  },
  {
    value: 360,
    label: '6h',
  },
  {
    value: 420,
    label: '7h',
  },
  {
    value: 480,
    label: '8h',
  },
]
