import { useEffect, useState } from 'react'
import { axiosInstance } from '@local/Utils/network/axios.config'

import { ISelectOption } from '../App.types'

const useFetchArbetsstallen = (searchCriteria: string, companyGuid: string) => {

  const [arbetsstallenLoading, setArbetsstallenLoading] = useState(false)
  const [arbetstallenOptions, setArbetsstallenOptions] = useState<ISelectOption[]>([])

  useEffect(() => {
    if (!searchCriteria) return
    setArbetsstallenLoading(true)
    axiosInstance.post('workplaces/workplaceSearch', { fullName: searchCriteria })
      .then(res => {
        const mappedSearchResult = (res.data as { fullName: string; address: string; workplaceId: string }[])
          .filter(workplace => workplace.workplaceId !== companyGuid)
          .map(workplace => ({
            value: workplace.workplaceId,
            label: `${workplace.fullName}${workplace.address ? `, ${workplace.address}` : ''}`,
          })).sort((a, b) => a.label.localeCompare(b.label))
        setArbetsstallenOptions(mappedSearchResult)
        setArbetsstallenLoading(false)
      })
      .catch(() => {
        console.error('failed fetching arbetsstallen')
      })
  }, [companyGuid, searchCriteria])

  return { arbetsstallenLoading, arbetstallenOptions }
}

export default useFetchArbetsstallen