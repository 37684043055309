import { ISelectOption } from '@local/src/App.types';
import { IWorkplacesSearchApiModel } from '@local/src/Utils/network/endpoints/WorkplacesSearch/IWorkplacesSearchApiModel';
import { useSearchWorkplacesQuery } from '@local/src/Utils/network/endpoints/WorkplacesSearch/workplacesSearchApi';
import { Autocomplete, Checkbox, Stack, TextField, Typography } from '@mui/material'
import React from 'react'

interface Props {
    currentWorkplaceId: string
    selection: ISelectOption[]
    organizationalNumber: string
    onChange: (selection: ISelectOption[]) => void
}

export const WorkplaceSelection = ({ currentWorkplaceId, selection, organizationalNumber, onChange }: Props) => {
    const { data, isFetching, isError } = useSearchWorkplacesQuery({
        organizationalNumber: organizationalNumber,
        orderBy: [{
            property: 'fullName',
            order: 'ascending'
        }]
    } as IWorkplacesSearchApiModel);

    const options = data?.workplaces.filter(w => w.workplaceId !== currentWorkplaceId).map(w => ({
        value: w.workplaceId,
        label: `${w.fullName}${w.address ? `, ${w.address}` : ''}`
    } as ISelectOption)) ?? []

    const allSelected = selection.length === options.length
    const someSelected = selection.length > 0 && selection.length < options.length
    const noneSelected = selection.length === 0

    const handleOnCheck = () => {
        if (noneSelected)
            onChange(options)
        else
            onChange([])
    }

    return (
        <Stack direction='column' rowGap={3}>
            <Stack direction='row' alignItems='center'>
                <Checkbox checked={allSelected} indeterminate={someSelected} onClick={handleOnCheck} />
                {noneSelected && <Typography>Välj alla {options.length} arbetställen under detta organisationsnummer</Typography>}
                {someSelected && <Typography>{selection.length} av {options.length} arbetsställen valda</Typography>}
                {allSelected && <Typography>Alla {options.length} arbetsställen valda under detta organisationsnummer</Typography>}
            </Stack>
            <Autocomplete
                options={options}
                value={selection}
                multiple
                disablePortal
                renderInput={params => <TextField {...params} label='Arbetsställen' helperText='Frivilligt' />}
                disabled={isError}
                loading={isFetching}
                getOptionKey={(o) => o.value}
                getOptionLabel={(o) => o.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(_, newSelection) => onChange(newSelection)}
                disableCloseOnSelect
            />
        </Stack>
    )
}

