import React, { useEffect, useMemo } from 'react'
import { Button, Stack } from '@mui/material'
import { IPagingModel } from '@local/src/Models/Misc/Paging.model'
import { ISortingModel } from '@local/src/Models/Misc/Sorting.model'
import useDebounce from '@local/src/Utils/helpers/useDebounce'
import { KeyboardArrowLeft } from '@mui/icons-material'

import { MyWorkplaces } from '../MyWorkplaces/MyWorkplaces'

import { WorkplaceSearchResult } from './WorkplaceSearchResult'
import { WorkplaceSearchFilter } from './WorkplaceSearchFilter'
import { useWorkplaceSearch } from './Hooks/useWorkplaceSearch'


export const WorkplaceSearch = () => {
    const { filterData, pagingData, sortingData, queryData } = useWorkplaceSearch()
    const shouldSearch = useDebounce(filterData.wasAnyFilterLoadedFromUrl, 300)

    useEffect(() => {
        if (shouldSearch)
            onSearch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldSearch])


    const handleOnPaging = (value: IPagingModel) => {
        pagingData.setPaging(value)
        queryData.performSearch(filterData, value, sortingData.sorting)
    }

    const handleOnSorting = (value: ISortingModel) => {
        pagingData.setPaging({ skip: 0, take: pagingData.paging.take })
        sortingData.setSorting(value)
        queryData.performSearch(filterData, { skip: 0, take: pagingData.paging.take }, value)
    }

    const onSearch = () => {
        pagingData.setPaging({ skip: 0, take: pagingData.paging.take })
        queryData.performSearch(filterData, { skip: 0, take: pagingData.paging.take }, sortingData.sorting)
    }

    const returnToMyWorkplaces = () => {
        filterData.resetFilters()
        queryData.clearSearch()
        pagingData.setPaging({ skip: 0, take: pagingData.paging.take })
    }

    const showSearchResult = queryData.hasSearched

    return (
        <Stack>
            <WorkplaceSearchFilter filterData={filterData} isSearching={queryData.isSearching} onSearch={onSearch} />
            {useMemo(() => !showSearchResult && <MyWorkplaces />, [showSearchResult])}
            {showSearchResult && <>
                <Button
                    variant='text'
                    size='small'
                    startIcon={<KeyboardArrowLeft />}
                    onClick={returnToMyWorkplaces}
                    sx={{ marginBottom: '1em' }}
                >Tillbaka till Mina företag</Button>
                <WorkplaceSearchResult
                    queryData={queryData}
                    paging={pagingData.paging}
                    onPagingChanged={handleOnPaging}
                    sorting={sortingData.sorting}
                    onSortingChanged={handleOnSorting}
                />
            </>}
        </Stack >
    )
}


