import { ActivityCategories, ActivitySeminarCodes, ActivityStatuses, SubjectMatters } from '@local/src/App.types'
import { ProcessType } from '@local/src/Scenes/Company/CompanyTabs/Activities/Activities.model'

import { organisationsregistretApi } from '../organisationsregistretApi'

export const activitiesApi = organisationsregistretApi.injectEndpoints({
  endpoints: (builder) => ({
    getActivityCategories: builder.query<ActivityCategories[], void>({
      query: () => ({ url: 'activities/categories', method: 'GET' }),
      transformResponse: (data: ActivityCategories[]) =>
        data.map((d) => ({
          value: d.categoryCode,
          label: d.categoryDescription,
          processType: d.processType,
          categoryCode: d.categoryCode,
          categoryDescription: d.categoryDescription,
        })),
    }),

    getActivityProcessTypes: builder.query<ProcessType[], void>({
      query: () => ({ url: 'activities/processTypes', method: 'GET' }),
      transformResponse: (data: ProcessType[]) =>
        data.map(
          (d) =>
            ({
              value: d.processTypeCode,
              label: d.processTypeDescription,
              processTypeCode: d.processTypeCode,
              processTypeDescription: d.processTypeDescription,
            }) as ProcessType
        ),
    }),

    getActivitySeminarCodes: builder.query<ActivitySeminarCodes[], void>({
      query: () => ({ url: 'activities/seminarCodes', method: 'GET' }),
      transformResponse: (data: ActivitySeminarCodes[]) =>
        data.map(
          (d) =>
            ({
              value: d.customerSpecificSeminarCode,
              label: d.customerSpecificSeminarDescription,
              customerSpecificSeminarCode: d.customerSpecificSeminarCode,
              customerSpecificSeminarDescription: d.customerSpecificSeminarDescription,
              isActive: d.isActive,
            }) as ActivitySeminarCodes
        ),
    }),

    getActivityStatuses: builder.query<ActivityStatuses[], void>({
      query: () => ({ url: 'activities/statuses', method: 'GET' }),
      transformResponse: (data: ActivityStatuses[]) =>
        data.map((d) => ({
          value: d.statusCode,
          label: d.statusDescription,
          statusCode: d.statusCode,
          statusDescription: d.statusDescription,
          processTypeCode: d.processTypeCode,
        })),
    }),

    getActivitySubjectMatters: builder.query<SubjectMatters[], void>({
      query: () => ({ url: 'activities/subjectMatters', method: 'GET' }),
      transformResponse: (data: SubjectMatters[]) =>
        data.map((d) => ({
          value: d.subjectMatterCode,
          label: d.subjectMatterDescription,
          subjectMatterCode: d.subjectMatterCode,
          subjectMatterDescription: d.subjectMatterDescription,
        })),
    }),
  }),
})

export const {
  useGetActivityCategoriesQuery,
  useGetActivityProcessTypesQuery,
  useGetActivitySeminarCodesQuery,
  useGetActivityStatusesQuery,
  useGetActivitySubjectMattersQuery,
} = activitiesApi
