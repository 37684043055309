import { tableTooltip } from '@local/src/Utils/helpers/tooltips'
import { Box, IconButton, Tooltip } from '@mui/material'
import { GridColDef, MuiEvent } from '@mui/x-data-grid'
import React from 'react'
import StarIcon from '@mui/icons-material/Star'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import { ContactModel } from './Contacts.model'

export const contactColumns = (
  editFunc: (row: ContactModel, event: MuiEvent<React.MouseEvent<HTMLElement>>) => void,
  deleteFunc: (row: ContactModel, event: MuiEvent<React.MouseEvent<HTMLElement>>) => void): GridColDef<ContactModel>[] => {
  return [
    {
      headerName: 'Huvudkontakt',
      field: 'isMainContact',
      renderCell: ({ row }) => {
        return row.isMainContact ? (
          <Box width='100%' display='flex' justifyContent='center'>
            <Tooltip title='Huvudkontakt'>
              <StarIcon color='primary' />
            </Tooltip>
          </Box>
        ) : ''
      },
      flex: 1,
      maxWidth: 125,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      headerName: 'Namn',
      field: 'fullName',
      renderCell: ({ row }) => tableTooltip(row?.fullName),
      flex: 1,
    },
    {
      headerName: 'Kategori',
      field: 'roleLevelOne.roleLevel1Text',
      valueGetter: ({ row }) => row?.roleLevelOne?.roleLevel1Text,
      renderCell: ({ row }) => tableTooltip(row?.roleLevelOne?.roleLevel1Text),
      flex: 1,
      maxWidth: 100,
    },
    {
      headerName: 'Roll',
      field: 'roleLevelTwo.roleLevel2Text',
      valueGetter: ({ row }) => row?.roleLevelTwo?.roleLevel2Text,
      renderCell: ({ row }) => tableTooltip(row?.roleLevelTwo?.roleLevel2Text),
      flex: 1,
    },
    {
      headerName: 'E-post',
      field: 'email',
      renderCell: ({ row }) => tableTooltip(row?.email),
      flex: 1,
    },
    {
      headerName: 'Status',
      field: 'status.statusText',
      valueGetter: ({ row }) => row?.status?.statusText,
      renderCell: ({ row }) => tableTooltip(row?.status?.statusText),
      flex: 1,
      maxWidth: 175,
    },
    {
      headerName: 'Mobil',
      field: 'mobile.number',
      valueGetter: ({ row }) => row?.mobile?.number,
      renderCell: ({ row }) => tableTooltip(row?.mobile?.number),
      flex: 1,
      maxWidth: 125,
    },
    {
      headerName: 'Telefon',
      field: 'phone.number',
      valueGetter: ({ row }) => row?.phone?.number,
      renderCell: ({ row }) => tableTooltip(row?.phone?.number),
      flex: 1,
      maxWidth: 125,
    },
    {
      headerName: 'Senast uppdaterad',
      field: 'updatedBy',
      valueGetter: ({ row }) => row.updatedBy.timestamp ?? row.createdBy.timestamp,
      renderCell: ({ row }) => tableTooltip((row.updatedBy.timestamp ?? row.createdBy.timestamp).substring(0, 10)),
      flex: 1,
    },
    {
      headerName: '',
      field: 'Redigera',
      renderCell: ({ row }) => {
        return (
          <>
            <Tooltip title='Redigera'>
              <IconButton onClick={(event) => editFunc(row, event)} aria-label='Redigera kontaktperson'>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Ta bort'>
              <IconButton onClick={(event) => deleteFunc(row, event)} aria-label='Ta bort kontaktperson'>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )
      },
      flex: 1,
      maxWidth: 100,
      sortable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ]
}