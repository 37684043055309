import React from 'react'
import { yesNo } from '@local/Utils/helpers'
import WorkIcon from '@mui/icons-material/Work'
import LaunchIcon from '@mui/icons-material/Launch'
import { Box, Grid, Link, Typography } from '@mui/material'
import LinkWithIcon from '@local/src/Components/LinkWithIcon/LinkWithIcon'
import InformationPopover from '@local/src/Components/InformationPopover/InformationPopover'
import { MailOutline } from '@mui/icons-material'

import { ICompany } from '../../Company.model'

interface InfoSectionProps {
  company: ICompany
}

const InfoSection = ({
  company,
}: InfoSectionProps): JSX.Element => {

  const homepageUrl =
    company.homepage?.substring(0, 4) !== 'http' ? `http://${company.homepage}` : `${company.homepage}`
  const customerEvaluationLink = 'https://link.webropolsurveys.com/Participation/Public/6a96d7a8-9642-4dc2-bf09-664554b726f8?displayId=Swe2763549'

  return (
    <Box>
      <Box display='flex' alignItems='center' gap={1} marginBottom={2}>
        <WorkIcon />
        <Typography variant="subtitle2">Arbetsställe</Typography>
      </Box>

      <Box display='flex' alignItems='flex-end'>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2">Arbetsställe</Typography>
            <Typography variant='body1' color='text.secondary'>{company.mainName}</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2">Ort</Typography>
            <Typography variant='body1' color='text.secondary'>{company.visitingAddress.town}</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2">Webbsida</Typography>
            {company.homepage && (
              <LinkWithIcon
                href={homepageUrl}
                external
                icon={<LaunchIcon fontSize='small' />}
                text={company.homepage}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display="flex" alignItems="center" height="19px">
              <Typography variant="subtitle2">Kundleverans TRR info</Typography>
              <InformationPopover
                iconSize="small"
                infoText="När du klickar på länken öppnas ett mejl med länken till utvärderingen bifogad."
              />
            </Box>
            <LinkWithIcon
              text="Skicka utvärdering"
              href={`mailto:?to=%20&body=${customerEvaluationLink}`}
              icon={<MailOutline fontSize="small" />}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2">Telefon</Typography>
            <Typography variant='body1' color='text.secondary'>{company.phoneNumber}</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2">Företagsstatus</Typography>
            <Typography variant='body1' color='text.secondary'>{company.companyStatus.companyStatusDescription}</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2">E-post</Typography>
            {company.email && (
              <Link
                target="_top"
                rel="noopener noreferrer"
                href={`mailto:${company.email}`}
              >
                <Typography variant='body1' sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{company.email}</Typography>
              </Link>

            )}
          </Grid>
          <Grid item sm={3} />
          <Grid item xs={12} sm={3} minHeight='60px'>
            <Typography variant="subtitle2">Nationellt ansvarig</Typography>
            <Typography variant='body1' color='text.secondary'>{company.nationalCustomerManager.employeeName}</Typography>
          </Grid>
          <Grid item xs={12} sm={3} minHeight='60px'>
            <Typography variant="subtitle2">Lokalt ansvarig</Typography>
            <Typography variant='body1' color='text.secondary'>{company.localCustomerManager.employeeName}</Typography>
          </Grid>
          <Grid item xs={12} sm={3} minHeight='60px'>
            <Typography variant="subtitle2">Lokal kontakt</Typography>
            <Typography variant='body1' color='text.secondary'>{company.localContact.employeeName}</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2">Lokalt Strategisk</Typography>
            <Typography variant='body1' color='text.secondary'>{yesNo(company.locallyImportant)}</Typography>
          </Grid>
        </Grid>
      </Box>

    </Box >
  )
}

export default InfoSection
