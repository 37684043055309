import { companyContactUrl } from '@local/src/basename'
import { IContact } from '@local/src/Models/Domain/Contact.model'
import { tableTooltip } from '@local/src/Utils/helpers/tooltips'
import { Link } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import React from 'react'

export const workplaceNameColumn: GridColDef<IContact> = {
    headerName: 'Arbetsställe',
    field: 'Arbetsstalle_Namn',
    valueGetter: ({ row }) => row?.workplace.name,
    renderCell: ({ row, formattedValue }) => <WorkplaceNameField formattedValue={formattedValue} workplaceId={row?.workplace.id} />,
    flex: 1
}

interface Props {
    formattedValue: string
    workplaceId: string
}
const WorkplaceNameField = React.memo(({ formattedValue, workplaceId }: Props) => (
    <Link href={companyContactUrl(workplaceId)} onClick={e => e.stopPropagation()} sx={{ width: 'calc(100% - 14px)' }}>
        {tableTooltip(formattedValue)}
    </Link>
))