import React, { useEffect } from 'react'
import useDebounce from '@local/src/Utils/helpers/useDebounce'
import { Button } from '@mui/material'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { IPagingModel } from '@local/src/Models/Misc/Paging.model'
import { ISortingModel } from '@local/src/Models/Misc/Sorting.model'

import { ContactSearchFilter } from './Components/Filter/ContactSearchFilter'
import { ContactSearchResult } from './Components/ContactSearchResult'
import { useContactSearch } from './Hooks/useContactSearch'
import { MyContacts } from './Components/MyContacts'

export const ContactSearch = () => {
    const { filterData, pagingData, sortingData, queryData } = useContactSearch()
    const shouldSearch = useDebounce(filterData.wasAnyFilterLoadedFromUrl, 300)

    useEffect(() => {
        if (shouldSearch)
            onSearch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldSearch])

    const handleOnPaging = (value: IPagingModel) => {
        pagingData.setPaging(value)
        queryData.performSearch(filterData, value, sortingData.sorting)
    }

    const handleOnSorting = (value: ISortingModel) => {
        pagingData.setPaging({ skip: 0, take: pagingData.paging.take })
        sortingData.setSorting(value)
        queryData.performSearch(filterData, { skip: 0, take: pagingData.paging.take }, value)
    }

    const onSearch = () => {
        pagingData.setPaging({ skip: 0, take: pagingData.paging.take })
        queryData.performSearch(filterData, { skip: 0, take: pagingData.paging.take }, sortingData.sorting)
    }

    const returnToMyContacts = () => {
        filterData.resetFilters()
        queryData.clearSearch()
        pagingData.setPaging({ skip: 0, take: pagingData.paging.take })
    }

    return <>
        <ContactSearchFilter filterData={filterData} onSearch={onSearch} isSearching={false} />
        {!queryData.hasSearched && <MyContacts />}
        {queryData.hasSearched && <>
            <Button
                variant='text'
                size='small'
                startIcon={<KeyboardArrowLeft />}
                onClick={returnToMyContacts}
                sx={{ marginBottom: '1em' }}
            >Tillbaka till Mina kontaktpersoner</Button>
            <ContactSearchResult paging={pagingData.paging} sorting={sortingData.sorting} queryData={queryData} onPagingChanged={handleOnPaging} onSortingChanged={handleOnSorting} />
        </>}
    </>
}