import { Stack, Alert, List, ListItem, Typography, SxProps, Theme, IconButton, Box } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { contactDetailsEditUrl } from '@local/src/basename'

import { ContactModel } from '../../Contacts.model'

interface DuplicateContactsProps {
  contacts: ContactModel[]
  companyId: string
  warningText: string
}

const DuplicateContacts = ({
  contacts,
  companyId,
  warningText,
}: DuplicateContactsProps): JSX.Element => {

  const history = useHistory()

  type StyleNames = | 'listItem' | 'list'

  const styles: Record<StyleNames, SxProps<Theme>> = {
    listItem: {
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row', md: 'row'},
      justifyContent: 'space-between',
      alignItems: { xs: 'center' },
      flexWrap: 'wrap'
    },
    list: {
      marginTop: 1,
      marginBottom: 2
    }
  }

  const editContact = (contactId: string) => {
    history.push(contactDetailsEditUrl(companyId, contactId))
  }

  return (
    <>
      { contacts.length > 0 && (
      <Stack>
        <Alert severity="warning">{warningText}</Alert>
        <List sx={styles.list}>
          { contacts.map((contact: ContactModel, index: number) => (
            <ListItem key={index} sx={styles.listItem}>
              <Box textAlign={{xs: 'center', md: 'unset' }} width={{ xs: 200, md: 275 }}>
                <Typography variant='body1'>{`${contact.firstName} ${contact.lastName}`}</Typography>
                <Typography variant='caption' color={'text.secondary'}>{
                  contact.roleLevelTwo.roleLevel2Text
                  ? `${contact.roleLevelTwo.roleLevel2Text} - ${contact.status.statusText}`
                  : contact.status.statusText
                }</Typography>
              </Box>
              <Box textAlign={{xs: 'center', md: 'unset' }} mt={{xs: 2, md: 0}} width={{ md: '33%'}}>
                <Typography variant='body1'>{contact.email}</Typography>
              </Box>
              <IconButton
                onClick={() => editContact(contact.contactId)}
                size='small'
                sx={{ marginTop: { xs: 2, md: 0 }}}
              >
                <EditIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Stack>
      )}
    </>
  )
}

export default DuplicateContacts