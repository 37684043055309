import React, { useEffect, useRef } from 'react'
import { formatOrgNr } from '@local/Utils/helpers'
import { companyEditUrl } from '@local/src/basename'
import LaunchIcon from '@mui/icons-material/Launch'
import Loading from '@local/src/Components/Loading/Loading'
import InformationPopover from '@local/src/Components/InformationPopover/InformationPopover'
import LinkWithIcon from '@local/src/Components/LinkWithIcon/LinkWithIcon'
import EditIcon from '@mui/icons-material/Edit'
import { Alert, Box, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import AccordionBox from '@local/src/Components/AccordionBox/AccordionBox'
import { RootState } from '@local/Store/configureStore'
import { useHistory, useParams } from 'react-router-dom'
import { useLazyGetCompanyDetailsQuery } from '@local/src/Utils/network/endpoints/workplacesApi'

import { OrganizationalNumberLink } from '../CompanyTabs/CompanyGroup/Fragments'

import QuantityWithDate from './QuantityWithDate/QuantityWithDate'
import AddressSection from './AddressSection/AddressSection'
import InfoSection from './InfoSection/InfoSection'

const CompanyOverview = (): JSX.Element => {
  const { companyGuid } = useParams<{ companyGuid: string }>()
  const history = useHistory()

  const [fetchCompany, { data: company, isFetching: isLoading, isError: fetchCompanyFailed }] = useLazyGetCompanyDetailsQuery()

  const saveInProgress = useSelector((state: RootState) => state.company.updateCompanyInProgress)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))


  useEffect(() => {
    if ((!company && companyGuid) || company?.workplaceId !== companyGuid) {
      void fetchCompany(companyGuid, true)
    }
  }, [company, companyGuid])

  const prevSaveInProgress = useRef(saveInProgress)

  useEffect(() => {
    const finishedSaving = prevSaveInProgress.current && saveInProgress === false
    if (finishedSaving) {
      void fetchCompany(companyGuid)
    }
    prevSaveInProgress.current = saveInProgress
  }, [companyGuid, saveInProgress])



  if (fetchCompanyFailed) {
    return <Alert severity='error'>Något gick fel vid hämtning av företagsinformation.</Alert>
  }

  if (isLoading || !company) {
    return <Loading />
  }

  const reportLink = `https://app.powerbi.com/groups/67f45051-c89e-484b-abef-7b1e195d297d/reports/31dfd6c4-d30a-433b-bba6-4b09d7dea8b7/ReportSection?filter=Kundrapport/Organisationsnummer eq '${company.organizationalNumber}'`

  const showKundPopup = company.eligibilityStatus.eligibilityStatusCode === '03'

  return (
    <Paper variant="outlined" sx={{ paddingX: 3, paddingY: 2, overflow: 'hidden' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection={{ xs: 'column', sm: 'row' }}
        marginBottom={{ xs: 2, sm: 0 }}
      >
        <Box>
          <Typography variant="subtitle2">{company.companyType.name}</Typography>
          <Typography variant="h4" gutterBottom>
            {company.fullName}
          </Typography>
        </Box>
        <LinkWithIcon
          text="Redigera företagsinformation"
          icon={<EditIcon fontSize="small" />}
          onClick={() => history.push(companyEditUrl(companyGuid))}
        />
      </Box>

      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        gap={2}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
      >
        <Box>
          <Typography variant="subtitle2">Organisationsnr</Typography>
          <OrganizationalNumberLink orgNumber={formatOrgNr(company.organizationalNumber)} />
        </Box>

        <Box>
          <Typography variant="subtitle2">Antal tjänstemän (TRR)</Typography>
          <QuantityWithDate date={company.whiteCollarTrr.trrUpdateDate} quantity={company.whiteCollarTrr.trrNumber} />
        </Box>

        <Box>
          <Typography variant="subtitle2">Antal tjänstemän (D&B)</Typography>
          <QuantityWithDate
            date={company.whiteCollarBisnode.whitecollarBisnodeDate}
            quantity={company.whiteCollarBisnode.whitecollarBisnodeDescription}
          />
        </Box>

        <Box>
          <Box display="flex" alignItems="center" height="19px">
            <Typography variant="subtitle2">Kund</Typography>
            {showKundPopup && (
              <InformationPopover
                iconSize="small"
                infoText="Avtal avslutat innebär att kundföretagets försäkringsavtal har löpt ut, men att det fortfarande är
              möjligt att skicka in ansökningar för tjänstemän eftersom tiden för efterskydd inte har löpt ut
              ännu. För mer specifika detaljer kring ett visst kundföretag kontakta Avtal och ersättningar."
              />
            )}
          </Box>
          <Typography variant="body1" color="text.secondary">
            {company.eligibilityStatus.eligibilityStatusDescription}
          </Typography>
        </Box>

        {<Box>
          <Typography variant='subtitle2'>Huvudbransch</Typography>
          <Typography variant="body1" color="text.secondary">
            {company.trrIndustries?.find(ti => ti.isMain)?.description ?? "Data saknas"}
          </Typography>
        </Box>}

        <Box>
          <Box display="flex" alignItems="center" height="19px">
            <Typography variant="subtitle2">Analysrapport</Typography>
            <InformationPopover
              iconSize="small"
              infoText='Rapporten visar hur det gått för företagens tjänstemän.
              Syftet med rapporten är att kunna använda den som ett underlag i dialog med kund.
              Rapporten kan också presenteras för kunden som en pdf eller Power Point.
              Detta val görs inne i rapporten under rubriken "Exportera".'
            />
          </Box>
          <LinkWithIcon
            text='Återkoppling till kund'
            href={reportLink}
            external
            icon={<LaunchIcon fontSize="small" />}
          />
        </Box>
      </Box>

      <AccordionBox label="Visa mer företagsinformation" expandedLabel="Visa mindre företagsinformation">
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={2} justifyContent="space-between">
          <Box flex={1} maxWidth={isMobile ? 'inherit' : '40%'}>
            <AddressSection company={company} />
          </Box>
          <Box flex={1}>
            <InfoSection company={company} />
          </Box>
        </Box>
      </AccordionBox>
    </Paper>
  )
}

export default CompanyOverview
