import { configureStore, PreloadedState } from '@reduxjs/toolkit'
import { organisationsregistretApi } from '@local/Utils/network/organisationsregistretApi'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import createRootReducer from './rootReducer'

const rootReducer = createRootReducer()

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(organisationsregistretApi.middleware),
    devTools: { trace: true, traceLimit: 25 },
  })

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export default setupStore()
