import { Box, Button, Collapse, Divider, Stack } from '@mui/material'
import React from 'react'
import { Clear, KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material'
import { ContactCategorySelectionInput } from '@local/src/Components/Inputs/Selection/ContactCategorySelectionInput'
import { ContactRoleSelectionInput } from '@local/src/Components/Inputs/Selection/ContactRoleSelectionInput'
import { UnionSelectionInput } from '@local/src/Components/Inputs/Selection/UnionSelectionInput'
import { ContactStatusSelectionInput } from '@local/src/Components/Inputs/Selection/ContactStatusSelectionInput'
import { ContactResponsibleSelectionInput } from '@local/src/Components/Inputs/Selection/ContactResponsibleSelectionInput'
import { WorkplaceTypeSelectionInput } from '@local/src/Components/Inputs/Selection/WorkplaceTypeSelectionInput'
import { EligibilitySelectionInput } from '@local/src/Components/Inputs/Selection/EligibilitySelectionInput'
import { CountySelectionInput } from '@local/src/Components/Inputs/Selection/CountySelectionInput'
import { MunicipalitySelectionInput } from '@local/src/Components/Inputs/Selection/MunicipalitySelectionInput'
import { IsMainContactSelectionInput } from '@local/src/Components/Inputs/Selection/IsMainContactSelectionInput'
import { TrrIndustrySelectionInput } from '@local/src/Components/Inputs/Selection/TrrIndustrySelectionInput'

import { IContactSearchFilterData } from '../../Hooks/Filters/useContactSearchFilters'

import { ContactFreeTextSearchField } from './ContactFreeTextSearchField'

interface Props {
    filterData: IContactSearchFilterData,
    isSearching: boolean,
    onSearch: () => void
}

export const ContactSearchFilter = ({ filterData, isSearching, onSearch }: Props) => {
    const { value: showFilters, setValue: setShowFilters } = filterData.filterVisibilityData

    const resetFilters = () => {
        filterData.resetFilters()
    }

    return (
        <Stack marginBottom={2}>
            <Box display='grid' gridTemplateColumns='repeat(4, 1fr)' gap={2} alignItems='center'>
                <ContactFreeTextSearchField {...filterData.freeTextSearchData} onSearch={onSearch} disabled={isSearching} />
                <Button
                    variant='text'
                    onClick={() => setShowFilters(!showFilters)}
                    endIcon={showFilters ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    sx={{ gridColumn: '3' }}
                >
                    Förfina din sökning
                </Button>
            </Box>
            <Collapse in={showFilters}>
                <Box display='grid' gridTemplateColumns='repeat(5, 1fr)' gap={2} marginBottom={2} marginTop={2}>
                    <ContactCategorySelectionInput {...filterData.contactCategoryData} disabled={isSearching} />
                    <ContactRoleSelectionInput {...filterData.contactRoleData} disabled={isSearching} />
                    <UnionSelectionInput {...filterData.unionData} disabled={isSearching} />
                    <ContactStatusSelectionInput {...filterData.contactStatusData} disabled={isSearching} />
                    <IsMainContactSelectionInput {...filterData.isMainContactData} disabled={isSearching} />
                </Box>

                <Box display='grid' gridTemplateColumns='repeat(6, 1fr)' gap={2} marginBottom={2} marginTop={2}>
                    <ContactResponsibleSelectionInput {...filterData.contactResponsibleData} disabled={isSearching} />
                    <EligibilitySelectionInput {...filterData.eligibilityData} disabled={isSearching} />
                    <WorkplaceTypeSelectionInput {...filterData.workplaceTypeData} disabled={isSearching} />
                    <CountySelectionInput {...filterData.countyData} disabled={isSearching} />
                    <MunicipalitySelectionInput {...filterData.municipalityData} disabled={isSearching} />
                    <TrrIndustrySelectionInput {...filterData.trrIndustryData} disabled={isSearching} />

                    <Stack direction='row' sx={{ gridColumn: '6', justifySelf: 'end' }} gap={1}>
                        <Button variant='contained' startIcon={<Search />} onClick={onSearch} disabled={isSearching}>Sök</Button>
                        <Button variant='outlined' startIcon={<Clear />} onClick={resetFilters} disabled={isSearching}>Rensa</Button>
                    </Stack>
                </Box>
                <Divider />
            </Collapse>
        </Stack >
    )
}