import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert, Stack, Typography } from '@mui/material'
import { DataGrid, GridColumnVisibilityModel, GridDensity, GridPaginationModel, GridRowParams, GridSortModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector } from '@mui/x-data-grid'
import { MAX_NUMBER_OF_WORKPLACE_SEARCH_RESULTS } from '@local/src/Utils/network/endpoints/WorkplacesSearch/workplacesSearchApi'
import { DEFAULT_PAGE_SIZES, IPagingModel } from '@local/src/Models/Misc/Paging.model'
import { ISortingModel } from '@local/src/Models/Misc/Sorting.model'
import { useLocalStorage } from '@local/src/hooks/useLocalStorage'
import { IWorkplace } from '@local/src/Models/Domain/Workplace.model'
import { companyOverviewUrl } from '@local/src/basename'

import { CreateCompanyButton } from '../CreateCompanyModal/CreateCompanyModal'
import { mapFromSortingModel } from '../../Common/Mapping/mapFromSortingModel'
import { mapFromPagingModel } from '../../Common/Mapping/mapFromPagingModel'

import { workplaceSearchResultColumns } from './workplaceSearchResultColumns'
import { IWorkplaceSearchQueryData } from './Hooks/Query/useWorkplaceSearchQuery'

export interface IWorkplaceSearchResultProps {
    queryData: IWorkplaceSearchQueryData
    paging: IPagingModel
    sorting: ISortingModel
    onPagingChanged: (paging: IPagingModel) => void
    onSortingChanged: (sorting: ISortingModel) => void
}

export const WorkplaceSearchResult = ({ queryData, paging, onPagingChanged, sorting, onSortingChanged }: IWorkplaceSearchResultProps) => {
    const { result, isSearching, didSearchFail } = queryData

    const [visibleColumns, setVisibleColumns] = useLocalStorage('workplace-search-visible-columns', {} as GridColumnVisibilityModel)
    const [density, setDensity] = useLocalStorage('workplace-search-density', 'standard' as GridDensity)

    const history = useHistory()

    const handleOnPagingChanged = (p: GridPaginationModel) => {
        const newPaging = {
            skip: p.page * p.pageSize,
            take: p.pageSize
        } as IPagingModel
        onPagingChanged(newPaging)
    }

    const handleOnSortingChanged = (s: GridSortModel) => {
        const newSorting = {
            property: s.at(0).field,
            order: s.at(0).sort === 'asc' ? 'ascending' : 'descending'
        } as ISortingModel
        onSortingChanged(newSorting)
    }

    const handleOnRowClick = (workplaceId: string) => {
        if (workplaceId?.length > 0)
            history.push(companyOverviewUrl(workplaceId))
    }


    const maxResultsReached = result?.totalCount >= MAX_NUMBER_OF_WORKPLACE_SEARCH_RESULTS

    return (
        <Stack direction='column' spacing={2}>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant='h4'>Sökresultat {result?.totalCount >= 0 ? `(${result.totalCount.toLocaleString('sv-SE')})` : ''}</Typography>
                <CreateCompanyButton />
            </Stack>
            {maxResultsReached && <Alert severity='warning'>Maximalt antal sökresultat uppnått. Förfina sökningen om du vill vara säker på att få med alla relevanta resultat.</Alert>}
            {didSearchFail && <Alert severity='error'>Kunde inte hämta företag - vänligen försök igen</Alert>}
            {useMemo(() => <DataGrid
                loading={isSearching}
                columns={workplaceSearchResultColumns()}
                rows={result?.workplaces ?? []}
                rowCount={result?.totalCount ?? 0}
                getRowId={w => w.workplaceId}
                onRowClick={(params: GridRowParams<IWorkplace>) => handleOnRowClick(params.row?.workplaceId)}
                autoHeight
                disableVirtualization
                disableColumnMenu
                disableRowSelectionOnClick
                paginationModel={mapFromPagingModel(paging)}
                paginationMode='server'
                onPaginationModelChange={handleOnPagingChanged}
                pageSizeOptions={DEFAULT_PAGE_SIZES}
                sortModel={mapFromSortingModel(sorting)}
                sortingMode='server'
                onSortModelChange={handleOnSortingChanged}
                sortingOrder={['asc', 'desc']}
                columnVisibilityModel={visibleColumns}
                onColumnVisibilityModelChange={setVisibleColumns}
                density={density}
                onStateChange={(state) => {
                    if (state.density.value !== density)
                        setDensity(state.density.value as GridDensity)
                }}
                slots={{
                    noResultsOverlay: () =>
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            <Typography variant='body2'>Inga resultat</Typography>
                        </Stack>,
                    noRowsOverlay: () =>
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            <Typography variant='body2'>Inga resultat</Typography>
                        </Stack>,
                    toolbar: () =>
                        <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                            <GridToolbarColumnsButton />
                            <GridToolbarDensitySelector />
                        </GridToolbarContainer>,
                }}
                sx={{
                    "& div[role=cell]:focus": {
                        outline: "none !important"
                    },
                    "& div[role=cell]:focus-within": {
                        outline: "none !important"
                    }
                }}
                componentsProps={{ panel: { placement: 'bottom-end' } }} // Workaround since the new slot props are broken and reset everything when changing just panel.placement
            // eslint-disable-next-line react-hooks/exhaustive-deps
            />, [density, isSearching, paging, result?.totalCount, result?.workplaces, sorting, visibleColumns])}
        </Stack>
    )
}


