import React from 'react'
import { activityDetailsUrl } from '@local/src/basename'
import { Link } from '@mui/material'

import { IActivity } from '../CompanyGroup'

interface IActivityLink {
  activity: IActivity
}

export const ActivityLink = ({ activity: { arbetsstalleId, id, aktivitetTypNamn } }: IActivityLink) => (
  <Link href={activityDetailsUrl(arbetsstalleId, id)}>
    {aktivitetTypNamn}
  </Link>
)
