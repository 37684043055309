import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps
} from '@mui/material'
import React from 'react'

type DropdownProps = {
  label: string
  value: unknown
  options: unknown[]
  getOptionValue: (option: unknown) => string
  getOptionLabel: (option: unknown) => string
  onChange: (event: SelectChangeEvent) => void
  error?: boolean
  errorText?: string
  fullWidth?: boolean
  disabled?: boolean
} & SelectProps

const Dropdown = ({
  label,
  value,
  options,
  getOptionValue,
  getOptionLabel,
  onChange,
  onBlur,
  error = false,
  errorText,
  fullWidth = true,
  renderValue,
  sx,
  disabled,
}: DropdownProps): JSX.Element => {
  return (
    <FormControl fullWidth={fullWidth} sx={sx}>
      <InputLabel id={`${label}-label`} error={error}>{label}</InputLabel>
      <Select
        labelId={`${label}-label`}
        error={error}
        aria-label={label}
        defaultValue=''
        value={value ?? ''}
        label={label}
        onBlur={onBlur}
        onChange={onChange}
        MenuProps={{ PaperProps: { sx: { maxHeight: 355 } } }}
        renderValue={renderValue}
        disabled={disabled}
      >
        {options.map((option, index: number) => (
          <MenuItem key={index} value={getOptionValue(option)}>{getOptionLabel(option)}</MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText sx={{ color: 'error.main' }}>{errorText}</FormHelperText>
      )}
    </FormControl>
  )
}

export default Dropdown