import { useSelector } from 'react-redux'

import { RootState } from './configureStore'

/**
 * Use a selector to fetch data from our typed root store.
 * @param selector The selector function, sent to useSelector internally.
 */
// eslint-disable-next-line no-unused-vars
export const useStateSelector = <TResult>(selector: (state: RootState) => TResult) =>
  useSelector<RootState, TResult>(selector)
