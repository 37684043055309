import { IContactSearchResult } from '@local/src/Models/ContactSearch/ContactSearchResult.model'

import { organisationsregistretApi } from '../../organisationsregistretApi'
import { ISendEmailRecipientModel } from '../contactsApi'

import { IContactsSearchApiModel } from './IContactsSearchApiModel'
import { IContactsSearchResponseApiModel, mapToContact } from './IContactsSearchResponseApiModel'

export const MAX_NUMBER_OF_WORKPLACE_SEARCH_RESULTS = 100000

export const contactsSearchApi = organisationsregistretApi.injectEndpoints({
  endpoints: (builder) => ({
    searchContacts: builder.query<IContactSearchResult, IContactsSearchApiModel>({
      query: (apiModel) => ({
        url: `contactsV2/search`,
        method: 'POST',
        body: apiModel,
      }),
      transformResponse: (data: { results: IContactsSearchResponseApiModel[]; totalCount: number }) => ({
        contacts: data.results.map(mapToContact),
        totalCount: Math.min(data.totalCount, MAX_NUMBER_OF_WORKPLACE_SEARCH_RESULTS),
      }),
      providesTags: ['SearchedContacts'],
    }),
    getEmailsForContactSelection: builder.query<ISendEmailRecipientModel[], IContactsSearchApiModel>({
      query: (apiModel) => ({
        url: `contactsV2/search`,
        method: 'POST',
        body: apiModel,
      }),
      transformResponse: (data: { results: IContactsSearchResponseApiModel[] }) =>
        data.results.map((c) => ({
          id: c.id,
          emailAddress: c.epost,
        })),
    }),
  }),
})

export const { useLazySearchContactsQuery, useLazyGetEmailsForContactSelectionQuery } = contactsSearchApi
