import { format } from 'date-fns'
import { ISelectOption } from '@local/src/App.types'

import { HandleProjectFormValues, ICreateProject, IProject, IProjectStatusSelectOption, IProjektForetag } from '../Projects.types'

export const getInitialValuesForEdit = (project: IProject): HandleProjectFormValues => {
  return {
    beskrivning: project.beskrivning,
    foretag: project.foretag.map((foretag: IProjektForetag) => ({
      value: foretag.foretagId,
      label: `${foretag.foretagNamn}${foretag.address ? `, ${foretag.address}` : ''}`,
    })),
    klientkopplingSlut: format(new Date(project.klientkopplingPeriod.end), 'yyyy-MM-dd'),
    klientkopplingStart: format(new Date(project.klientkopplingPeriod.start), 'yyyy-MM-dd'),
    kontaktperson:
      !project.kontaktperson.contactId || !project.kontaktperson.fullName
        ? null
        : {
            value: project.kontaktperson.contactId,
            label: project.kontaktperson.fullName,
          },
    projektSlut: format(new Date(project.projektPeriod.end), 'yyyy-MM-dd'),
    projektStart: format(new Date(project.projektPeriod.start), 'yyyy-MM-dd'),
    projektagare:
      !project.projektagare.employeeId || !project.projektagare.employeeName
        ? null
        : {
            value: project.projektagare.employeeId,
            label: project.projektagare.employeeName,
          },
    projektnamn: project.projektnamn,
    projektstatus: {
      value: project.projektstatus.projektstatusId,
      label: project.projektstatus.projektstatusText,
    },
  }
}

export const getInitialValuesForCreate = (
  user: ISelectOption,
  projectStatuses: IProjectStatusSelectOption[]): HandleProjectFormValues => {

  const defaultStartDate = new Date()
  const defaultEndDate = new Date()
  const defaultStatus = projectStatuses.find(value => value.label === 'Aktuellt')
  defaultEndDate.setMonth(defaultEndDate.getMonth() + 12)
  return {
    beskrivning: '',
    foretag: [],
    klientkopplingSlut: format(defaultEndDate, 'yyyy-MM-dd'),
    klientkopplingStart: format(defaultStartDate, 'yyyy-MM-dd'),
    kontaktperson: null,
    projektSlut: format(defaultEndDate, 'yyyy-MM-dd'),
    projektStart: format(defaultStartDate, 'yyyy-MM-dd'),
    projektagare: user,
    projektnamn: '',
    projektstatus: defaultStatus,
    uppdrag: [],
  }
}

export const convertCreateFormValues = (values: HandleProjectFormValues, companyGuid: string): ICreateProject => {
  return {
    beskrivning: values.beskrivning,
    foretagId: values.foretag.map(workplace => workplace.value),
    huvudForetag: companyGuid,
    klientkopplingPeriod: {
      start: values.klientkopplingStart,
      end: values.klientkopplingSlut,
    },
    kontaktpersonId: values.kontaktperson ? values.kontaktperson.value : null,
    projektagareId: values.projektagare.value,
    projektnamn: values.projektnamn,
    projektperiod: {
      start: values.projektStart,
      end: values.projektSlut,
    },
    projektstatus: values.projektstatus.value,
    uppdragId: values.uppdrag.map(selectOptions => selectOptions.value),
  }
}

export const convertEditFormValues = (values: HandleProjectFormValues, project: IProject, companyGuid: string): IProject => {
  return {
    foretag: values.foretag.map((workplace: ISelectOption) => ({
      foretagId: workplace.value,
      foretagNamn: workplace.label,
    })),
    huvudForetag: {
      foretagId: companyGuid,
      foretagNamn: null,
    },
    klientkopplingPeriod: {
      end: values.klientkopplingSlut,
      start: values.klientkopplingStart,
    },
    kontaktperson: values.kontaktperson
      ? {
          contactId: values.kontaktperson.value,
          fullName: values.kontaktperson.label,
        }
      : null,
    projektId: project.projektId,
    projektPeriod: {
      end: values.projektSlut,
      start: values.projektStart,
    },
    projektagare: {
      employeeId: values.projektagare.value,
      employeeName: null,
    },
    projektnamn: values.projektnamn,
    projektnummer: project.projektnummer,
    projektstatus: {
      projektstatusId: values.projektstatus.value,
      projektstatusText: null,
    },
    beskrivning: values.beskrivning,
    skapadAv: project.skapadAv,
    canEdit: project.canEdit,
  }
}