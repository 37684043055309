import { useLocalStorage } from '@local/src/hooks/useLocalStorage'
import { ISortingModel } from '@local/src/Models/Misc/Sorting.model'

export interface IContactSearchSortingData {
  sorting: ISortingModel
  setSorting: (value: ISortingModel) => void
}

export const DEFAULT_SORTING = { property: 'Namn', order: 'ascending' } as ISortingModel

export const useContactSearchSorting = (): IContactSearchSortingData => {
  const [sorting, setSorting] = useLocalStorage('contact-search-sorting', DEFAULT_SORTING)

  return {
    sorting,
    setSorting,
  }
}
