import { ISelectionData } from '@local/src/Components/Inputs/Selection/Common/ISelectionData'
import { IWorkplaceType } from '@local/src/Models/Domain/WorkplaceType.model'
import { IUrlSyncedData } from '@local/src/Utils/helpers/Url/IUrlSyncedData'
import { useUrlSynchedEntityOptionsSelection } from '@local/src/Utils/helpers/Url/useUrlSynchedEntityOptionsSelection'
import { useGetWorkplaceTypesQuery } from '@local/src/Utils/network/endpoints/workplacesApi'

export interface IWorkplaceTypeFilterData extends IUrlSyncedData, ISelectionData<IWorkplaceType> {}

export const useWorkplaceTypeFilter = (): IWorkplaceTypeFilterData =>
  useUrlSynchedEntityOptionsSelection<IWorkplaceType>('workplaceTypes', useGetWorkplaceTypesQuery)
