import React, { useEffect, useState } from 'react'
import { FetchingState } from '@local/src/App.types'

import { ICreateEmailRecipients } from './CreateEmail.types'
import CreateEmailButton from './CreateEmailButton/CreateEmailButton'
import CreateEmailModal from './CreateEmailModal/CreateEmailModal'
import CreateEmailModalConfirmation from './CreateEmailModalConfirmation/CreateEmailModalConfirmation'

export interface ICreateEmailButton {
  recipients: ICreateEmailRecipients[]
  createEmail: (subject: string, recipients: ICreateEmailRecipients[]) => void
  createEmailState: FetchingState
}

const CreateEmail = ({
  recipients,
  createEmail,
  createEmailState
}: ICreateEmailButton): JSX.Element => {

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  useEffect(() => {
    if (createEmailState === FetchingState.SUCCESS) {
      setIsConfirmationModalOpen(true)
    }
  }, [createEmailState])

  const closeModal = () => {
    setIsCreateModalOpen(false)
    setIsConfirmationModalOpen(false)
  }

  const handleCreateEmail = (subject: string) => {
    setIsCreateModalOpen(false)
    setIsConfirmationModalOpen(true)
    createEmail(subject, recipients)
  }

  const numberOfEmailRecipients = recipients?.length || 0
  const numberOfRecipientsWithoutEmail = recipients?.filter(x => !x.emailAddress).length

  return (
    <>
      <CreateEmailButton onClick={() => setIsCreateModalOpen(true)} />
      <CreateEmailModal
        isOpen={isCreateModalOpen}
        closeModal={closeModal}
        createEmail={handleCreateEmail}
        createEmailState={createEmailState}
        numberOfEmailRecipients={numberOfEmailRecipients}
        numberOfRecipientsWithoutEmail={numberOfRecipientsWithoutEmail}
      />
      <CreateEmailModalConfirmation
        isOpen={isConfirmationModalOpen}
        closeModal={closeModal}
        createEmailState={createEmailState}
      />
    </>
  )
}

export default CreateEmail
