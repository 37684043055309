import { IUrlSyncedData } from '@local/src/Utils/helpers/Url/IUrlSyncedData'
import { useUrlSync } from '@local/src/Utils/helpers/Url/useUrlSync'
import { useState } from 'react'

export interface IIsLocallyImportantFieldData extends IUrlSyncedData {
  value: boolean
  setValue: (value: boolean) => void
}

export const useIsLocallyImportantField = (): IIsLocallyImportantFieldData => {
  const [value, setValue] = useState<boolean>(false)
  const { wasLoadedFromUrl, updateValue } = useUrlSync<boolean>(
    'isLocallyImportant',
    setValue,
    (b) => (b ? 'true' : ''),
    (s) => (s === 'true' ? true : false),
    []
  )

  return {
    value,
    setValue: updateValue,
    wasLoadedFromUrl,
  }
}
