import { useLocalStorage } from '@local/src/hooks/useLocalStorage'
import { DEFAULT_PAGE_SIZE, IPagingModel } from '@local/src/Models/Misc/Paging.model'

export interface IWorkplaceSearchPagingData {
  paging: IPagingModel
  setPaging: (value: IPagingModel) => void
}

export const DEFAULT_PAGING = { skip: 0, take: DEFAULT_PAGE_SIZE } as IPagingModel

export const useWorkplaceSearchPaging = (): IWorkplaceSearchPagingData => {
  const [paging, setPaging] = useLocalStorage('workplace-search-paging', DEFAULT_PAGING)

  return {
    paging,
    setPaging,
  }
}
