import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IRange } from '@local/src/Models/Misc/Range.model'
import { IEntity } from '@local/src/Models/Domain/Entity.model'

import useDebounce from '../useDebounce'

export const URL_VALUE_SEPARATOR = ';'

export const useUrlSync = <T>(
  name: string,
  setValue: (value: T) => void,
  mapToUrl: (value: T) => string,
  mapFromUrl: (urlValue: string) => T,
  loadingDependencies?: unknown[]
) => {
  const history = useHistory()
  const [wasLoadedFromUrl, setWasLoadedFromUrl] = useState<boolean>(false)
  const [localValue, setLocalValue] = useState<T>(null)
  const debouncedLocalValue = useDebounce(localValue, 800)

  const getParams = () => new URLSearchParams(location?.search ?? '')
  const getUrlValue = () => getParams().get(name)

  const pushParams = (params: URLSearchParams) => history.replace({ search: params.toString() })

  const onUpdateValue = useCallback(
    (newValue: T) => {
      setValue(newValue)
      setLocalValue(newValue)
    },
    [setValue]
  )

  useEffect(() => {
    const urlValue = getUrlValue()
    if (urlValue !== null) {
      setValue(mapFromUrl(urlValue))
      setWasLoadedFromUrl(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...loadingDependencies])

  useEffect(() => {
    if (debouncedLocalValue === null) return

    const params = getParams()
    const mappedValue = mapToUrl(debouncedLocalValue)

    if (mappedValue === '') params.delete(name)
    else params.set(name, mappedValue)

    pushParams(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedLocalValue])

  return {
    wasLoadedFromUrl,
    updateValue: onUpdateValue,
  }
}

export const mapIRangeToUrl = (range: IRange, defaultValue: IRange) => {
  if (range.from === defaultValue.from || range.to === defaultValue.to) return ''

  return `${range.from}${URL_VALUE_SEPARATOR}${range.to}`
}

export const mapIRangeFromUrl = (urlParam: string, defaultValue: IRange) => {
  const parts = urlParam.split(URL_VALUE_SEPARATOR)
  if (parts.length !== 2) return defaultValue
  let from = Number(parts[0])
  let to = Number(parts[1])
  if (isNaN(from)) from = defaultValue.from
  if (isNaN(to)) to = defaultValue.to

  return { from, to }
}

export const mapIEntityArrayToUrl = (value: IEntity[]) => value.map((e) => e.id).join(URL_VALUE_SEPARATOR)
export const mapIEntityArrayFromUrl = <T extends IEntity>(urlParam: string, options: T[]) =>
  options.filter((o) => urlParam.split(URL_VALUE_SEPARATOR).includes(o.id))
