import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { IProjectList } from '@local/Scenes/Company/CompanyTabs/Projects/Projects.types'
import { useCallback, useEffect } from 'react'
import { useStateSelector } from '@local/src/Store/useStateSelector'
import { orgBasepath } from '@local/src/basename'
import { GridEventListener, GridRowParams } from '@mui/x-data-grid'
import DataGridWrapper from '@local/src/Components/DataGridWrapper/DataGridWrapper'
import Loading from '@local/src/Components/Loading/Loading'
import { Alert, Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useAppDispatch } from '@local/Store/configureStore'

import { displayProjects } from '../../Home.actions'
import { projectsSearchFailureSelector, projectsSelector } from '../../Home.selectors'

import { projectColumns } from './ProjectColumns'

const Projects = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const loaded = useStateSelector<boolean>(state => state.home.projectsLoaded)
  const searchResults = useStateSelector(projectsSelector)
  const projectsSearchFailure = useStateSelector(projectsSearchFailureSelector)
  const [inactiveProjectsIncluded, setInactiveProjectsIncluded] = React.useState(false)

  useEffect(() => {
    dispatch(displayProjects())
  }, [dispatch])

  const navigateToProject: GridEventListener<'rowClick'> = (params: GridRowParams<IProjectList>) => {
    history.push(`${orgBasepath}/foretag/detaljer/${params.row.foretag.foretagId}/projekt/${params.row.projektId}`)
  }

  const handleOnChange = useCallback(() => {
    setInactiveProjectsIncluded(!inactiveProjectsIncluded)
  }, [inactiveProjectsIncluded])

  const projects = inactiveProjectsIncluded
    ? searchResults
    : searchResults?.filter((r: IProjectList) => r.projektstatus.projektstatusId === '01')

  const numberOfSearchHits = projects?.length ? `${projects.length}` : ''

  if (!loaded) {
    return <Loading />
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={1}>
        <FormControlLabel
          control={<Checkbox checked={inactiveProjectsIncluded} onChange={handleOnChange} />}
          label="Visa även inaktiva projekt"
        />
        <Typography variant="h4" paddingBottom={2} paddingTop={1}>{`Projekt (${numberOfSearchHits})`}</Typography>
      </Box>

      {projectsSearchFailure && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          Fel vid hämtning av projekt.
        </Alert>
      )}

      <DataGridWrapper
        rows={projects}
        columns={projectColumns}
        getRowId={(row: IProjectList) => row.projektId}
        labelRowsPerPage={'Projekt per sida'}
        onRowClick={navigateToProject}
      />
    </>
  )
}

export default Projects
