import React from 'react'
import { Alert } from '@mui/material'

export interface NoEmailWarningProps {
  quantity: number
}

const NoEmailWarning = ({
  quantity,
}: NoEmailWarningProps): JSX.Element => {

  const contacts = quantity === 1 ? 'kontakt' : 'kontakter'

  return (
    <Alert severity='warning'>
      {`${quantity} ${contacts} saknar mejladress och kommer inte med.`}
    </Alert>
  )
}

export default NoEmailWarning
