import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ITabItem } from '@local/Scenes/Home/Tabs/TabsContainer/TabsContainer'

const useTabs = (items: ITabItem[]) => {
  const history = useHistory()
  const match = useRouteMatch()

  const findSelectedIndex = (items: ITabItem[]) => {
    return items.findIndex(item => item.urls.some(url => (item.exact ? match.path === url : match.path.includes(url))))
  }

  const selectedIndex = findSelectedIndex(items)
  const [currentIndex, setCurrentIndex] = useState(selectedIndex !== -1 ? selectedIndex : 0)

  useEffect(() => {
    setCurrentIndex(selectedIndex !== -1 ? selectedIndex : 0)
  }, [selectedIndex])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentIndex(newValue)

    const selectedTabUrl = items[newValue].route
    history.push(selectedTabUrl)
  }

  const getA11yProps = (index: number) => {
    return {
      id: `tabbutton-${index}`,
      'aria-controls': `tabpanel-${index}`,
    }
  }

  return {
    currentIndex,
    handleChange,
    getA11yProps,
  }
}

export default useTabs
