import { RootState } from '@local/src/Store/configureStore'
import { createSelector } from '@reduxjs/toolkit'


export const projectsSelector = createSelector(
  (state: RootState) => state.home.projectResult,
  projects => projects.projectData
)

export const projectsSearchFailureSelector = createSelector(
  (state: RootState) => state.home,
  home => home.projectsSearchFailure
)

export const companyCreationFailedSelector = createSelector(
  (state: RootState) => state.home,
  home => home.companyCreationFailed
)

export const companyCreationInitiatedSelector = createSelector(
  (state: RootState) => state.home,
  home => home.companyCreationInitiated
)

export const companyCreationSuccessSelector = createSelector(
  (state: RootState) => state.home,
  home => home.companyCreationSuccess
)

export const companyCreationErrorMessageSelector = createSelector(
  (state: RootState) => state.home,
  home => home.companyCreationErrorMessage
)

export const companiesLoadedSelector = createSelector(
  (state: RootState) => state.home,
  home => home.companiesLoaded
)

export const allCompaniesDataSelector = createSelector(
  (state: RootState) => state.home,
  home => home.allCompaniesData
)

