import Loading from '@local/src/Components/Loading/Loading'
import { Send } from '@mui/icons-material'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLazyGetEmailsForContactSelectionQuery } from '@local/src/Utils/network/endpoints/ContactsSearch/contactsSearchApi'
import { IContactsSearchApiModel } from '@local/src/Utils/network/endpoints/ContactsSearch/IContactsSearchApiModel'
import { formatSwedishNumber, pluralizeWord } from '@local/src/Utils/helpers/formatting'
import { useSendEmailMutation } from '@local/src/Utils/network/endpoints/contactsApi'
import { isEmail } from '@local/src/Utils/helpers'

const MAX_NUMBER_OF_RECIPIENTS = 500

interface Props {
    apiModel?: IContactsSearchApiModel
    numberOfContactsSelected: number
    isContactSelected: (id: string) => boolean
}
export const SendContactsEmail = ({ apiModel, numberOfContactsSelected, isContactSelected }: Props) => {
    const [getEmails, { data: emailData, isFetching: isLoadingEmailData, isError: failedToLoadEmailData }] = useLazyGetEmailsForContactSelectionQuery()
    const [sendEmail, { isLoading: isSendingEmail, isError: failedToSendEmail, isSuccess: successfullySentEmail }] = useSendEmailMutation()
    const [isShowing, setIsShowing] = useState<boolean>(false)
    const [subjectLine, setSubjectLine] = useState<string>('')
    const [isSubjectLineMissing, setIsSubjectLineMissing] = useState<boolean>(false)

    const areTooManyRecipientsSelected = numberOfContactsSelected > MAX_NUMBER_OF_RECIPIENTS
    const recipients = emailData?.filter(r => isContactSelected(r.id)) ?? []
    const recipientsWithoutEmail = recipients.filter(r => !isEmail(r.emailAddress))
    const recipientsWithEmail = recipients.filter(r => isEmail(r.emailAddress))

    useEffect(() => setIsSubjectLineMissing(false), [subjectLine])
    useEffect(() => {
        if (successfullySentEmail)
            handleOnClose()
    }, [successfullySentEmail])

    const handleOnOpen = () => {
        setIsShowing(true)

        if (areTooManyRecipientsSelected || apiModel === undefined)
            return

        void getEmails({ ...apiModel, skip: 0, count: 500 })
    }
    const handleOnSend = () => {
        if (subjectLine.trim().length === 0) {
            setIsSubjectLineMissing(true)
            return
        }

        void sendEmail({ subject: subjectLine, recipients: recipientsWithEmail })
    }
    const handleOnClose = () => setIsShowing(false)

    return (
        <>
            <Button sx={{ marginLeft: '48px' }} variant='text' size='small' endIcon={<Send />} disabled={numberOfContactsSelected === 0} onClick={handleOnOpen}>Skicka E-post</Button>
            <Dialog open={isShowing} onClose={handleOnClose}>
                <DialogTitle>
                    {`Skapa e-postmeddelande till ${formatSwedishNumber(numberOfContactsSelected)} ${pluralizeWord('kontaktperson', numberOfContactsSelected, 'er')}`}
                </DialogTitle>
                <DialogContent>
                    <EmailWarnings areTooManyRecipientsSelected={areTooManyRecipientsSelected} numberOfRecipientsWithoutEmail={recipientsWithoutEmail.length} failedToLoadEmailData={failedToLoadEmailData} failedToSendEmail={failedToSendEmail} />
                    <TextField
                        label='Ämnesrad'
                        fullWidth sx={{ marginY: '1em' }}
                        disabled={areTooManyRecipientsSelected}
                        value={subjectLine}
                        onChange={(e) => setSubjectLine(e.currentTarget.value)}
                        helperText={isSubjectLineMissing ? 'Vänligen ange en ämnesrad' : undefined}
                        error={isSubjectLineMissing}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter')
                                handleOnSend()
                        }} />
                    <Typography>
                        {`När du trycker på OK skapas ett e-postmeddelande i mappen 'Utkast' i Outlook.`}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    {(isLoadingEmailData || isSendingEmail) && <Loading />}
                    <Button variant='text' onClick={handleOnSend} disabled={isLoadingEmailData || areTooManyRecipientsSelected}>OK</Button>
                    <Button variant='text' onClick={handleOnClose}>Avbryt</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

interface EmailWarningsProps {
    areTooManyRecipientsSelected: boolean
    numberOfRecipientsWithoutEmail: number
    failedToLoadEmailData: boolean
    failedToSendEmail: boolean
}
const EmailWarnings = ({ areTooManyRecipientsSelected, numberOfRecipientsWithoutEmail, failedToLoadEmailData, failedToSendEmail }: EmailWarningsProps) => {
    return (
        <>
            {areTooManyRecipientsSelected &&
                <Alert severity='warning'>{`Du har valt fler än ${formatSwedishNumber(MAX_NUMBER_OF_RECIPIENTS)} mottagare. Vänligen ändra ditt urval så att det inte överskrider denna gräns.`}</Alert>}
            {numberOfRecipientsWithoutEmail > 0 &&
                <Alert severity='warning'>{numberOfRecipientsWithoutEmail} {pluralizeWord('kontaktperson', numberOfRecipientsWithoutEmail, 'er')} saknar en giltig e-postadress och följer därav inte med i utkastet.</Alert>}
            {failedToLoadEmailData &&
                <Alert severity='error'>Kunde inte hämta mottagarinformation - vänligen försök igen</Alert>}
            {failedToSendEmail &&
                <Alert severity='error'>Kunde inte skapa e-postutkastet - vänligen försök igen</Alert>}
        </>
    )
}