import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

interface IConfirmationModal {
  title: string
  message: string
  active: boolean
  close: () => void
  submit: () => void
}

const ConfirmationModal = ({ active, close, submit, message, title }: IConfirmationModal): JSX.Element => {
  return (
    <Dialog open={active} onClose={close}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={submit}>OK</Button>
        <Button variant='text' onClick={close}>Avbryt</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationModal
