import { date, object, string } from 'yup';

export const projectValidation = object({
  beskrivning: string().required('Beskrivning måste anges'),
  projektnamn: string()
    .required('Projektnamn måste anges')
    .max(100, 'Projektnamn är begränsat till 100 tecken'),
  klientkopplingStart: date()
    .required('Kopplingsperiod start måste anges')
    .when('projektStart', (projektStart, schema) =>
      projektStart && schema.min(projektStart, 'Kopplingsperiod start måste vara efter projektstart')
    )
    .when('projektSlut', (projektSlut, schema) =>
      projektSlut && schema.max(projektSlut, 'Kopplingsperiod start måste vara innan projektslut')
    ),
  klientkopplingSlut: date()
    .required('Kopplingsperiod slut måste anges')
    .when('projektStart', (projektStart, schema) =>
      projektStart && schema.min(projektStart, 'Kopplingsperiod slut måste vara efter projektstart')
    )
    .when('projektSlut', (projektSlut, schema) =>
      projektSlut && schema.max(projektSlut, 'Kopplingsperiod slut måste vara innan projektslut')
    )
    .when('klientkopplingStart', (klientkopplingStart, schema) =>
      klientkopplingStart && schema.min(klientkopplingStart, 'Kopplingsperiod slut måste vara efter kopplingsperiod start')
    ),

  projektagare: object().nullable().required('Projektägare måste anges'),

  projektStart: date().required('Projektstart måste anges'),
  projektSlut: date()
    .required('Projektslut måste anges')
    .when('projektStart', (projektStart, schema) =>
      projektStart && schema.min(projektStart, 'Projektslut måste vara efter projektstart')
    ),

  projektstatus: object({
    value: string().required('Status måste anges'),
  }).nullable(),
})
