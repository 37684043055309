import { FetchingState } from '@local/src/App.types'
import Loading from '@local/src/Components/Loading/Loading'
import { useAppDispatch } from '@local/src/Store/configureStore'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'

import { resetEmail } from '../CreateEmail.reducer'

export interface ICreateEmailButton {
  isOpen: boolean
  closeModal: () => void
  createEmailState: FetchingState
}

const CreateEmailModal = ({
  isOpen,
  closeModal,
  createEmailState
}: ICreateEmailButton): JSX.Element => {

  const dispatch = useAppDispatch()
  const isLoading = createEmailState === FetchingState.LOADING
  const isSuccess = createEmailState === FetchingState.SUCCESS
  const isError = createEmailState === FetchingState.ERROR

  const handleClose = () => {
    dispatch(resetEmail())
    closeModal()
  }

  const getModalTitle = () => {
    if (isSuccess) {
      return 'Utkastet har skapats'
    }

    return isError ? 'Utkastet kunde inte skapas' : ''
  }

  const heading = !isLoading && getModalTitle()

  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <DialogTitle>{heading}</DialogTitle>
      <DialogContent>
        { isLoading ? (
          <Loading />
        ) : (
          <Typography variant='body1'>
            {isSuccess && 'Nu kan du skriva och skicka ditt mejl i Outlook.'}
            {isError && 'Något gick fel när vi försökte skapa ditt mejl. Försök gärna igen.'}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateEmailModal
