import { ISelectionData } from '@local/src/Components/Inputs/Selection/Common/ISelectionData'
import { ITrrIndustry } from '@local/src/Models/Domain/TrrIndustry.model'
import { IUrlSyncedData } from '@local/src/Utils/helpers/Url/IUrlSyncedData'
import { useUrlSynchedEntityOptionsSelection } from '@local/src/Utils/helpers/Url/useUrlSynchedEntityOptionsSelection'
import { useGetTrrIndustriesQuery } from '@local/src/Utils/network/endpoints/workplacesApi'

export interface ITrrIndustryFilterData extends IUrlSyncedData, ISelectionData<ITrrIndustry> {}

export const useTrrIndustryFilter = (): ITrrIndustryFilterData =>
  useUrlSynchedEntityOptionsSelection<ITrrIndustry>('trrIndustries', useGetTrrIndustriesQuery)
