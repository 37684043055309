import { Search } from '@mui/icons-material'
import { IconButton, TextField, useTheme } from '@mui/material'
import React from 'react'

interface Props {
    value: string,
    setValue: (value: string) => void,
    onSearch: () => void
    disabled?: boolean
}

export const ContactFreeTextSearchField = React.memo(({ value, setValue, onSearch, disabled = false }: Props) => {
    const theme = useTheme()

    return <TextField
        label='Sök på valfri text, namn, mobilnummer, e-post, eller ett organisationsnummer'
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        onKeyDown={(e) => {
            if (e.key === 'Enter')
                onSearch()
        }}
        sx={{ gridColumn: '1 / 3' }}
        disabled={disabled}
        InputProps={{
            endAdornment: <IconButton size="small" onClick={onSearch}><Search sx={{ color: `${theme.palette.primary.main} !important` }} /></IconButton>
        }}
    />
})