import { ISelectionData } from '@local/src/Components/Inputs/Selection/Common/ISelectionData'
import { IEntity } from '@local/src/Models/Domain/Entity.model'
import { IQuery } from '@local/src/Utils/network/organisationsregistretApi'
import { useState } from 'react'

export const useEntityOptionsSelection = <T extends IEntity>(query: () => IQuery<T[]>): ISelectionData<T> => {
  const { data, isFetching, isError } = query()
  const options = data ?? []

  const [selection, setSelection] = useState<T[]>([])

  return {
    selection,
    setSelection,
    options,
    isLoading: isFetching,
    isError,
  }
}
