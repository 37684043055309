import { connect } from 'react-redux'
import { RootState } from '@local/Store/configureStore'

import HandleActivity, { IHandleActivityProps } from './HandleActivity'

const mapStateToProps = (state: RootState) => ({
  activity: state.activity.detailedActivity,
  company: state.company,
  contactsLoaded: state.company.contactsLoaded,
  createOrUpdateFailed: state.activity.createOrUpdateActivityFailed,
  errorMessage: state.activity.createOrUpdateActivityErrorMessage,
  saveInProgress: state.activity.createOrUpdateActivityStarted,
} as IHandleActivityProps)

export default connect(mapStateToProps)(HandleActivity)
