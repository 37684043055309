import React from "react"
import { IsMainContact } from "@local/src/Scenes/Home/Tabs/Contacts/Hooks/Filters/useIsMainContactFilter"

import { ISingleSelectionData } from "./Common/ISingleSelectionData"
import { SingleSelectionInput } from "./Common/SingleSelectionInput"

interface Props extends ISingleSelectionData<IsMainContact> {
    disabled?: boolean
}

export const IsMainContactSelectionInput = React.memo((props: Props) => {
    return <SingleSelectionInput
        {...props}
        name="Huvudkontakt"
        getDisplayValue={o => o}
    />
})