import { createSlice } from '@reduxjs/toolkit'
import { FetchingState } from '@local/src/App.types'

import { ICreateEmailState } from './CreateEmail.types'

export const initialState: ICreateEmailState = {
  createEmailState: FetchingState.IDLE,
}

const createEmailSlice = createSlice({
  name: 'createEmail',
  initialState,
  reducers: {
    setCreateEmailInitiate: state => {
      state.createEmailState = FetchingState.LOADING
    },
    setCreateEmailSuccess: state => {
      state.createEmailState = FetchingState.SUCCESS
    },
    setCreateEmailFailure: state => {
      state.createEmailState = FetchingState.ERROR
    },
    resetEmail: state => {
      state.createEmailState = FetchingState.IDLE
    }
  },
})

export const { actions, reducer: createEmailReducer } = createEmailSlice
export const { setCreateEmailInitiate, setCreateEmailSuccess, setCreateEmailFailure, resetEmail } = createEmailSlice.actions
export default createEmailReducer
