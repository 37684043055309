import React from 'react'
import PlaceIcon from '@mui/icons-material/Place'
import { Box, Grid, Typography } from '@mui/material'

import { ICompany } from '../../Company.model'

interface AddressSectionProps {
  company: ICompany
}

const AddressSection = ({
   company,
}: AddressSectionProps): JSX.Element => {

  return (
    <Box>
      <Box display='flex' alignItems='center' gap={1} marginBottom={2}>
        <PlaceIcon />
        <Typography variant="subtitle2">Adresser</Typography>
      </Box>

      <Grid container spacing={2}>

        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle2">Besöksadress</Typography>
          <Typography variant='body1' color='text.secondary'>{company.visitingAddress.street} {company.visitingAddress.town}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle2">Län</Typography>
          <Typography variant='body1' color='text.secondary'>{company.visitingAddress.county.countyName}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle2">Land</Typography>
          <Typography variant='body1' color='text.secondary'>{company.visitingAddress.country.countryName}</Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle2">Postadress</Typography>
          <Typography variant='body1' color='text.secondary'>{company.postalAddress.street} {company.postalAddress.town}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle2">Län</Typography>
          <Typography variant='body1' color='text.secondary'>{company.postalAddress.county.countyName}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle2">Land</Typography>
          <Typography variant='body1' color='text.secondary'>{company.postalAddress.country.countryName}</Typography>
        </Grid>

      </Grid>
    </Box>
  )
}

export default AddressSection
