import React from 'react'
import { companyUrl, contactUrl, orgUrl, projectUrl } from '@local/src/basename'

import { ITabItem, TabsContainer } from './TabsContainer/TabsContainer'

const SearchTabs = (): JSX.Element => {
  const items: ITabItem[] = [
    {
      heading: 'Företag',
      urls: [companyUrl, orgUrl],
      route: companyUrl,
      exact: true,
    },
    {
      heading: 'Kontaktpersoner',
      urls: [contactUrl],
      route: contactUrl,
    },
    {
      heading: 'Projekt',
      urls: [projectUrl],
      route: projectUrl,
    },
  ]

  return <TabsContainer items={items} />
}

export default SearchTabs
