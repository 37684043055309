import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import CreateEmail from '@local/Blocks/CreateEmail'
import { formatPhoneNumber } from '@local/src/Utils/strings'
import { contactDetailsUrl, contactDetailsEditUrl, createContactUrl } from '@local/src/basename'
import { GridEventListener, GridRowId, GridRowParams, MuiEvent } from '@mui/x-data-grid'
import DataGridWrapper from '@local/src/Components/DataGridWrapper/DataGridWrapper'
import { ICreateEmailRecipients } from '@local/src/Blocks/CreateEmail/CreateEmail.types'
import Loading from '@local/src/Components/Loading/Loading'
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import InformationPopover from '@local/src/Components/InformationPopover/InformationPopover'
import { FetchingState } from '@local/src/App.types'

import ConfirmationModal from '../../../../Components/ConfirmationModal/ConfirmationModal'

import { ContactModel } from './Contacts.model'
import { contactColumns } from './Contacts.columns'

export interface IContactsProps {
  fetchContactsData?: (companyGuid: string) => void
  deleteContact?: (companyGuid: string, contactId: string) => void
  createEmail?: (subject: string) => void
  loaded?: boolean
  contacts: ContactModel[]
  createNewContact?: () => void
  displayMyContacts?: () => void
  createEmailState?: FetchingState
  resetContacts?: () => void
}

const formatPhones = (kontaktpersoner: ContactModel[]) => {
  return kontaktpersoner.map((kontaktperson: ContactModel) => {
    const formattedMobile = kontaktperson.mobile.number
      ? formatPhoneNumber(kontaktperson.mobile.number)
      : kontaktperson.mobile.number

    const formattedPhone = kontaktperson.phone.number
      ? formatPhoneNumber(kontaktperson.phone.number)
      : kontaktperson.phone.number

    return {
      ...kontaktperson,
      mobile: {
        ...kontaktperson.mobile,
        number: formattedMobile,
      },
      phone: {
        ...kontaktperson.phone,
        number: formattedPhone,
      },
    }
  })
}

const Contacts = ({
  fetchContactsData,
  deleteContact,
  createEmail,
  loaded,
  contacts,
  createEmailState,
  resetContacts,
}: IContactsProps) => {
  const [isShowingOnlyActiveContacts, setIsShowingOnlyActiveContacts] = useState(true)
  const [isShowingOnlyMainContacts, setIsShowingOnlyMainContacts] = useState(false)
  const [isShowingModal, setIsShowingModal] = useState(false)
  const [contactIdToDelete, setContactIdToDelete] = useState<string>(null)
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([])
  const [clearSelectedRows, setClearSelectedRows] = useState<boolean>(false)
  const { companyGuid } = useParams<{ companyGuid: string }>()

  const history = useHistory()

  useEffect(() => {
    fetchContactsData(companyGuid)
    return () => {
      resetContacts()
    }
  }, [companyGuid, fetchContactsData, resetContacts])

  const editContact = (row: ContactModel, event: MuiEvent<React.MouseEvent<HTMLElement>>) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    history.push(contactDetailsEditUrl(row.workplaceId, row.contactId))
  }

  const showModal = (row: ContactModel, event: MuiEvent<React.MouseEvent<HTMLElement>>) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    setIsShowingModal(true)
    setContactIdToDelete(row.contactId)
  }

  const createNewContact = () => history.push(createContactUrl(companyGuid))

  const handleDeleteContact = () => {
    setIsShowingModal(false)
    deleteContact(companyGuid, contactIdToDelete)
  }

  const cancelDelete = () => {
    setIsShowingModal(false)
    setContactIdToDelete(null)
  }

  const handleDisplayContactDetails: GridEventListener<'rowClick'> = (params: GridRowParams<ContactModel>) => {
    params.row && history.push(contactDetailsUrl(companyGuid, params.row.contactId))
  }

  const handleShowActiveContactsChange = () => setIsShowingOnlyActiveContacts(!isShowingOnlyActiveContacts)
  const handleShowOnlyMainContacts = () => setIsShowingOnlyMainContacts(!isShowingOnlyMainContacts)

  const [visibleContacts, setVisibleContacts] = useState<ContactModel[]>([])

  const getRecipients = (): ICreateEmailRecipients[] => {
    return visibleContacts
      .reduce((accumulator: ContactModel[], current: ContactModel) => {
        selectedRowIds.some(rowId => current.contactId === rowId) && accumulator.push(current)
        return accumulator
      }, [])
      .map((value: ContactModel): ICreateEmailRecipients => ({ id: value.contactId, emailAddress: value.email }))
  }

  useEffect(() => {
    setClearSelectedRows(createEmailState === FetchingState.SUCCESS)
  }, [createEmailState])

  useEffect(() => {
    const contactList = formatPhones(contacts)
    const visible = contactList.filter((contact: ContactModel) => {
      const isActiveContactFilter = isShowingOnlyActiveContacts ? contact.status.statusId === '0' : true
      const isMainContactFilter = isShowingOnlyMainContacts ? contact.isMainContact : true
      return isActiveContactFilter && isMainContactFilter
    })
    setVisibleContacts(visible)
  }, [contacts, isShowingOnlyActiveContacts, isShowingOnlyMainContacts])

  if (!loaded) return <Loading />

  return (
    <>
      <Box display="flex" justifyContent="space-between" marginLeft={2} marginBottom={2}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <FormControlLabel
            label="Visa även inaktiva kontakter"
            control={<Checkbox checked={!isShowingOnlyActiveContacts} onChange={handleShowActiveContactsChange} />}
          />
          <Box>
            <FormControlLabel
              label="Visa endast huvudkontakter"
              control={
                <Checkbox
                  checked={isShowingOnlyMainContacts}
                  onChange={handleShowOnlyMainContacts}
                  name="isMainContact"
                />
              }
              sx={{ marginRight: 1 }}
            />
            <InformationPopover
              infoText="Syftet med att märka upp en kontakt som en huvudkontakt är att du snabbt kan få fram de personer som
              du jobbar med mest frekvent, exempelvis vid gruppmejl."
              iconSize='small'
            />
          </Box>
        </Box>

        <Button onClick={createNewContact}>Skapa ny kontaktperson</Button>
      </Box>

      {visibleContacts && (
        <Typography variant="subtitle1" marginBottom={1}>
          {visibleContacts.length} {visibleContacts.length === 1 ? 'kontakt' : 'kontakter'}{' '}
        </Typography>
      )}

      <DataGridWrapper
        rows={visibleContacts}
        columns={contactColumns(editContact, showModal)}
        getRowId={(row: ContactModel) => row.contactId}
        labelRowsPerPage={'Kontaktpersoner per sida'}
        onRowClick={handleDisplayContactDetails}
        checkboxSelection
        clearSelectedRows={clearSelectedRows}
        getSelectedRowIds={setSelectedRowIds}
        customActions={
          <CreateEmail recipients={getRecipients()} createEmail={createEmail} createEmailState={createEmailState} />
        }
      />

      <ConfirmationModal
        active={isShowingModal}
        close={cancelDelete}
        submit={handleDeleteContact}
        message={`
        Skapade händelser finns kvar när en kontaktperson tas bort. Däremot försvinner personens namn ifrån händelsen.

        Är du säker på att du vill ta bort denna kontaktperson?
        `}
        title={'Bekräfta borttagning'}
      />
    </>
  )
}

export default Contacts
