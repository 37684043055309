import { IUnion } from '@local/src/Models/Domain/Union.model'

import { organisationsregistretApi } from '../organisationsregistretApi'

export const unionsApi = organisationsregistretApi.injectEndpoints({
  endpoints: (builder) => ({
    getUnions: builder.query<IUnion[], void>({
      query: () => ({ url: 'artifacts/unions', method: 'GET' }),
      transformResponse: (data: { accountID: string; name2: string }[]) =>
        data.map((d) => ({ id: d.accountID, name: d.name2 }) as IUnion),
    }),
  }),
})

export const { useGetUnionsQuery } = unionsApi
