import { object, string } from 'yup';

export const validationSchema = object({
  firstName: string()
    .required('Förnamn måste anges')
    .max(40, 'Förnamn är begränsade till 40 tecken'),
  email: string()
    .nullable()
    .matches(/^[^åäöÅÄÖ]+$/, 'Får inte innehålla åäö')
    .email('E-post-adressen måste ha korrekt format')
    .max(64, 'E-post-adresssen får som mest vara 64 tecken'),

  telephone: string()
    .nullable()
    .max(12, 'Telefonnummer får max innehålla 12 tecken')
    .matches(/^[0-9-+\s]*$/, 'Telefonnummer får endast innehålla siffror, bindesstreck, plustecken samt mellanslag'),
  cellphone: string()
    .nullable()
    .max(12, 'Mobilnummer får max innehålla 12 tecken')
    .matches(/^[0-9-+\s]*$/, 'Mobilnummer får endast innehålla siffror, bindesstreck, plustecken samt mellanslag'),
  lastName: string()
    .required('Efternamn måste anges')
    .max(40, 'Efternamn är begränsade till 40 tekcken'),
  roleLevelOne: string().required('Kategori måste anges'),
  roleLevelTwo: string().required('Roll måste anges'),
  union: string()
    .notRequired()
    .when('roleLevelTwo', {
      is: (value) => value === '0011' || value === '0012',
      then: () => string().required('Fackförbund måste anges'),
      otherwise: () => string().notRequired(),
    }),
  contactStatus: string().required('Status måste anges'),
  linkedin: string()
    .nullable()
    .matches(/linkedin\.com/, 'Måste innehålla linkedin.com')
    .max(500, 'LinkedIn-länkar är begränsade till 500 tecken'),
})