import { ISelectionData } from '@local/src/Components/Inputs/Selection/Common/ISelectionData'
import { IWorkplaceStatus } from '@local/src/Models/Domain/WorkplaceStatus.model'
import { IUrlSyncedData } from '@local/src/Utils/helpers/Url/IUrlSyncedData'
import { useUrlSynchedEntityOptionsSelection } from '@local/src/Utils/helpers/Url/useUrlSynchedEntityOptionsSelection'
import { useGetCompanyStatusesQuery } from '@local/src/Utils/network/endpoints/workplacesApi'

export interface IWorkplaceStatusFilterData extends IUrlSyncedData, ISelectionData<IWorkplaceStatus> {}

export const useWorkplaceStatusFilter = (): IWorkplaceStatusFilterData =>
  useUrlSynchedEntityOptionsSelection<IWorkplaceStatus>('workplaceStatuses', useGetCompanyStatusesQuery)
