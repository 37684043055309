import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IProject, IProjectList, IProjectAttachment, IProjectStatusSelectOption } from './Projects.types'
import { ICase } from './Case.types'

export interface ProjectsStore {
  caseFailure: boolean
  cases: ICase[]
  canEditCases: boolean
  canEdit: boolean
  deleteFail: boolean
  deleteInProgress: boolean
  detailedProjectFetchFailure: boolean
  downloadAttachmentFailure: boolean
  fetchAttachmentsFailure: boolean
  fetchCasesByProjectIdFailure: boolean
  fileNameValidationFailure: boolean
  filterOnActiveProjects: boolean
  getProjectsByWorkplaceError: boolean
  linkingCases: boolean
  project: IProject
  projectAttachments: IProjectAttachment[]
  projects: IProjectList[]
  projectsLoaded: boolean
  projectStatuses: IProjectStatusSelectOption[]
  saveFail: boolean
  updateProjectInProgress: boolean
  saveProjectErrorMessage: string
  createProjectInProgress: boolean
  fileUploadInProgress: boolean
  arendeIdUnderDeletion: string
  uploadAttachmentError: boolean
}

export const initialState: ProjectsStore = {
  caseFailure: false,
  cases: [],
  canEditCases: false,
  canEdit: false,
  deleteFail: false,
  deleteInProgress: false,
  detailedProjectFetchFailure: false,
  downloadAttachmentFailure: false,
  fetchAttachmentsFailure: false,
  fetchCasesByProjectIdFailure: false,
  fileNameValidationFailure: false,
  filterOnActiveProjects: true,
  getProjectsByWorkplaceError: false,
  linkingCases: false,
  project: null,
  projectAttachments: [],
  projects: null,
  projectsLoaded: false,
  projectStatuses: [],
  saveFail: false,
  updateProjectInProgress: false,
  saveProjectErrorMessage: '',
  createProjectInProgress: false,
  fileUploadInProgress: false,
  arendeIdUnderDeletion: '',
  uploadAttachmentError: false,
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    updateProjectStarted: (state: ProjectsStore) => {
      state.updateProjectInProgress = true
    },
    updateProjectSuccess: (state: ProjectsStore) => {
      state.updateProjectInProgress = false
    },
    updateProjectFailure: (state: ProjectsStore, action: PayloadAction<string>) => {
      state.saveFail = true
      state.updateProjectInProgress = false
      state.saveProjectErrorMessage = action.payload
    },
    fetchProjectStatusesStarted: () => {
      // unused
    },
    fetchProjectStatusesSuccess: (state: ProjectsStore, action: PayloadAction<IProjectStatusSelectOption[]>) => {
      state.projectStatuses = action.payload
    },
    fetchProjectsByWorkplaceStarted: (state: ProjectsStore) => {
      state.projects = null
      state.projectsLoaded = false
      state.deleteFail = false
    },
    fetchProjectsByWorkplaceSuccess: (state: ProjectsStore, action: PayloadAction<IProjectList[]>) => {
      state.projects = action.payload
      state.projectsLoaded = true
    },
    fetchProjectsByWorkplaceFailure: (state: ProjectsStore) => {
      state.getProjectsByWorkplaceError = true
      state.projectsLoaded = true
      state.projects = []
    },
    fetchProjectByIdStarted: (state: ProjectsStore) => {
      state.project = null
      state.detailedProjectFetchFailure = false
    },
    fetchProjectByIdSuccess: (state: ProjectsStore, action: PayloadAction<IProject>) => {
      state.project = action.payload
      state.detailedProjectFetchFailure = false
    },
    fetchProjectByIdFailure: (state: ProjectsStore) => {
      state.detailedProjectFetchFailure = true
      state.project = null
    },
    filterProjects: (state: ProjectsStore, action: PayloadAction<boolean>) => {
      state.filterOnActiveProjects = action.payload
    },
    deleteStarted: (state: ProjectsStore) => {
      state.deleteInProgress = true
    },
    deleteFailure: (state: ProjectsStore) => {
      state.deleteFail = true
      state.deleteInProgress = false
    },
    deleteSucceeded: (state: ProjectsStore) => {
      state.deleteInProgress = false
    },
    deleteAttachmentStarted: (state: ProjectsStore) => {
      state.deleteInProgress = true
    },
    deleteAttachmentSuccess: (state: ProjectsStore, action: PayloadAction<string>) => {
      state.projectAttachments = state.projectAttachments.filter(attachment => attachment.fileId !== action.payload)
      state.deleteInProgress = false
    },
    deleteAttachmentFailure: (state: ProjectsStore) => {
      state.deleteFail = true
      state.deleteInProgress = false
    },
    fetchProjectAttachmentInitiate: (state: ProjectsStore) => {
      state.fetchAttachmentsFailure = false
    },
    fetchProjectAttachmentSuccess: (state: ProjectsStore, action: PayloadAction<IProjectAttachment[]>) => {
      state.projectAttachments = action.payload
    },
    fetchProjectAttachmentFailure: (state: ProjectsStore) => {
      state.fetchAttachmentsFailure = true
    },
    uploadProjectAttachmentInitiate: (state: ProjectsStore) => {
      state.uploadAttachmentError = false
      state.fileNameValidationFailure = false
      state.fileUploadInProgress = true
    },
    uploadProjectAttachmentSuccess: (state: ProjectsStore) => {
      state.fileUploadInProgress = false
    },
    uploadProjectAttachmentFailure: (state: ProjectsStore) => {
      state.fileUploadInProgress = false
      state.uploadAttachmentError = true
    },
    uploadProjectAttachmentValidationError: (state: ProjectsStore) => {
      state.fileNameValidationFailure = true
      state.fileUploadInProgress = false
    },
    downloadStarted: (state: ProjectsStore) => {
      state.downloadAttachmentFailure = false
    },
    downloadSuccess: (state: ProjectsStore) => {
      state.downloadAttachmentFailure = false
    },
    downloadFailure: (state: ProjectsStore) => {
      state.downloadAttachmentFailure = true
    },
    fetchCasesByProjectIdStarted: (state: ProjectsStore) => {
      state.caseFailure = false
    },
    fetchCasesByProjectIdSuccess: (
      state: ProjectsStore,
      action: PayloadAction<{ cases: ICase[]; canEdit: boolean }>
    ) => {
      state.cases = action.payload.cases
      state.canEditCases = action.payload.canEdit
      state.caseFailure = false
    },
    fetchCasesByProjectIdFailure: (state: ProjectsStore) => {
      state.cases = []
      state.canEditCases = false
      state.caseFailure = true
    },
    linkCasesStarted: (state: ProjectsStore) => {
      state.linkingCases = true
    },
    linkCasesSuccess: (state: ProjectsStore) => {
      state.linkingCases = false
    },
    linkCasesFailure: (state: ProjectsStore) => {
      state.linkingCases = false
    },
    deleteCaseFromProjectStarted: (state: ProjectsStore, action: PayloadAction<string>) => {
      state.arendeIdUnderDeletion = action.payload
    },
    deleteCaseFromProjectSuccess: (state: ProjectsStore, action: PayloadAction<string>) => {
      state.cases = state.cases.filter(cases => cases.arendeId !== action.payload)
      state.canEdit = state.canEditCases
      state.arendeIdUnderDeletion = ''
    },
    deleteCaseFromProjectFailure: (state: ProjectsStore) => {
      state.arendeIdUnderDeletion = ''
    },
    resetProject: (state: ProjectsStore) => {
      state.project = undefined
    },
    createProjectStarted: (state: ProjectsStore) => {
      state.createProjectInProgress = true
    },
    createProjectSuccess: (state: ProjectsStore) => {
      state.createProjectInProgress = false
    },
    createProjectFailed: (state: ProjectsStore, action: PayloadAction<string>) => {
      state.createProjectInProgress = false
      state.saveFail = true
      state.saveProjectErrorMessage = action.payload
    },
  },
})

export const { actions, reducer: projectsReducer } = projectsSlice
export const {
  filterProjects,
  deleteCaseFromProjectFailure,
  deleteCaseFromProjectStarted,
  deleteCaseFromProjectSuccess,
  fetchCasesByProjectIdFailure,
  deleteAttachmentStarted,
  deleteAttachmentSuccess,
  deleteAttachmentFailure,
  deleteStarted,
  fetchCasesByProjectIdStarted,
  fetchCasesByProjectIdSuccess,
  fetchProjectAttachmentFailure,
  fetchProjectAttachmentSuccess,
  fetchProjectAttachmentInitiate,
  fetchProjectByIdStarted,
  fetchProjectByIdSuccess,
  fetchProjectsByWorkplaceFailure,
  fetchProjectsByWorkplaceStarted,
  fetchProjectsByWorkplaceSuccess,
  fetchProjectStatusesStarted,
  fetchProjectStatusesSuccess,
  updateProjectStarted,
  updateProjectSuccess,
  updateProjectFailure,
  createProjectStarted,
  createProjectSuccess,
  createProjectFailed,
  fetchProjectByIdFailure,
  uploadProjectAttachmentFailure,
  uploadProjectAttachmentSuccess,
  uploadProjectAttachmentInitiate,
  uploadProjectAttachmentValidationError,
  deleteFailure,
  linkCasesFailure,
  linkCasesSuccess,
  linkCasesStarted,
  downloadFailure,
  downloadSuccess,
  downloadStarted,
  deleteSucceeded,
  resetProject,
} = projectsSlice.actions
export default projectsReducer
