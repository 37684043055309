import { useState, useEffect } from "react";

export type SelectionMode = 'additive' | 'subtractive'
export const useContactSelection = (numberOfRows: number) => {
    const [selectionMode, setSelectionMode] = useState<SelectionMode>('additive');
    const [selectionDelta, setSelectionDelta] = useState<string[]>([]);

    useEffect(() => {
        if (selectionDelta.length > 0 && selectionDelta.length === numberOfRows) {
            toggleSelectionMode();
        }
    }, [numberOfRows, selectionDelta.length]);

    const toggleSelectionMode = () => {
        setSelectionDelta([]);
        setSelectionMode(prev => prev === 'additive' ? 'subtractive' : 'additive');
    };

    const toggleSelection = (id: string) => {
        if (selectionDelta.includes(id))
            setSelectionDelta(prev => prev.filter(s => s !== id));

        else
            setSelectionDelta(prev => prev.concat(id));
    };

    const isSelected = (id: string) => {
        if (selectionMode === 'additive')
            return selectionDelta.includes(id);

        else
            return !selectionDelta.includes(id);
    };

    const numberOfSelectedContacts = selectionMode === 'additive' ? selectionDelta.length : numberOfRows - selectionDelta.length;

    const areAllSelected = numberOfSelectedContacts === numberOfRows && numberOfRows > 0
    const areOnlySomeSelected = numberOfSelectedContacts > 0 && numberOfSelectedContacts < numberOfRows
    const isNoneSelected = numberOfSelectedContacts === 0

    return {
        toggleSelectionMode,
        toggleSelection,
        isSelected,
        selectionMode,
        numberOfSelectedContacts,
        areAllSelected,
        areOnlySomeSelected,
        isNoneSelected
    };
};
