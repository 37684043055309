import { Box, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import LinkWithIcon from '@local/src/Components/LinkWithIcon/LinkWithIcon'
import WorkIcon from '@mui/icons-material/Work'

import { IProject } from '../Projects.types'

interface ProjectDetailsProps {
  project: IProject
}

const ProjectDetails = ({
  project,
}: ProjectDetailsProps): JSX.Element => {

  const allWorkplaces = () => {
    return [project.huvudForetag, ...project.foretag]
  }

  const reportLink = `https://app.powerbi.com/groups/67f45051-c89e-484b-abef-7b1e195d297d/reports/ec27663a-1ba7-401e-8d30-67bac9cfbc0a/ReportSection?filter=Kundrapport/Projektnummer eq '${project.projektnummer}'`

  return (
    <>
      <Box marginBottom={3} display='flex' flexDirection='column' gap={1}>
        <Typography variant='h4'>{`${project.projektnummer} - ${project.projektnamn}`}</Typography>
        <Typography variant='body1' maxWidth={752}>{project.beskrivning}</Typography>
        <LinkWithIcon
          icon={<OpenInNewIcon fontSize='small' />}
          href={reportLink}
          external
          text='Analysrapport projekt'
        />
      </Box>
      <Stack
        display='grid'
        gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr 1fr 1fr' }}
      >
        <ListItem disableGutters divider sx={{ mb: 1, pb: 1 }}>
          <ListItemText primary='Projektägare' secondary={project.projektagare.employeeName} />
        </ListItem>
        <ListItem disableGutters divider sx={{ mb: 1, pb: 1 }}>
          <ListItemText primary='Projektskapare' secondary={project.skapadAv.name} />
        </ListItem>
        <ListItem disableGutters divider sx={{ mb: 1, pb: 1 }}>
          <ListItemText primary='Företagets kontaktperson' secondary={project.kontaktperson.fullName} />
        </ListItem>
        <ListItem disableGutters divider sx={{ mb: 1, pb: 1 }}>
          <ListItemText primary='Projektstatus' secondary={project.projektstatus.projektstatusText} />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary='Projektperiod'
            secondary={`${project.projektPeriod.start.substring(0, 10)} - ${project.projektPeriod.end.substring(0, 10)}`}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary='Kopplingsperiod'
            secondary={`${project.klientkopplingPeriod.start.substring(0, 10)} - ${project.klientkopplingPeriod.end.substring(0, 10)}`}
          />
        </ListItem>
      </Stack>

      <Stack display='flex' gap={2} marginTop={4}>
        <Box>
          <Typography variant='body1'>Arbetsställen</Typography>
          <List>
            {allWorkplaces().map(value => (
              <ListItem key={value.foretagId}>
                <ListItemIcon>
                  <WorkIcon />
                </ListItemIcon>
                <ListItemText
                  primary={value.foretagNamn}
                  secondary={value.address}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Stack>
    </>
  )
}

export default ProjectDetails