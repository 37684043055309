import { useLocalStorage } from "@local/src/hooks/useLocalStorage"
import type { } from '@mui/x-data-grid/themeAugmentation'
import { Box, Checkbox, CheckboxProps, Stack, Tooltip, Typography } from "@mui/material"
import {
  DataGrid,
  GridColumnVisibilityModel,
  GridDensity,
  GridSortModel,
  GridEventListener,
  useGridApiRef,
  GridColDef,
  GridRowId,
  gridExpandedSortedRowIdsSelector,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridRowSelectionModel,
  GridValidRowModel,
  DataGridProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid"
import React, { useCallback, useEffect, useRef, useState } from "react"

import s from './DataGridWrapper.module.scss'

const CustomCheckbox = React.forwardRef((props: CheckboxProps, ref) => (
  <Tooltip
    ref={ref}
    placement='left'
    title='Markera för att skicka gruppmejl'
  >
    <Checkbox {...props} />
  </Tooltip>
))

export interface DataGridWrapperProps extends DataGridProps {
  columns: GridColDef<unknown>[]
  rows: unknown[]
  columnVisibilityModel?: GridColumnVisibilityModel
  checkboxSelection?: boolean
  labelRowsPerPage?: string
  labelNoRows?: string
  sortModel?: GridSortModel
  showDefaultToolbar?: boolean
  hideFooter?: boolean
  customActions?: JSX.Element
  onRowClick?: GridEventListener<"rowClick">
  setSortModel?: (val: { field: string; sort: string; }[]) => void
  getRowId: (row: unknown) => string
  columnVisibilityModelChange?: (model: GridColumnVisibilityModel) => void
  getVisibleRowIds?: (ids: GridRowId[]) => void
  getSelectedRowIds?: (ids: GridRowId[]) => void
  clearSelectedRows?: boolean
}

const DataGridWrapper = ({
  columns,
  rows,
  sortModel,
  columnVisibilityModel,
  labelRowsPerPage = 'Rader per sida',
  labelNoRows = 'Inga resultat',
  customActions,
  showDefaultToolbar = false,
  hideFooter = false,
  checkboxSelection = false,
  clearSelectedRows = false,
  onRowClick,
  setSortModel,
  columnVisibilityModelChange,
  getRowId,
  getVisibleRowIds,
  getSelectedRowIds,
}: DataGridWrapperProps): JSX.Element => {

  const apiRef = useGridApiRef()
  const [pageSize, setPageSize] = useLocalStorage('org.tablePaginationModel', { page: 0, pageSize: 100 })
  const [density, setDensity] = useLocalStorage('org.tableDensity', 'standard')
  const visibleRowIds = useRef<GridRowId[]>([])
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([])

  useEffect(() => {
    getVisibleRowIds?.(visibleRowIds.current)
    apiRef.current.subscribeEvent("stateChange", () => {
      const visibleRows = gridExpandedSortedRowIdsSelector(apiRef.current.state, apiRef.current.instanceId)
      if (visibleRowIds.current.sort().join(',') !== visibleRows.sort().join(',')) {
        visibleRowIds.current = visibleRows
        getVisibleRowIds?.(visibleRowIds.current)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSelectedRowsChanged = (rows: GridRowSelectionModel) => {
    setSelectedRows(rows)
    getSelectedRowIds(rows)
  }

  const handleCheckboxChange = () => {
    selectedRows.length === 0
      ? checkAllRows()
      : uncheckAllRows()
  }

  const uncheckAllRows = useCallback(() => {
    const selectedRows = apiRef.current.getSelectedRows()
    selectedRows.forEach((_, id) => {
      apiRef.current.selectRow(id, false)
    })
  }, [apiRef])

  const checkAllRows = () => {
    const rows = apiRef.current.getRowModels()
    rows.forEach((_: GridValidRowModel, key: GridRowId) => {
      apiRef.current.selectRow(key)
    })
  }

  const filterModelChanged = () => {
    /*
      When user filters, we need to remove checkbox on all rows no longer shown.
      Do this in a timeout so all of DataGrids internal events has time to fire.
    */
    setTimeout(() => {
      const filteredSelectedRows = selectedRows.filter(guid => !visibleRowIds.current.includes(guid))
      for (const idToUncheck of filteredSelectedRows) {
        apiRef.current.selectRow(idToUncheck, false)
      }
    }, 100)
  }

  useEffect(() => {
    clearSelectedRows && uncheckAllRows()
  }, [clearSelectedRows, uncheckAllRows])

  useEffect(() => {
    if (checkboxSelection && !columns.some(row => row.headerName === 'Checkbox')) {
      columns.unshift({
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        filterable: false,
        hideable: false,
        headerName: 'Checkbox'
      })
    }
  }, [checkboxSelection, columns])

  const selectedRowsText = () => {
    const selectedLength = selectedRows.length

    if (selectedLength === 0) {
      return `Markera alla ${rows?.length && rows.length}`
    }
    if (selectedLength === 1) {
      return `${selectedLength} vald av ${rows.length}`
    }
    return `${selectedLength} valda av ${rows.length}`
  }

  const Toolbar = (): JSX.Element => {
    return (
      <GridToolbarContainer sx={{ display: 'flex', justifyContent: customActions ? 'space-between' : 'flex-end' }}>
        {customActions && rows?.length > 0 && (
          <Box display='flex' alignItems='center'>
            <Checkbox
              checked={selectedRows.length === rows.length}
              indeterminate={selectedRows.length > 0 && selectedRows.length < rows.length}
              onChange={handleCheckboxChange}
            />
            <Typography variant='body1' paddingRight={2}>{selectedRowsText()}</Typography>
            <Box visibility={selectedRows.length > 0 ? 'inherit' : 'hidden'}>
              {customActions}
            </Box>
          </Box>
        )}
        {showDefaultToolbar && rows?.length > 0 && (
          <Box>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
          </Box>
        )}
      </GridToolbarContainer>
    )
  }

  return (
    <DataGrid
      apiRef={apiRef}
      columns={columns}
      rows={rows || []}
      checkboxSelection={checkboxSelection}
      onRowSelectionModelChange={onSelectedRowsChanged}
      getRowClassName={() => typeof onRowClick === 'function' && s.Clickable}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={columnVisibilityModelChange}
      onFilterModelChange={filterModelChanged}
      disableRowSelectionOnClick
      autoHeight
      hideFooter={hideFooter}
      getRowId={getRowId}
      paginationModel={pageSize}
      density={density as GridDensity}
      onPaginationModelChange={setPageSize}
      onRowClick={onRowClick}
      columnBuffer={columns.length + 1} // +1 for testing purposes (can mess with rendering in headless.)
      pageSizeOptions={[5, 10, 20, 25, 50, 100]}
      onStateChange={(state) => {
        if (state.density.value !== density) {
          setDensity(state.density.value as string)
        }
      }}
      sx={{
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
          display: "none"
        }
      }}
      disableColumnMenu
      componentsProps={{
        pagination: {
          labelRowsPerPage: labelRowsPerPage,
        },
        panel: {
          placement: "bottom-end"
        },
      }}
      components={{
        NoRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            {labelNoRows}
          </Stack>
        ),
        NoResultsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            {labelNoRows}
          </Stack>
        ),
        Toolbar: showDefaultToolbar || customActions ? Toolbar : null,
        BaseCheckbox: CustomCheckbox,
      }}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
    />
  )
}

export default DataGridWrapper