import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ICompany, ProjectResult } from '../Company/Company.model'

interface IHomeState {
  companiesLoaded: boolean
  projectsLoaded: boolean
  companyCreationErrorMessage: string
  filterOnActiveProject: boolean
  projectResult: ProjectResult
  projectsSearchFailure: boolean
  allCompaniesData: ICompany[]
  companyCreationInitiated: boolean
  companyCreationSuccess: boolean
  companyCreationFailed: boolean
  contactsLoaded: boolean
}

export const initialState: IHomeState = {
  companiesLoaded: true,
  projectsLoaded: true,
  filterOnActiveProject: true,
  projectResult: { projectData: [], type: '' },
  projectsSearchFailure: false,
  allCompaniesData: [],
  companyCreationInitiated: false,
  companyCreationSuccess: false,
  companyCreationFailed: false,
  companyCreationErrorMessage: '',
  contactsLoaded: false,
}

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    createCompanyFailure: (state, action: PayloadAction<string>) => {
      state.companyCreationFailed = true
      state.companyCreationInitiated = false
      state.companyCreationErrorMessage = action.payload
    },
    createCompanyInitiated: state => {
      state.companyCreationFailed = false
      state.companyCreationInitiated = true
      state.companyCreationSuccess = false
      state.companyCreationErrorMessage = ''
    },
    createCompanySuccess: state => {
      state.companyCreationSuccess = true
      state.companyCreationInitiated = false
    },
    createCompanyReset: state => {
      state.companyCreationFailed = false
      state.companyCreationInitiated = false
      state.companyCreationSuccess = false
      state.companyCreationErrorMessage = ''
    },
    //all companies
    searchAllCompaniesInitiated: state => {
      state.companiesLoaded = false
    },
    searchAllCompaniesSuccess: (state, action: PayloadAction<ICompany[]>) => {
      state.companiesLoaded = true
      state.allCompaniesData = action.payload
    },
    searchAllCompaniesFailure: state => {
      state.companiesLoaded = true
      state.allCompaniesData = []
    },
    //project
    fetchProjectSearchInitiated: state => {
      state.projectsLoaded = false
    },
    fetchProjectSearchSuccess: (state, action: PayloadAction<ProjectResult>) => {
      state.projectsLoaded = true
      state.projectResult = action.payload
      state.projectsSearchFailure = false
    },
    fetchProjectsSearchFailure: state => {
      state.projectsLoaded = true
      state.projectsSearchFailure = true
      state.projectResult = null
    },
    filterProjects: (state, action: PayloadAction<boolean>) => {
      state.filterOnActiveProject = action.payload
    },
  },
})

export const { actions, reducer: homeReducer } = homeSlice
export const {
  createCompanyInitiated,
  createCompanyFailure,
  createCompanyReset,
  createCompanySuccess,
  searchAllCompaniesFailure,
  searchAllCompaniesSuccess,
  searchAllCompaniesInitiated,
  fetchProjectSearchSuccess,
  fetchProjectsSearchFailure,
  fetchProjectSearchInitiated,
  filterProjects,
} = homeSlice.actions
export default homeReducer
