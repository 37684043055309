import { axiosInstance } from '@local/Utils/network/axios.config'
import { AppDispatch } from '@local/Store/configureStore'

import { ContactModel, CreateContact } from './CompanyTabs/Contacts/Contacts.model'
import { ICompany } from './Company.model'
import {
  createContactFailure,
  createContactStarted,
  createContactSuccess,
  deleteContactFailed,
  deleteContactStarted,
  deleteContactSuccess,
  fetchCompanyFailure,
  fetchCompanyStarted,
  fetchCompanySuccess,
  fetchContactFailed,
  fetchContactStarted,
  fetchContactSuccess,
  fetchDetailedContactFailure,
  fetchDetailedContactStarted,
  fetchDetailedContactSuccess,
  resetContactsTab,
  updateCompanyFailure,
  updateCompanyStarted,
  updateCompanySuccess,
  updateContactFailure,
  updateContactStarted,
  updateContactSuccess,
} from './Company.reducers'

export const fetchCompanyData = (guid: string) => (dispatch: AppDispatch) => {
  dispatch(fetchCompanyStarted())

  axiosInstance.get('workplaces/' + guid)
    .then(res => {
      dispatch(fetchCompanySuccess(res.data as ICompany))
    })
    .catch(() => {
      dispatch(fetchCompanyFailure())
    })
}

export const updateCompany = (company: ICompany) => (dispatch: AppDispatch) => {
  dispatch(updateCompanyStarted())

  axiosInstance.put(`workplaces/${company.workplaceId}`, company)
    .then(() => {
      dispatch(updateCompanySuccess())
    })
    .catch(errorMessage => {
      dispatch(updateCompanyFailure(errorMessage as string))
    })
}

export const fetchDetailedContactData = (companyGuid: string, contactGuid: string) => (dispatch: AppDispatch) => {
  dispatch(fetchDetailedContactStarted())
  axiosInstance.get(`workplaces/${companyGuid}/contacts/${contactGuid}`)
    .then(res => {
      dispatch(fetchDetailedContactSuccess(res.data as ContactModel))
    })
    .catch(() => {
      dispatch(fetchDetailedContactFailure())
    })
}

export const fetchContactsData = (guid: string) => (dispatch: AppDispatch) => {
  dispatch(fetchContactStarted())
  axiosInstance.get(`workplaces/${guid}/contacts`)
    .then(res => {
      dispatch(fetchContactSuccess(res.data as ContactModel[]))
    })
    .catch(() => {
      dispatch(fetchContactFailed())
    })
}

export const createContact = (contact: CreateContact) => (dispatch: AppDispatch) => {
  dispatch(createContactStarted())

  axiosInstance.post(`workplaces/${contact.workplaceBPGuid}/contacts`, contact)
    .then(() => {
      dispatch(createContactSuccess())
    })
    .catch(errorMessage => {
      dispatch(createContactFailure(errorMessage as string))
    })
}

export const updateContact = (contact: ContactModel) => (dispatch: AppDispatch) => {
  dispatch(updateContactStarted())

  axiosInstance.put(`workplaces/${contact.workplaceId}/contacts/${contact.contactId}`, contact)
    .then(() => {
      dispatch(updateContactSuccess())
    })
    .catch(() => {
      dispatch(updateContactFailure())
    })
}

export const deleteContact = (workplaceId: string, contactId: string, fetch = true) => (dispatch: AppDispatch) => {
  dispatch(deleteContactStarted(fetch))
  axiosInstance.delete(`workplaces/${workplaceId}/contacts/${contactId}`)
    .then(() => {
      dispatch(deleteContactSuccess())
      if (fetch) {
        dispatch(fetchContactsData(workplaceId))
      }
    })
    .catch(() => {
      dispatch(deleteContactFailed())
    })
}

export const resetContacts = () => (dispatch: AppDispatch) => {
  dispatch(resetContactsTab())
}
