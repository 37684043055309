import { IProjectList } from '@local/src/Scenes/Company/CompanyTabs/Projects/Projects.types'
import { tableTooltip } from '@local/src/Utils/helpers/tooltips'
import { formatUtcDate } from '@local/Utils/helpers'
import { GridColDef } from '@mui/x-data-grid'

export const projectColumns: GridColDef<IProjectList>[] = [
  { 
    field: 'projektnummer',
    headerName: 'Projektnr',
    renderCell: ({ row }) => tableTooltip(row?.projektnummer),
    flex: 1,
    maxWidth: 100,
  },
  {
    field: 'projektnamn',
    headerName: 'Projektnamn',
    renderCell: ({ row }) => tableTooltip(row?.projektnamn),
    flex: 1,
  },
  {
    field: 'foretag.foretagNamn',
    headerName: 'Företag',
    valueGetter: ({ row }) => row?.foretag?.foretagNamn,
    renderCell: ({ row }) => tableTooltip(row?.foretag?.foretagNamn),
    flex: 1,
  },
  {
    field: 'projektagare.employeeName',
    headerName: 'Projektägare',
    valueGetter: ({ row }) => row?.projektagare?.employeeName,
    renderCell: ({ row }) => tableTooltip(row?.projektagare?.employeeName),
    flex: 1,
  },
  {
    field: 'projektPeriod.end',
    headerName: 'Slutdatum',
    valueGetter: ({ row }) => row?.projektPeriod?.end,
    renderCell: ({ row }) => tableTooltip(formatUtcDate(row?.projektPeriod?.end) !== '0001-01-01' ? formatUtcDate(row?.projektPeriod?.end) : ''),
    flex: 1,
    maxWidth: 100,
  },
  {
    field: 'projektstatus.projektstatusText',
    headerName: 'Status',
    valueGetter: ({ row }) => row?.projektstatus?.projektstatusText,
    renderCell: ({ row }) => tableTooltip(row?.projektstatus?.projektstatusText),
    flex: 1,
    maxWidth: 100,
  },
  {
    field: 'antalKlienter',
    headerName: 'Omställningskunder',
    renderCell: ({ row }) => tableTooltip(row?.antalKlienter),
    flex: 1,
    maxWidth: 160,
  },
  {
    field: 'foretag.antalForetag',
    headerName: 'Antal arbetsställen',
    valueGetter: ({ row }) => row?.foretag?.antalForetag,
    renderCell: ({ row }) => tableTooltip(row?.foretag?.antalForetag),
    flex: 1,
    maxWidth: 150,
  },
  {
    field: 'beskrivning',
    headerName: 'Beskrivning',
    renderCell: ({ row }) => tableTooltip(row?.beskrivning),
    flex: 1,
  }
]
