import React, { useCallback, useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import { FetchingState } from '@local/src/App.types'

import NoEmailWarning from './NoEmailWarning/NoEmailWarning'

export interface ICreateEmailModal {
  isOpen: boolean
  closeModal: () => void
  createEmail: (subject: string) => void
  createEmailState: FetchingState
  numberOfEmailRecipients: number
  numberOfRecipientsWithoutEmail: number
}

const CreateEmailModal = ({
  isOpen,
  closeModal,
  createEmail,
  createEmailState,
  numberOfEmailRecipients,
  numberOfRecipientsWithoutEmail,
}: ICreateEmailModal) => {
  const [subject, setSubject] = useState('')

  useEffect(() => {
    if (createEmailState === FetchingState.SUCCESS) {
      setSubject('')
    }
  }, [createEmailState])

  const updateEmailSubject = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSubject(event.target.value)
    },
    [setSubject]
  )

  const handleCreateEmail = useCallback(() => {
    createEmail(subject)
  }, [createEmail, subject])

  const heading = numberOfEmailRecipients < 500 ? 'Skapa gruppmejl' : 'För många mottagare'

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{heading}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2}}>
        {numberOfEmailRecipients <= 500 ? (
          <>
            <Typography variant='body1'>Du har valt att skapa ett gruppmejl till {numberOfEmailRecipients} mottagare.</Typography>

            {numberOfRecipientsWithoutEmail > 0 && <NoEmailWarning quantity={numberOfRecipientsWithoutEmail} />}

            <TextField
              fullWidth
              label='Ange ämnesrad'
              placeholder='Ämne för gruppmejl'
              onChange={updateEmailSubject}
              value={subject}
            />
            <Typography variant='body1'>
              När du trycker på OK skapas ett mejl i mappen Utkast i Outlook.
            </Typography>
          </>
        ) : (
            <Typography variant='body1'>
              Du kan som mest skicka gruppmejl till 500 mottagare. <br/>
              Du har valt {numberOfEmailRecipients} mottagare. <br/>
              Justera filtreringen och försök igen.
            </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={closeModal}>Avbryt</Button>
        <Button variant='text' onClick={handleCreateEmail} disabled={numberOfEmailRecipients > 500}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateEmailModal
