import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { activityEditUrl, activitiesUrl } from '@local/src/basename'
import { useAppDispatch } from '@local/Store/configureStore'
import { fetchDetailedActivityData, resetActivity } from "@local/Scenes/Company/CompanyTabs/Activities/Activities.actions"
import Loading from '@local/src/Components/Loading/Loading'
import { useStateSelector } from '@local/src/Store/useStateSelector'
import { Box, Button, Divider, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import LinkWithIcon from '@local/src/Components/LinkWithIcon/LinkWithIcon'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import { activitySelector } from '../Activities.selectors'

const DetailedActivity = () => {
  const activity = useStateSelector(activitySelector)

  const { companyGuid, activityGuid } = useParams<{ companyGuid: string, activityGuid: string }>()
  const history = useHistory()
  const isEditable = !history.location.pathname.includes('no-edit')
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (activityGuid) {
      dispatch(fetchDetailedActivityData(activityGuid))
    }

    return () => {
      dispatch(resetActivity())
    }
  }, [activityGuid, dispatch])


  if (!activity) return <Loading />

  const isMeeting: boolean = activity.processType?.processTypeCode === 'Z003'
  const isContact: boolean = activity.processType?.processTypeCode === 'Z002'
  const isSeminar: boolean = activity.processType?.processTypeCode === 'Z004'

  const editActivity = () => history.push(activityEditUrl(companyGuid, activityGuid))
  const handleGoBack = () => history.push(activitiesUrl(companyGuid))

  const startTime = new Date(activity.range?.start)
    .toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' })

  const endTime = new Date(activity.range?.end)
    .toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' })

  return (
    <Stack maxWidth={752}>
      <LinkWithIcon
        text="Tillbaka till händelser"
        onClick={handleGoBack}
        icon={<ChevronLeftIcon fontSize="small" />}
      />

      <Typography variant='h5' marginTop={2} marginBottom={1}>Händelse - {activity.processType.processTypeDescription}</Typography>

      <ListItem>
        <ListItemText primary='Händelsedatum' secondary={
          `${format(new Date(activity.range.start), 'yyyy-MM-dd')},
            ${isMeeting ? (`${startTime} - ${endTime}`) : ('')}
            ${!isMeeting ? (activity.createdByUser?.employeeName ? activity.createdByUser.employeeName : activity.changedByUser?.employeeName) : ''}`
        }
        />
      </ListItem>

      <Divider sx={{ marginY: 1 }} />

      <Box display='flex' flexDirection='column' gap={2}>
        <ListItem>
          <ListItemText primary='Ämnesområde' secondary={activity.subjectMatter.subjectMatterDescription} />
        </ListItem>
        {isContact && (
          <ListItem>
            <ListItemText primary='Typ av kontakt' secondary={activity.category.categoryDescription} />
          </ListItem>
        )}

        {isSeminar && (
          <>
            <ListItem>
              <ListItemText primary='Typ av händelse' secondary={activity.customerSpecificSeminar.customerSpecificSeminarDescription} />
            </ListItem>
            <ListItem>
              <ListItemText primary='Antal deltagare' secondary={activity.attendeesNumber} />
            </ListItem>
          </>
        )}

        {isMeeting && (
          <>
            <ListItem>
              <ListItemText primary='Ämne' secondary={activity.subject} />
            </ListItem>
            <ListItem>
              <ListItemText primary='Status' secondary={activity.status.statusDescription} />
            </ListItem>
            <ListItem>
              <ListItemText primary='Plats' secondary={activity.location} />
            </ListItem>
          </>
        )}

        <ListItem>
          <ListItemText primary='Ansvariga på TRR' secondary={
            activity.employeesResponsible.map(colleague => (colleague.employeeName)).join('\n')
          }
            sx={{ whiteSpace: 'pre-line' }}
          />
        </ListItem>

        {isMeeting && (
          <ListItem>
            <ListItemText primary='Kollega på TRR' secondary={
              activity.colleagues.map(colleague => (colleague.colleagueName)).join('\n')
            }
              sx={{ whiteSpace: 'pre-line' }}
            />
          </ListItem>
        )}

        <ListItem>
          <ListItemText primary='Kontaktperson på arbetsstället' secondary={
            activity.contacts.map(contact => (contact.contactName)).join('\n')
          }
            sx={{ whiteSpace: 'pre-line' }}
          />
        </ListItem>

        <ListItem>
          <ListItemText primary='Notering' secondary={activity.text} />
        </ListItem>

        {isEditable && (
          <Button variant='outlined' onClick={editActivity} sx={{ alignSelf: 'baseline', marginTop: 3 }}>Redigera</Button>
        )}
      </Box>

    </Stack>
  )
}

export default DetailedActivity
