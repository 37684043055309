import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material'
import React from 'react'

import { ISelectionData } from './ISelectionData'

interface Props<T> extends ISelectionData<T> {
    name: string,
    getDisplayValue: (value: T) => string
    disabled?: boolean
}

export const SelectionInput = <T,>({ name, selection, setSelection, options, getDisplayValue, isLoading, isError, disabled = false }: Props<T>) => {
    return <Autocomplete
        options={options}
        value={selection}
        onChange={(_, values) => setSelection(values)}
        getOptionLabel={getDisplayValue}
        multiple
        renderInput={(params: AutocompleteRenderInputParams) => <TextField
            {...params}
            label={name}
            error={isError}
            helperText={isError ? 'Kunde inte hämta alternativ' : undefined} />}
        disabled={isError || disabled}
        loading={isLoading}
        disableCloseOnSelect
    />
}