import React from "react"
import { IUnion } from "@local/src/Models/Domain/Union.model"

import { ISelectionData } from "./Common/ISelectionData"
import { SelectionInput } from "./Common/SelectionInput"

interface Props extends ISelectionData<IUnion> {
    disabled?: boolean
}

export const UnionSelectionInput = React.memo((props: Props) => {
    return <SelectionInput
        {...props}
        name="Fackförbund"
        getDisplayValue={o => o.name}
    />
})