import React, { ChangeEvent, useState } from 'react'
import ConfirmationModal from '@local/Components/ConfirmationModal/ConfirmationModal'
import { MuiEvent } from '@mui/x-data-grid'
import DataGridWrapper from '@local/src/Components/DataGridWrapper/DataGridWrapper'
import { Box, Button, CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@local/Store/configureStore'

import { IProjectAttachment } from '../Projects.types'
import { fileUpload, deleteAttachment, downloadAttachment } from '../Projects.actions'
import { projectAttachmentsSelector, uploadFileInProgressSelector } from '../Projects.selectors'

import { documentColumns } from './DocumentProject.columns'

interface DocumentProjectProps {
  projektId: string
  huvudForetagId: string
}

const DocumentProject = ({ projektId, huvudForetagId }: DocumentProjectProps) => {
  const dispatch = useAppDispatch()
  const uploadInProgress = useSelector(uploadFileInProgressSelector)
  const attachments = useSelector(projectAttachmentsSelector)

  const [isShowingDeleteModal, setIsShowingDeleteModal] = useState(false)
  const [attachmentToDelete, setAttachmentToDelete] = useState<string>(null)

  const handleUploadClick = () => {
    document.getElementById('fileInputUpload').click()
  }

  const downloadFile = (row: IProjectAttachment) => {
    dispatch(downloadAttachment(huvudForetagId, projektId, row.fileId, row.fileName))
  }

  const showDeleteModal = (row: IProjectAttachment, event: MuiEvent<React.MouseEvent<HTMLElement>>) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    setIsShowingDeleteModal(true)
    setAttachmentToDelete(row?.fileId)
  }

  const cancelDelete = () => {
    setIsShowingDeleteModal(false)
    setAttachmentToDelete(null)
  }

  const deleteFile = () => {
    setIsShowingDeleteModal(false)
    dispatch(deleteAttachment(huvudForetagId, projektId, attachmentToDelete))
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files[0]
    dispatch(fileUpload(huvudForetagId, projektId, fileUploaded))
    // Set value to null to trigger on change if the same
    // file is chosen for upload more than once
    event.target.value = null
  }

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Button
        variant="outlined"
        size="small"
        onClick={handleUploadClick}
        disabled={uploadInProgress}
        sx={{ alignSelf: 'flex-end' }}
      >
        Ladda upp fil
        {uploadInProgress && <CircularProgress size="1rem" sx={{ marginLeft: 1 }} />}
      </Button>

      <input type="file" id="fileInputUpload" onChange={event => handleChange(event)} style={{ display: 'none' }} />

      <DataGridWrapper
        rows={attachments}
        columns={documentColumns(downloadFile, showDeleteModal)}
        getRowId={(row: IProjectAttachment) => row.fileId}
        labelRowsPerPage="Dokument per sida"
      />

      <ConfirmationModal
        active={isShowingDeleteModal}
        close={cancelDelete}
        submit={deleteFile}
        message="Är du säker på att du vill ta bort denna bilaga?"
        title="Bekräfta borttagning"
      />
    </Box>
  )
}

export default DocumentProject
