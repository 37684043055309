import { IContactSearchResult } from '@local/src/Models/ContactSearch/ContactSearchResult.model'
import { DEFAULT_PAGE_SIZES, IPagingModel } from '@local/src/Models/Misc/Paging.model'
import { ISortingModel } from '@local/src/Models/Misc/Sorting.model'
import { Stack, Typography, Box, Checkbox } from '@mui/material'
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridDensity, GridPaginationModel, GridRowParams, GridSortModel, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { IContact } from '@local/src/Models/Domain/Contact.model'
import { contactDetailsUrl } from '@local/src/basename'
import { useHistory } from 'react-router-dom'
import { IContactsSearchApiModel } from '@local/src/Utils/network/endpoints/ContactsSearch/IContactsSearchApiModel'

import { mapFromPagingModel } from '../../../Common/Mapping/mapFromPagingModel'
import { mapFromSortingModel } from '../../../Common/Mapping/mapFromSortingModel'

import { useContactSelection } from './useContactSelection'
import { SendContactsEmail } from './SendContactsEmail'

interface Props {
    result?: IContactSearchResult
    columns: GridColDef[]
    isSearching: boolean
    paging: IPagingModel
    onPagingChanged: (paging: IPagingModel) => void
    sorting: ISortingModel
    onSortingChanged: (sorting: ISortingModel) => void
    visibleColumns: GridColumnVisibilityModel
    onVisibleColumnsChanged: (visibleColumns: GridColumnVisibilityModel) => void
    density: GridDensity
    onDensityChanged: (density: GridDensity) => void
    apiModel?: IContactsSearchApiModel
}

export const ContactsTable = ({ result, columns, isSearching, paging, onPagingChanged, sorting, onSortingChanged, visibleColumns, onVisibleColumnsChanged, density, onDensityChanged, apiModel }: Props) => {
    const history = useHistory()
    const { isSelected, toggleSelection, toggleSelectionMode, numberOfSelectedContacts, areAllSelected, areOnlySomeSelected, isNoneSelected } = useContactSelection(result?.totalCount ?? 0)
    const [rowsWithSelectionData, setRowsWithSelectionData] = useState<(IContact & { isSelected: boolean; onChange: (id: string) => void })[]>([])

    useEffect(() => {
        setRowsWithSelectionData(result?.contacts?.map(c => ({
            ...c,
            isSelected: isSelected(c.id),
            onChange: (id) => toggleSelection(id),
        })) ?? [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result?.contacts, numberOfSelectedContacts])

    const handleOnPagingChanged = (p: GridPaginationModel) => {
        const newPaging = {
            skip: p.page * p.pageSize,
            take: p.pageSize
        } as IPagingModel
        onPagingChanged(newPaging)
    }

    const handleOnSortingChanged = (s: GridSortModel) => {
        const newSorting = {
            property: s.at(0).field,
            order: s.at(0).sort === 'asc' ? 'ascending' : 'descending'
        } as ISortingModel
        onSortingChanged(newSorting)
    }

    const handleOnRowClick = (workplaceId: string, contactId: string) => {
        if (workplaceId?.length > 0 && contactId?.length > 0)
            history.push(contactDetailsUrl(workplaceId, contactId))
    }

    return (
        <DataGrid
            columns={columns}
            rows={rowsWithSelectionData}
            rowCount={result?.totalCount ?? 0}
            loading={isSearching}
            getRowId={row => row?.id}
            autoHeight
            disableVirtualization
            disableColumnMenu
            disableRowSelectionOnClick
            onRowClick={({ row }: GridRowParams<IContact>) => handleOnRowClick(row?.workplace.id, row?.id)}
            paginationModel={mapFromPagingModel(paging)}
            paginationMode='server'
            onPaginationModelChange={handleOnPagingChanged}
            pageSizeOptions={DEFAULT_PAGE_SIZES}
            sortModel={mapFromSortingModel(sorting)}
            sortingMode='server'
            onSortModelChange={handleOnSortingChanged}
            sortingOrder={['asc', 'desc']}
            columnVisibilityModel={visibleColumns}
            onColumnVisibilityModelChange={onVisibleColumnsChanged}
            density={density}
            onStateChange={(state) => {
                if (state.density.value !== density)
                    onDensityChanged(state.density.value as GridDensity)
            }}
            slots={{
                noResultsOverlay: () =>
                    <Stack height="100%" alignItems="center" justifyContent="center">
                        <Typography variant='body2'>Inga resultat</Typography>
                    </Stack>,
                noRowsOverlay: () =>
                    <Stack height="100%" alignItems="center" justifyContent="center">
                        <Typography variant='body2'>Inga resultat</Typography>
                    </Stack>,
                toolbar: () => <ContactsTableToolbar
                    areAllSelected={areAllSelected}
                    areOnlySomeSelected={areOnlySomeSelected}
                    isNoneSelected={isNoneSelected}
                    numberOfSelectedContacts={numberOfSelectedContacts}
                    numberOfRows={result?.totalCount ?? 0}
                    isSelected={isSelected}
                    toggleSelectionMode={toggleSelectionMode}
                    apiModel={apiModel}
                />
            }}
            sx={{
                "& div[role=cell]:focus": {
                    outline: "none !important"
                },
                "& div[role=cell]:focus-within": {
                    outline: "none !important"
                }
            }}
            componentsProps={{ panel: { placement: 'bottom-end' } }} // Workaround since the new slot props are broken and reset everything when changing just panel.placement
        />
    )
}

interface ContactsTableToolbarProps {
    areAllSelected: boolean,
    areOnlySomeSelected: boolean,
    isNoneSelected: boolean,
    numberOfSelectedContacts: number
    numberOfRows: number,
    toggleSelectionMode: () => void,
    isSelected: (id: string) => boolean
    apiModel?: IContactsSearchApiModel
}

const ContactsTableToolbar = ({
    areAllSelected,
    areOnlySomeSelected,
    isNoneSelected,
    numberOfSelectedContacts,
    numberOfRows,
    isSelected,
    toggleSelectionMode,
    apiModel
}: ContactsTableToolbarProps) => {
    return (
        <Stack direction='row' alignItems='center' justifyContent='space-between' paddingX={1.25}>
            <Box display='grid' gridTemplateColumns='repeat(2, 1fr)' justifyItems='start' width='30%'>
                <Stack direction='row' alignItems='center'>
                    <Checkbox checked={areAllSelected} indeterminate={areOnlySomeSelected} onChange={() => toggleSelectionMode()} />

                    {isNoneSelected && <Typography variant='body2'>
                        Markera alla {numberOfRows.toLocaleString('sv-SE')}
                    </Typography>}

                    {areOnlySomeSelected && <Typography variant='body2'>
                        {numberOfSelectedContacts.toLocaleString('sv-SE')} av {numberOfRows.toLocaleString('sv-SE')} markerade
                    </Typography>}

                    {areAllSelected && <Typography variant='body2'>
                        Alla {numberOfRows.toLocaleString('sv-SE')} markerade
                    </Typography>}
                </Stack>

                <SendContactsEmail apiModel={apiModel} isContactSelected={isSelected} numberOfContactsSelected={numberOfSelectedContacts} />
            </Box>

            <Stack direction='row'>
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
            </Stack>
        </Stack>
    )
}