import { axiosInstance } from '@local/Utils/network/axios.config'
import {
  createActivityFailure,
  createActivityStarted,
  createActivitySuccess,
  deleteActivityFailure,
  deleteActivityStarted,
  deleteActivitySuccess,
  fetchActivitiesFailure,
  fetchActivitiesStarted,
  fetchActivitiesSuccess,
  fetchDetailedActivityFailure,
  fetchDetailedActivityStarted,
  fetchDetailedActivitySuccess,
  resetActivityTab,
  updateActivityFailure,
  updateActivityStarted,
  updateActivitySuccess,
} from '@local/Scenes/Company/CompanyTabs/Activities/Activities.reducers'
import { AppDispatch } from '@local/Store/configureStore'

import { ActivityModel, NewActivityModel } from './Activities.model'

export const deleteActivity = (workPlaceId: string, activityId: string) => (dispatch: AppDispatch) => {
  dispatch(deleteActivityStarted())
  axiosInstance.delete(`activities/${activityId}`)
    .then(() => {
      dispatch(deleteActivitySuccess())
    })
    .catch(() => {
      dispatch(deleteActivityFailure())
    })
}

export const createActivity = (activity: NewActivityModel) => (dispatch: AppDispatch) => {
  dispatch(createActivityStarted())

  axiosInstance.post('activities', activity)
    .then(() => {
      dispatch(createActivitySuccess())
      dispatch(fetchActivitiesData(activity.workplaceId))
    })
    .catch(errorMessage => {
      dispatch(createActivityFailure(errorMessage as string))
    })
}

export const fetchDetailedActivityData = (activityGuid: string) => (dispatch: AppDispatch) => {
  dispatch(fetchDetailedActivityStarted())
  axiosInstance.get(`activities/${activityGuid}`)
    .then(res => {
      dispatch(fetchDetailedActivitySuccess(res.data as ActivityModel))
    })
    .catch(() => {
      dispatch(fetchDetailedActivityFailure())
    })
}

export const fetchActivitiesData = (companyGuid: string) => (dispatch: AppDispatch) => {
  dispatch(fetchActivitiesStarted())

  axiosInstance.get(`activities?workplaceId=${companyGuid}`)
    .then(res => {
      dispatch(fetchActivitiesSuccess(res.data as ActivityModel[]))
    })
    .catch(() => {
      dispatch(fetchActivitiesFailure())
    })
}

export const resetActivity = () => (dispatch: AppDispatch) => {
  dispatch(resetActivityTab())
}

export const updateActivity = (activity: ActivityModel) => (dispatch: AppDispatch) => {
  dispatch(updateActivityStarted())

  axiosInstance.put(`activities/${activity.activityId}`, activity)
    .then(() => {
      dispatch(updateActivitySuccess())
      dispatch(fetchActivitiesData(activity.workplaceId))
    })
    .catch(errorMessage => {
      dispatch(updateActivityFailure(errorMessage as string))
    })
}
