import { connect } from 'react-redux'
import { createEmail } from '@local/Blocks/CreateEmail/CreateEmail.actions'
import { RootState } from '@local/Store/configureStore'

import { fetchContactsData, deleteContact, resetContacts } from '../../Company.actions'

import Contacts from './Contacts'

const mapStateToProps = (state: RootState) => ({
  contacts: state.company.contacts,
  loaded: state.company.contactsLoaded,
  createEmailState: state.createEmail.createEmailState,
})

const mapDispatchToProps = {
  fetchContactsData,
  deleteContact,
  createEmail,
  resetContacts,
}

export default connect(mapStateToProps, mapDispatchToProps)(Contacts)
