import { ISelectionData } from '@local/src/Components/Inputs/Selection/Common/ISelectionData'
import { IWorkplaceEligibility } from '@local/src/Models/Domain/WorkplaceEligibility.model'
import { IUrlSyncedData } from '@local/src/Utils/helpers/Url/IUrlSyncedData'
import { useUrlSynchedEntityOptionsSelection } from '@local/src/Utils/helpers/Url/useUrlSynchedEntityOptionsSelection'
import { useGetEligibilityStatusesQuery } from '@local/src/Utils/network/endpoints/workplacesApi'

export interface IEligibilityFilterData extends IUrlSyncedData, ISelectionData<IWorkplaceEligibility> {}

export const useEligibilityFilter = (): IEligibilityFilterData =>
  useUrlSynchedEntityOptionsSelection<IWorkplaceEligibility>('eligibilities', useGetEligibilityStatusesQuery)
