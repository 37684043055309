import React from 'react'
import SearchTabs from '@local/Scenes/Home/Tabs/SearchTabs'
import { Route, Switch } from 'react-router-dom'
import { companyUrl, contactUrl, orgUrl, projectUrl } from '@local/src/basename'
import { Box } from '@mui/material'
import Projects from '@local/Scenes/Home/Tabs/Projects/Projects'

import { WorkplaceSearch } from './Tabs/Companies/WorkplaceSearch/WorkplaceSearch'
import { ContactSearch } from './Tabs/Contacts/ContactSearch'

const Home = (): JSX.Element => {
  return (
    <>
      <SearchTabs />
      <Box marginTop={3}>
        <Switch>
          <Route path={[companyUrl, orgUrl]} exact>
            <WorkplaceSearch />
          </Route>
          <Route path={contactUrl} exact>
            <ContactSearch />
          </Route>
          <Route path={projectUrl} exact>
            <Projects />
          </Route>
        </Switch>
      </Box>
    </>
  )
}

export default Home
