import { ISelectionData } from '@local/src/Components/Inputs/Selection/Common/ISelectionData'
import { IContactStatus } from '@local/src/Models/Domain/ContactStatus.model'
import { IUrlSyncedData } from '@local/src/Utils/helpers/Url/IUrlSyncedData'
import { useUrlSynchedEntityOptionsSelection } from '@local/src/Utils/helpers/Url/useUrlSynchedEntityOptionsSelection'
import { useGetContactStatusesQuery } from '@local/src/Utils/network/endpoints/workplacesApi'

export interface IContactStatusFilterData extends IUrlSyncedData, ISelectionData<IContactStatus> {}

export const useContactStatusFilter = (): IContactStatusFilterData =>
  useUrlSynchedEntityOptionsSelection<IContactStatus>('statuses', useGetContactStatusesQuery)
