// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Olt4JaNQuFyprgWY6sWM{cursor:pointer}.Olt4JaNQuFyprgWY6sWM>*:focus{outline:none !important}.Olt4JaNQuFyprgWY6sWM>*:focus-within{outline:none !important}`, "",{"version":3,"sources":["webpack://./src/Components/DataGridWrapper/DataGridWrapper.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CAGE,8BACE,uBAAA,CAGF,qCACE,uBAAA","sourcesContent":[".Clickable {\n  cursor: pointer;\n\n  > * {\n    &:focus {\n      outline: none !important;\n    }\n\n    &:focus-within {\n      outline: none !important;\n    }\n  } \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Clickable": `Olt4JaNQuFyprgWY6sWM`
};
export default ___CSS_LOADER_EXPORT___;
