import { GridColDef, MuiEvent } from '@mui/x-data-grid'
import { tableTooltip } from '@local/src/Utils/helpers/tooltips'
import { formatUtcDate } from '@local/src/Utils/helpers'
import { IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import React from 'react'

import { IProjectList } from './Projects.types'

export const projectsColumns = (
  editFunc: (row: IProjectList, event: MuiEvent<React.MouseEvent<HTMLElement>>) => void,
  deleteFunc: (row: IProjectList, event: MuiEvent<React.MouseEvent<HTMLElement>>) => void
): GridColDef<IProjectList>[] => {
  return [
    {
      headerName: 'Projektnr.',
      field: 'projektnummer',
      renderCell: ({ row }) => tableTooltip(row?.projektnummer),
      flex: 1,
      maxWidth: 100,
    },
    {
      headerName: 'Projektnamn',
      field: 'projektnamn',
      renderCell: ({ row }) => tableTooltip(row?.projektnamn),
      flex: 1,
    },
    {
      headerName: 'Projektägare',
      field: 'projektagare.employeeName',
      valueGetter: ({ row }) => row?.projektagare?.employeeName,
      renderCell: ({ row }) => tableTooltip(row?.projektagare?.employeeName),
      flex: 1,
    },
    {
      headerName: 'Slutdatum',
      field: 'projektPeriod.end',
      valueGetter: ({ row }) => row?.projektPeriod?.end,
      renderCell: ({ row }) => tableTooltip(formatUtcDate(row?.projektPeriod?.end) !== '0001-01-01' ? formatUtcDate(row?.projektPeriod?.end) : ''),
      flex: 1,
      maxWidth: 110,
    },
    {
      headerName: 'Status',
      field: 'projektstatus.projektstatusText',
      valueGetter: ({ row }) => row?.projektstatus?.projektstatusText,
      renderCell: ({ row }) => tableTooltip(row?.projektstatus?.projektstatusText),
      flex: 1,
      maxWidth: 100,
    },
    {
      headerName: 'Omställningskunder',
      field: 'antalKlienter',
      renderCell: ({ row }) => tableTooltip(row?.antalKlienter),
      flex: 1,
      maxWidth: 160,
    },
    {
      headerName: 'Arbetsställen',
      field: 'foretag.antalForetag',
      valueGetter: ({ row }) => row?.foretag?.antalForetag,
      renderCell: ({ row }) => tableTooltip(row?.foretag?.antalForetag),
      flex: 1,
      maxWidth: 110,
    },
    {
      headerName: 'Beskrivning',
      field: 'beskrivning',
      renderCell: ({ row }) => tableTooltip(row?.beskrivning),
      flex: 1,
    },
    {
      headerName: '',
      field: 'Redigera',
      renderCell: ({ row }) => {
        return (
          <>
            { row.canEdit && (
              <>
                <Tooltip title='Redigera'>
                  <IconButton onClick={(event) => editFunc(row, event)} aria-label='Redigera projekt'>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Ta bort'>
                  <IconButton onClick={(event) => deleteFunc(row, event)} aria-label='Ta bort projekt'>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
        )
      },
      flex: 1,
      maxWidth: 100,
      sortable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ]
}