import { ISelectionData } from '@local/src/Components/Inputs/Selection/Common/ISelectionData'
import { ICounty } from '@local/src/Models/Domain/County.model'
import { IUrlSyncedData } from '@local/src/Utils/helpers/Url/IUrlSyncedData'
import { useUrlSynchedEntityOptionsSelection } from '@local/src/Utils/helpers/Url/useUrlSynchedEntityOptionsSelection'
import { useGetCountiesQuery } from '@local/src/Utils/network/endpoints/regionsApi'

export interface ICountyFilterData extends IUrlSyncedData, ISelectionData<ICounty> {}

export const useCountyFilter = (): ICountyFilterData =>
  useUrlSynchedEntityOptionsSelection<ICounty>('counties', useGetCountiesQuery)
