export const formatSwedishNumber = (value: number) => value.toLocaleString('sv-SE')

export const pluralizeWord = (word: string, count: number, suffix: string) => {
  if (count > 1) {
    return word + suffix
  }

  return word
}

const formattedOrganizationalNumberRegexp = /^\d{6}-\d{4}$/
export const formatOrganizationalNumber = (value: string) => {
  if (value.match(formattedOrganizationalNumberRegexp)) return value

  return value.slice(0, 6) + '-' + value.slice(6, 10)
}
