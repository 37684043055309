import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material'
import { ISelectOption } from '@local/src/App.types'

export interface ICreateActivityModal {
  active: boolean
  cancel: () => void
  create: () => void
  activityTypes: ISelectOption[]
  selectedActivity: string
  setSelectedActivity: React.Dispatch<React.SetStateAction<string>>
}

const CreateActivityModal = ({
  active,
  cancel,
  create,
  activityTypes,
  selectedActivity,
  setSelectedActivity
}: ICreateActivityModal): JSX.Element => {

  return(
    <Box sx={{ width: '600px' }}>
      <Dialog open={active}>
        <DialogTitle>{'Skapa ny händelse'}</DialogTitle>
        <DialogContent>
          <Typography component='div' sx={{ marginLeft: 2, marginRight: 2 }}>
            <Box sx={{ fontWeight: 'bold' }}>Anteckning</Box>
            Skapas vid envägskommunikation för att underlätta kundarbetet internt.
          </Typography>

          <Typography component='div' sx={{ marginLeft: 2, marginRight: 2, marginTop: 1 }}>
            <Box sx={{ fontWeight: 'bold'}}>Kontakt</Box>
            Skapas vid tvåvägskommunikation med kund. Till exempel vid fysiska möten, onlinemöten, telefonsamtal eller mejl, oavsett mötets innehåll.
          </Typography>

          <Typography component='div' sx={{ marginLeft: 2, marginRight: 2, marginTop: 1 }}>
            <Box sx={{ fontWeight: 'bold' }}>Insats i omställningsituation</Box>
            Skapas vid olika typer av workshops för kund eller TRR information.
          </Typography>

          <FormControl sx={{ marginTop: 4 }}>
            <InputLabel>Välj en händelse</InputLabel>
            <Select
              sx={{ width: '552px' }}
              required
              defaultValue=''
              value={selectedActivity || ''}
              label="Välj en händelse"
              onChange={(event: SelectChangeEvent<string>) =>
                {setSelectedActivity(event.target.value)}
              }
              >
              { activityTypes
                .map((activityType, index: number) => (
                <MenuItem key={index} value={activityType.value}>{activityType.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button
            variant='text'
            size = 'medium'
            disabled={selectedActivity === '' || selectedActivity === null}
            onClick={create}
          >
            Skapa
          </Button>
          <Button
            variant='text'
            size = 'medium'
            onClick={cancel}
          >
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default CreateActivityModal
