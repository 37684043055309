import { organisationsregistretApi } from '../organisationsregistretApi'

export const contactsApi = organisationsregistretApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteContact: builder.mutation<undefined, { workplaceId: string; contactId: string }>({
      query: ({ workplaceId, contactId }) => ({
        url: `workplaces/${workplaceId}/contacts/${contactId}`,
        method: 'DELETE',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(() =>
            setTimeout(() => {
              dispatch(organisationsregistretApi.util.invalidateTags(['SearchedContacts']))
            }, 1000)
          )
          .catch(() => {})
      },
    }),
    sendEmail: builder.mutation<undefined, ISendEmailModel>({
      query: (apiModel) => ({
        url: 'email',
        method: 'POST',
        body: apiModel,
      }),
    }),
  }),
})

export const { useDeleteContactMutation, useSendEmailMutation } = contactsApi

export interface ISendEmailModel {
  subject: string
  recipients: ISendEmailRecipientModel[]
}

export interface ISendEmailRecipientModel {
  id: string
  emailAddress: string
}
