import { addMinutes } from 'date-fns'

import { ActivityModel, NewActivityModel } from '../Activities.model'

import { IActivityFormValues } from './HandleActivity'

export const formatDateTime = ({
  date,
  startTime,
  duration,
}: {
  date: string
  startTime: string
  duration?: number
}) => {
  const startDateTime = new Date(`${date}T${startTime}:00`)
  if (duration) {
    return new Date(addMinutes(startDateTime, duration))
  }
  return new Date(startDateTime)
}

export const getActivityCreateModel = (activityGuid: string, workplaceId: string, values: IActivityFormValues): NewActivityModel => {
  return {
    activityId: activityGuid,
    attendeesNumber: +values.attendeesNumber,
    category: values.category,
    colleaguesIds: values?.selectedColleagues?.map(sc => sc.value.toString()),
    contactsIds: values?.selectedContacts?.map(sc => sc.value.toString()),
    customerSpecificSeminar: values.customerSpecificSeminar,
    employeesResponsibleIds: values.selectedEmployeesResponsible.map(ser => ser.value.toString()),
    location: values.location,
    processType: values.processType,
    range: {
      start: formatDateTime({
        date: values.date,
        startTime: values.startTime,
      }).toISOString(),
      end: formatDateTime({
        date: values.date,
        startTime: values.startTime,
        duration: values.duration,
      }).toISOString(),
    },
    sendInviteToContact: values.sendInviteToContact,
    status: values.status,
    subject: values.subject,
    subjectMatter: values.subjectMatter,
    text: values.text,
    updateLatestContact: values.processType !== 'Z001',
    workplaceId: workplaceId,
  }
}

export const getActivityUpdateModel = (activity: ActivityModel, workplaceId: string, values: IActivityFormValues): ActivityModel => {
  const activityClone = JSON.parse(JSON.stringify(activity))

  activityClone.category.categoryCode = values.category
  activityClone.range = {
    start: formatDateTime({
      date: values.date,
      startTime: values.startTime,
    }).toISOString(),
    end: formatDateTime({
      date: values.date,
      startTime: values.startTime,
      duration: values.duration,
    }).toISOString(),
  }
  activityClone.attendeesNumber = values.attendeesNumber
  activityClone.colleagues = values?.selectedColleagues?.map(sc => ({
    colleagueId: sc.value.toString(),
    colleagueName: sc.label,
    _employees: null,
  }))
  activityClone.contacts = values?.selectedContacts?.map(sc => ({
    contactId: sc.value.toString(),
    contactName: sc.label,
  }))
  activityClone.employeesResponsible = values.selectedEmployeesResponsible.map(ser => ({
    employeeResponsibleId: ser.value.toString(),
    employeeName: ser.label,
    _employees: null,
  }))
  activityClone.location = values.location
  activityClone.processType.processTypeCode = values.processType
  activityClone.sendInviteToContact = values.sendInviteToContact
  activityClone.status.processTypeCode = values.processType
  activityClone.status.statusCode = values.status
  activityClone.subject = values.subject
  activityClone.subjectMatter.subjectMatterCode = values.subjectMatter
  activityClone.text = values.text
  activityClone.updateLatestContact = values.processType !== 'Z001' && values.processType !== 'Z002'
  activityClone.workplaceId = workplaceId
  activityClone.customerSpecificSeminar = {
    customerSpecificSeminarCode: values.customerSpecificSeminar,
    customerSpecificSeminarDescription: null,
    _seminarCodes: null,
  }

  return activityClone
}
