import { ISelectionData } from '@local/src/Components/Inputs/Selection/Common/ISelectionData'
import { IEntity } from '@local/src/Models/Domain/Entity.model'
import { useUrlSync, mapIEntityArrayToUrl, mapIEntityArrayFromUrl } from '@local/src/Utils/helpers/Url/useUrlSync'
import { IQuery } from '@local/src/Utils/network/organisationsregistretApi'

import { useEntityOptionsSelection } from '../../../Components/Inputs/Selection/Common/useEntityOptionsSelection'

import { IUrlSyncedData } from './IUrlSyncedData'

export const useUrlSynchedEntityOptionsSelection = <T extends IEntity>(
  entityName: string,
  query: () => IQuery<T[]>
): IUrlSyncedData & ISelectionData<T> => {
  const { selection, setSelection, options, isLoading, isError } = useEntityOptionsSelection<T>(query)
  const { wasLoadedFromUrl, updateValue } = useUrlSync<T[]>(
    entityName,
    setSelection,
    mapIEntityArrayToUrl,
    (s) => mapIEntityArrayFromUrl(s, options),
    [options]
  )

  return {
    selection,
    setSelection: updateValue,
    options,
    isLoading,
    isError,
    wasLoadedFromUrl,
  }
}
