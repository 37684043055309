import React from 'react'
import EmailIcon from '@mui/icons-material/Email'
import { IconButton } from '@mui/material'

export interface CreateEmailButtonProps {
  onClick: () => void
}

const CreateEmailButton = ({
  onClick,
}: CreateEmailButtonProps) => (
  <IconButton onClick={onClick} aria-label='skapa gruppmejl'>
    <EmailIcon />
  </IconButton>
)

export default CreateEmailButton
