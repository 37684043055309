import { useState } from 'react'

import { isNotNullOrEmpty } from '../Utils/helpers'

const useLocalStorage = <T>(key: string, initialValue: T): readonly [T, (val: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const lsValue = window.localStorage.getItem(key)
      const parsedLsValue: T = JSON.parse(lsValue)

      if (isNotNullOrEmpty(lsValue)) return parsedLsValue
      else return initialValue
    } catch {
      return initialValue
    }
  })

  const setValue = (value: T) => {
    try {
      setStoredValue(value)
      window.localStorage.setItem(key, JSON.stringify(value))
    } catch {
      console.log('Cant set { value: ' + JSON.stringify(value) + ', key: ' + key + ' }')
    }
  }

  return [storedValue, setValue] //as const
}

export { useLocalStorage }