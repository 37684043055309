import { axiosInstance } from '@local/Utils/network/axios.config'
import { AppDispatch } from '@local/Store/configureStore'
import {
  setCreateEmailFailure,
  setCreateEmailInitiate,
  setCreateEmailSuccess,
} from '@local/Blocks/CreateEmail/CreateEmail.reducer'

import { ICreateEmailRecipients } from './CreateEmail.types'

export const createEmail = (subject: string, recipients: ICreateEmailRecipients[]) => (dispatch: AppDispatch) => {
  dispatch(setCreateEmailInitiate())

  const body = {
    subject,
    recipients,
  }

  axiosInstance.post('email', body)
    .then(() => {
      dispatch(setCreateEmailSuccess())
    })
    .catch(() => {
      dispatch(setCreateEmailFailure())
    })
}
