import { IWorkplaceSearchFilterData, useWorkplaceSearchFilters } from './Filters/useWorkplaceSearchFilters'
import { IWorkplaceSearchPagingData, useWorkplaceSearchPaging } from './Paging/useWorkplaceSearchPaging'
import { IWorkplaceSearchQueryData, useWorkplaceSearchQuery } from './Query/useWorkplaceSearchQuery'
import { IWorkplaceSearchSortingData, useWorkplaceSearchSorting } from './Sorting/useWorkplaceSearchSorting'

export interface IWorkplaceSearchData {
  filterData: IWorkplaceSearchFilterData
  pagingData: IWorkplaceSearchPagingData
  sortingData: IWorkplaceSearchSortingData
  queryData: IWorkplaceSearchQueryData
}

export const useWorkplaceSearch = (): IWorkplaceSearchData => {
  const filterData = useWorkplaceSearchFilters()
  const pagingData = useWorkplaceSearchPaging()
  const sortingData = useWorkplaceSearchSorting()
  const queryData = useWorkplaceSearchQuery()

  return {
    filterData,
    pagingData,
    sortingData,
    queryData,
  }
}
