import { ISelectionData } from '@local/src/Components/Inputs/Selection/Common/ISelectionData'
import { useGetMunicipalitiesQuery } from '@local/src/Utils/network/endpoints/regionsApi'
import { IMunicipality } from '@local/src/Models/Domain/Municipality.model'
import { ICounty } from '@local/src/Models/Domain/County.model'
import { IUrlSyncedData } from '@local/src/Utils/helpers/Url/IUrlSyncedData'
import { useUrlSynchedEntityOptionsSelection } from '@local/src/Utils/helpers/Url/useUrlSynchedEntityOptionsSelection'

export interface IMunicipalityFilterData extends IUrlSyncedData, ISelectionData<IMunicipality> {}

export const useMunicipalityFilter = (selectedCounties: ICounty[]): IMunicipalityFilterData => {
  const { selection, setSelection, options, isLoading, isError, wasLoadedFromUrl } =
    useUrlSynchedEntityOptionsSelection<IMunicipality>('municipalities', useGetMunicipalitiesQuery)

  const filteredOptions =
    selectedCounties.length === 0
      ? options
      : options.filter((o) => selectedCounties.map((c) => c.id).includes(o.countyId))

  return {
    selection,
    setSelection,
    options: filteredOptions,
    isLoading,
    isError,
    wasLoadedFromUrl,
  }
}
