import React from 'react'
import { IRange } from '@local/src/Models/Misc/Range.model'

import { RangeInput } from './Common/RangeInput'
import { IRangeData } from './Common/IRangeData'

interface Props extends IRangeData {
    disabled?: boolean
}


const PAYROLL_DISPLAY_FACTOR = 1000000

export const PayrollRangeInput = React.memo((props: Props) => {
    const { value, bounds, setValue } = props
    const displayValue = { from: value.from / PAYROLL_DISPLAY_FACTOR, to: value.to / PAYROLL_DISPLAY_FACTOR } as IRange
    const displayBounds = { from: bounds.from / PAYROLL_DISPLAY_FACTOR, to: bounds.to / PAYROLL_DISPLAY_FACTOR }

    const handleOnChange = (newValue: IRange) => {
        setValue({ from: newValue.from * PAYROLL_DISPLAY_FACTOR, to: newValue.to * PAYROLL_DISPLAY_FACTOR })
    }

    return <RangeInput
        {...props}
        value={displayValue}
        bounds={displayBounds}
        setValue={handleOnChange}
        label='Lönesumma'
        unit='MSEK'
    />
})