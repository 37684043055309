import { orgBasepath } from '../basename'

import { RouteType } from './UI.types'

const staticRoutes: RouteType[] = [
  {
    path: `${orgBasepath}`,
    pagetype: 'Home',
  },
  {
    path: `${orgBasepath}/foretag`,
    pagetype: 'Home',
  },
  {
    path: `${orgBasepath}/kontakt`,
    pagetype: 'Home',
  },
  {
    path: `${orgBasepath}/projekt`,
    pagetype: 'Home',
  },
  {
    path: `${orgBasepath}/foretag/detaljer/:companyGuid`,
    pagetype: 'Company',
  },
  {
    path: `${orgBasepath}/foretag/detaljer/:companyGuid/redigera`,
    pagetype: 'Company',
  },
  {
    path: `${orgBasepath}/foretag/detaljer/:companyGuid/kontakt/:contactGuid`,
    pagetype: 'Company',
  },
  {
    path: `${orgBasepath}/foretag/detaljer/:companyGuid/kontakt/:contactGuid/redigera`,
    pagetype: 'Company',
  },
  {
    path: `${orgBasepath}/foretag/detaljer/:companyGuid/handelser/`,
    pagetype: 'Company',
  },
  {
    path: `${orgBasepath}/foretag/detaljer/:companyGuid/kontakt/`,
    pagetype: 'Company',
  },
  {
    path: `${orgBasepath}/foretag/detaljer/:companyGuid/projekt/`,
    pagetype: 'Company',
  },
  {
    path: `${orgBasepath}/foretag/detaljer/:companyGuid/projekt/skapa`,
    pagetype: 'Company',
  },
  {
    path: `${orgBasepath}/foretag/detaljer/:companyGuid/projekt/:projectGuid`,
    pagetype: 'Company',
  },
  {
    path: `${orgBasepath}/foretag/detaljer/:companyGuid/projekt/:projectGuid/redigera`,
    pagetype: 'Company',
  },
  {
    path: `${orgBasepath}/foretag/detaljer/:companyGuid/koncern/`,
    pagetype: 'Company',
  },
  {
    path: `${orgBasepath}/foretag/detaljer/:companyGuid/handelser/:activityGuid`,
    pagetype: 'Company',
  },
  {
    path: `${orgBasepath}/foretag/detaljer/:companyGuid/handelser/:activityGuid/redigera`,
    pagetype: 'Company',
  },
  {
    path: `${orgBasepath}/foretag/detaljer/:companyGuid/handelser/:activityGuid/no-edit`,
    pagetype: 'Company',
  },
  {
    path: `${orgBasepath}/foretag/detaljer/:companyGuid/handelser/skapa`,
    pagetype: 'Company',
  },
]

export default staticRoutes
