import { contactDetailsEditUrl } from '@local/src/basename'
import Loading from '@local/src/Components/Loading/Loading'
import { IContact } from '@local/src/Models/Domain/Contact.model'
import { formatCompanyTypeName } from '@local/src/Utils/helpers'
import { tableTooltip } from '@local/src/Utils/helpers/tooltips'
import { useDeleteContactMutation } from '@local/src/Utils/network/endpoints/contactsApi'
import { Delete, Edit } from '@mui/icons-material'
import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, Stack, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { formatOrganizationalNumber } from '@local/src/Utils/helpers/formatting'

import { nameColumn } from './NameColumn'
import { workplaceNameColumn } from './WorkplaceNameColumn'

export const DefaultMyContactsColumns = { "Fackforbund": false, "ForetagsStatus": false, "Arbetsstalle_NationellKontakt": false, "Arbetsstalle_Organisationsnummer": false, "Arbetsstalle_Kontorstyp": false, "Arbetsstalle_Lan": false, "Arbetsstalle_Kommun": false }
export const DefaultContactsColumns = { "Mobilnummer": false, "RollNiva1": false, "Fackforbund": false, "Arbetsstalle_Kontorstyp": false, "Arbetsstalle_Lan": false, "SenastAndrad": false, "Arbetsstalle_NationellKontakt": false }

const selectionColumn: GridColDef<IContact & { isSelected: boolean; onChange: (id: string) => void }> = {
    headerName: 'Val',
    field: 'selection',
    hideable: false,
    sortable: false,
    renderHeader: () => <></>,
    renderCell: ({ row }) => <Checkbox checked={row?.isSelected} onClick={(e) => {
        e.stopPropagation()
        row?.onChange(row?.id)
    }} />,
    width: 45
}

const emailColumn: GridColDef<IContact> = {
    headerName: 'E-post',
    field: 'Epost',
    valueGetter: ({ row }) => row?.email,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1
}

const phoneColumn: GridColDef<IContact> = {
    headerName: 'Mobil',
    field: 'Mobilnummer',
    valueGetter: ({ row }) => row?.mobileNumber,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    maxWidth: 140,
    flex: 1
}

const categoryColumn: GridColDef<IContact> = {
    headerName: 'Kategori',
    field: 'RollNiva1',
    valueGetter: ({ row }) => row?.category?.description,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    maxWidth: 80,
    flex: 1
}

const roleColumn: GridColDef<IContact> = {
    headerName: 'Roll',
    field: 'RollNiva2',
    valueGetter: ({ row }) => row?.role?.description,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 0.75
}

const unionColumn: GridColDef<IContact> = {
    headerName: 'Fackförbund',
    field: 'Fackforbund',
    valueGetter: ({ row }) => row?.union?.name,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1
}

const statusColumn: GridColDef<IContact> = {
    headerName: 'Status',
    field: 'ForetagsStatus',
    valueGetter: ({ row }) => row?.status?.description,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1
}

const organizationalNumberColumn: GridColDef<IContact> = {
    headerName: 'Org.nr',
    field: 'Arbetsstalle_Organisationsnummer',
    valueGetter: ({ row }) => formatOrganizationalNumber(row?.workplace.organizationalNumber),
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    width: 105
}

const workplaceTypeColumn: GridColDef<IContact> = {
    headerName: 'HK/AP',
    field: 'Arbetsstalle_Kontorstyp',
    valueGetter: ({ row }) => row?.workplace?.type?.name ?? '-',
    valueFormatter: ({ value }) => formatCompanyTypeName(value as string),
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    width: 70,
    sortingOrder: ['desc', 'asc']
}

const eligibilityColumn: GridColDef<IContact> = {
    headerName: 'Kund',
    field: 'Arbetsstalle_Omfattningsstatus',
    valueGetter: ({ row }) => row?.workplace.eligibility.description,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1,
    maxWidth: 60,
}

const countyColumn: GridColDef<IContact> = {
    headerName: 'Län',
    field: 'Arbetsstalle_Lan',
    valueGetter: ({ row }) => row?.workplace.county?.name,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1,
}

const municipalityColumn: GridColDef<IContact> = {
    headerName: 'Kommun',
    field: 'Arbetsstalle_Kommun',
    valueGetter: ({ row }) => row?.workplace.municipality?.name,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 0.75,
}

const trrIndustryColumn: GridColDef<IContact> = {
    headerName: 'Huvudbransch',
    field: 'Arbetsstalle_Bransch',
    valueGetter: ({ row }) => row?.workplace.trrIndustries?.find((ti) => ti.isMain)?.description,
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    flex: 1,
}

const lastUpdatedColumn: GridColDef<IContact> = {
    headerName: 'Uppdaterad',
    field: 'SenastAndrad',
    sortingOrder: ['desc', 'asc'],
    valueGetter: ({ row }) => row?.lastUpdated,
    valueFormatter: ({ value }) => value?.replace('0001-01-01T00:00:00+00:00', '').substring(0, 10) || '',
    renderCell: ({ formattedValue }) => tableTooltip(formattedValue as string),
    width: 100,
}

const actionsColumn: GridColDef<IContact> = {
    headerName: 'Åtgärder',
    field: 'actions',
    sortable: false,
    hideable: false,
    renderHeader: () => <></>,
    renderCell: ({ row }) => (<Stack direction='row' alignItems='center'>
        <Link href={contactDetailsEditUrl(row?.workplace.id, row?.id)} onClick={e => e.stopPropagation()} alignItems='center' display='flex'>
            <Edit fontSize='small' color='primary' />
        </Link>
        <DeleteContactModal contactName={row?.name} workplaceId={row?.workplace.id} contactId={row?.id} />
    </Stack>),
    width: 90
}


interface DeleteContactModalProps {
    contactName: string
    workplaceId: string,
    contactId: string
}
const DeleteContactModal = React.memo(({ contactName, workplaceId, contactId }: DeleteContactModalProps) => {
    const [deleteContact, { isLoading, isError, isSuccess }] = useDeleteContactMutation()
    const [isShowing, setIsShowing] = useState<boolean>(false)

    useEffect(() => {
        if (isSuccess)
            handleOnClose()
    }, [isSuccess])

    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        setIsShowing(true)
    }

    const handleOnDelete = () => {
        void deleteContact({ workplaceId, contactId })
    }

    const handleOnClose = () => setIsShowing(false)

    return (<>
        <IconButton onClick={handleOnClick}>
            <Delete fontSize='small' color='primary' />
        </IconButton>

        <Dialog open={isShowing} onClose={handleOnClose}>
            <DialogTitle>{`Bekräfta borttagning av '${contactName}'`}</DialogTitle>
            <DialogContent>
                {isError && <Alert severity='error'>Kunde inte ta bort kontaktperson - vänligen försök igen</Alert>}
                <Typography whiteSpace='preserve-breaks'>
                    {`Skapade händelser finns kvar när en kontaktperson tas bort. Däremot försvinner personens namn ifrån händelsen.
                    
                    Är du säker på att du vill ta bort denna kontaktperson?`}
                </Typography>
            </DialogContent>
            <DialogActions>
                {isLoading && <Loading />}
                <Button variant='text' onClick={handleOnDelete} disabled={isLoading}>OK</Button>
                <Button variant='text' onClick={handleOnClose} disabled={isLoading}>Avbryt</Button>
            </DialogActions>
        </Dialog>
    </>
    )
})

export const AllContactsColumns: GridColDef<IContact>[] = [
    selectionColumn,
    nameColumn,
    emailColumn,
    phoneColumn,
    categoryColumn,
    roleColumn,
    unionColumn,
    statusColumn,
    organizationalNumberColumn,
    workplaceNameColumn,
    workplaceTypeColumn,
    eligibilityColumn,
    countyColumn,
    municipalityColumn,
    trrIndustryColumn,
    lastUpdatedColumn
]

export const MyContactsColumns: GridColDef<IContact>[] = [
    selectionColumn,
    nameColumn,
    emailColumn,
    phoneColumn,
    categoryColumn,
    roleColumn,
    unionColumn,
    statusColumn,
    organizationalNumberColumn,
    workplaceNameColumn,
    workplaceTypeColumn,
    eligibilityColumn,
    countyColumn,
    municipalityColumn,
    trrIndustryColumn,
    lastUpdatedColumn,
    actionsColumn
]