import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { activityCreateUrl, activityDetailsUrl, activityEditUrl } from '@local/src/basename'
import { GridEventListener, GridRowParams, MuiEvent } from '@mui/x-data-grid'
import { Box, Button, Stack, Typography } from '@mui/material'
import DataGridWrapper from '@local/src/Components/DataGridWrapper/DataGridWrapper'
import { RootState, useAppDispatch } from '@local/Store/configureStore'
import { deleteActivity, fetchActivitiesData } from '@local/Scenes/Company/CompanyTabs/Activities/Activities.actions'
import { useSelector } from 'react-redux'
import Loading from '@local/src/Components/Loading/Loading'
import { useGetActivityProcessTypesQuery } from '@local/src/Utils/network/endpoints/activitiesApi'

import ConfirmationModal from '../../../../Components/ConfirmationModal/ConfirmationModal'

import { ActivityModel } from './Activities.model'
import CreateActivityModal from './CreateActivityModal/CreateActivityModal'
import { activitiesColumns } from './Activities.columns'

export interface IActivities {
  loaded?: boolean
  activities?: ActivityModel[]
  quantity: number
}

const Activities = ({ loaded, activities, quantity }: IActivities) => {
  const dispatch = useAppDispatch()

  const [selectedActivity, setSelectedActivity] = useState('')
  const [isShowingModal, setIsShowingModal] = useState(false)
  const [activityIdToBeDeleted, setActivityIdToBeDeleted] = useState<string>(null)
  const [displayCreateActivityModal, setDisplayCreateActivityModal] = useState(false)
  const history = useHistory()
  const { companyGuid } = useParams<{ companyGuid: string }>()
  const deleteInProgress = useSelector((state: RootState) => state.activity.deleteInProgress)

  const { data: activityTypes, isLoading: isLoadingActivityTypes } = useGetActivityProcessTypesQuery()

  useEffect(() => {
    dispatch(fetchActivitiesData(companyGuid))
  }, [companyGuid, dispatch])

  const prevSaveInProgress = useRef(deleteInProgress)

  useEffect(() => {
    const finishedDeleting = prevSaveInProgress.current && deleteInProgress === false
    if (finishedDeleting) {
      dispatch(fetchActivitiesData(companyGuid))
    }

    prevSaveInProgress.current = deleteInProgress
  }, [companyGuid, deleteInProgress, dispatch])

  const handleDeleteActivity = () => {
    setIsShowingModal(false)
    dispatch(deleteActivity(companyGuid, activityIdToBeDeleted))
  }

  const handleCreateActivity = () => {
    const activity = activityTypes.find(activityType => activityType.value === selectedActivity)
    const queryParams = new URLSearchParams()
    queryParams.set('processTypeCode', activity.processTypeCode)
    queryParams.set('processTypeDescription', activity.processTypeDescription)

    const search = queryParams.toString()

    history.push({ pathname: activityCreateUrl(companyGuid), search: `${search}` })
  }

  const cancelActivityCreation = () => {
    setDisplayCreateActivityModal(false)
    setSelectedActivity(null)
  }

  const editActivity = (activity: ActivityModel, event: MuiEvent<React.MouseEvent<HTMLElement>>) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    history.push(activityEditUrl(companyGuid, activity.activityId))
  }

  const handleDisplayActivityDetails: GridEventListener<'rowClick'> = (params: GridRowParams<ActivityModel>) => {
    params.row && history.push(activityDetailsUrl(companyGuid, params.row.activityId))
  }

  const handleDisplayCreateActivityModal = () => {
    setDisplayCreateActivityModal(true)
  }

  const showModal = (activity: ActivityModel, event: MuiEvent<React.MouseEvent<HTMLElement>>) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    setIsShowingModal(true)
    setActivityIdToBeDeleted(activity.activityId)
  }

  const cancelDelete = () => {
    setIsShowingModal(false)
    setActivityIdToBeDeleted(null)
  }

  if (!loaded || isLoadingActivityTypes) return <Loading />

  return (
    <Stack spacing={2}>
      <Box display='flex' justifyContent='space-between' flexDirection='row' alignItems='flex-end'>
        <Typography variant="subtitle1">
          {quantity} {quantity === 1 ? 'händelse' : 'händelser'}
        </Typography>
        <Button size="large" onClick={handleDisplayCreateActivityModal}>
          Skapa ny händelse
        </Button>
      </Box>

      <DataGridWrapper
        rows={activities}
        columns={activitiesColumns(editActivity, showModal)}
        getRowId={(row: ActivityModel) => row.activityNumber}
        labelRowsPerPage='Händelser per sida'
        onRowClick={handleDisplayActivityDetails}
      />

      {displayCreateActivityModal && (
        <CreateActivityModal
          active={displayCreateActivityModal}
          cancel={cancelActivityCreation}
          create={handleCreateActivity}
          activityTypes={activityTypes}
          selectedActivity={selectedActivity}
          setSelectedActivity={setSelectedActivity}
        />
      )}

      <ConfirmationModal
        active={isShowingModal}
        close={cancelDelete}
        submit={handleDeleteActivity}
        message='Är du säker på att du vill ta bort denna händelse?'
        title='Bekräfta borttagning'
      />
    </Stack>
  )
}

export default Activities
