import React from "react"
import { IWorkplaceManager } from "@local/src/Models/Domain/WorkplaceManager.model"

import { ISelectionData } from "./Common/ISelectionData"
import { SelectionInput } from "./Common/SelectionInput"

interface Props extends ISelectionData<IWorkplaceManager> {
    disabled?: boolean
}

export const LocalContactSelectionInput = React.memo((props: Props) => {
    return <SelectionInput
        {...props}
        name="Lokal kontakt"
        getDisplayValue={o => o.fullName}
    />
})