import { axiosInstance } from '@local/Utils/network/axios.config'
import { encodeRFC5987ValueChars, trimPathInformation, fileNameValid } from '@local/Utils/helpers'
import {
  createProjectFailed,
  createProjectStarted,
  createProjectSuccess,
  deleteAttachmentFailure,
  deleteAttachmentStarted,
  deleteAttachmentSuccess,
  deleteCaseFromProjectFailure,
  deleteCaseFromProjectStarted,
  deleteCaseFromProjectSuccess,
  deleteFailure,
  deleteStarted,
  deleteSucceeded,
  downloadFailure,
  downloadStarted,
  downloadSuccess,
  fetchCasesByProjectIdFailure,
  fetchCasesByProjectIdStarted,
  fetchCasesByProjectIdSuccess,
  fetchProjectAttachmentFailure,
  fetchProjectAttachmentSuccess,
  fetchProjectByIdFailure,
  fetchProjectByIdStarted,
  fetchProjectByIdSuccess,
  fetchProjectsByWorkplaceFailure,
  fetchProjectsByWorkplaceStarted,
  fetchProjectsByWorkplaceSuccess,
  fetchProjectStatusesStarted,
  fetchProjectStatusesSuccess,
  linkCasesFailure,
  linkCasesStarted,
  linkCasesSuccess,
  updateProjectFailure,
  updateProjectStarted,
  updateProjectSuccess,
  uploadProjectAttachmentFailure,
  uploadProjectAttachmentInitiate,
  uploadProjectAttachmentSuccess,
  uploadProjectAttachmentValidationError,
} from '@local/Scenes/Company/CompanyTabs/Projects/Projects.reducers'
import { AppDispatch } from '@local/Store/configureStore'

import { IProject, IProjectList, IProjectAttachment, IProjectStatusSelectOption, ICreateProject } from './Projects.types'
import { ICase } from './Case.types'

export const createNewProject = (project: ICreateProject) => (dispatch: AppDispatch) => {
  dispatch(createProjectStarted())
  axiosInstance.post('projects', project)
    .then(() => {
      dispatch(createProjectSuccess())
    })
    .catch(err => {
      dispatch(createProjectFailed(err.response.data as string))
    })
}

export const getProjectStatuses = () => (dispatch: AppDispatch) => {
  dispatch(fetchProjectStatusesStarted())
  axiosInstance.get('projects/projectStatuses')
    .then(res => {
      const mapped = res.data.map((result: IProjectStatusSelectOption) => ({
        value: result.projektstatusId,
        label: result.projektstatusText,
        projektstatusId: result.projektstatusId,
        projektstatusText: result.projektstatusText,
      })) as IProjectStatusSelectOption[]
      dispatch(fetchProjectStatusesSuccess(mapped))
    })
    .catch(err => {
      console.error(err)
    })
}

export const getProjectsByWorkplaceId = (workplaceId: string) => (dispatch: AppDispatch) => {
  dispatch(fetchProjectsByWorkplaceStarted())
  axiosInstance.get(`workplaces/${workplaceId}/projects`)
    .then(res => dispatch(fetchProjectsByWorkplaceSuccess(res.data as IProjectList[])))
    .catch(() => {
      dispatch(fetchProjectsByWorkplaceFailure())
    })
}

export const getProjectById = (workplaceId: string, projectId: string) => (dispatch: AppDispatch) => {
  dispatch(fetchProjectByIdStarted())
  dispatch(getAttachmentByProjectId(workplaceId, projectId))
  dispatch(getCasesByProjectId(workplaceId, projectId))

  axiosInstance.get(`workplaces/${workplaceId}/projects/${projectId}`)
    .then(res => {
      dispatch(fetchProjectByIdSuccess(res.data as IProject))
    })
    .catch(() => {
      dispatch(fetchProjectByIdFailure())
    })
}

export const updateProject = (workplaceId: string, projectId: string, project: IProject) => (dispatch: AppDispatch) => {
  dispatch(updateProjectStarted())
  axiosInstance.put(`workplaces/${workplaceId}/projects/${projectId}`, project)
    .then(() => {
      dispatch(updateProjectSuccess())
    })
    .catch(err => {
      dispatch(updateProjectFailure(err.message as string))
    })
}

export const deleteProject = (workplaceId: string, projectId: string) => (dispatch: AppDispatch) => {
  dispatch(deleteStarted())
  axiosInstance.delete(`workplaces/${workplaceId}/projects/${projectId}`)
    .then(() => {
      dispatch(deleteSucceeded())
      dispatch(getProjectsByWorkplaceId(workplaceId))
    })
    .catch(() => {
      dispatch(deleteFailure())
    })
}

export const getAttachmentByProjectId = (workplaceId: string, projectId: string) => (dispatch: AppDispatch) => {
  axiosInstance.get(`workplaces/${workplaceId}/projects/${projectId}/attachments`)
    .then(res => {
      dispatch(fetchProjectAttachmentSuccess(res.data as IProjectAttachment[]))
    })
    .catch(() => {
      dispatch(fetchProjectAttachmentFailure())
    })
}

export const fileUpload = (workplaceId: string, projectId: string, file: File) => (dispatch: AppDispatch) => {
  dispatch(uploadProjectAttachmentInitiate())
  const formData = new FormData()
  const trimmedFileName = trimPathInformation(file.name)
  if (fileNameValid(trimmedFileName)) {
    formData.append('file', file, encodeRFC5987ValueChars(trimmedFileName))
    axiosInstance.post(`workplaces/${workplaceId}/projects/${projectId}/attachments`, formData)
      .then(() => {
        dispatch(uploadProjectAttachmentSuccess())
        dispatch(getAttachmentByProjectId(workplaceId, projectId))
      })
      .catch(() => {
        dispatch(uploadProjectAttachmentFailure())
      })
  } else {
    dispatch(uploadProjectAttachmentValidationError())
  }
}

export const deleteAttachment = (workplaceId: string, projectId: string, fileId: string) => (dispatch: AppDispatch) => {
  dispatch(deleteAttachmentStarted())
  axiosInstance.delete(`workplaces/${workplaceId}/projects/${projectId}/attachments/${fileId}`)
    .then(() => {
      dispatch(deleteAttachmentSuccess(fileId))
    })
    .catch(() => {
      dispatch(deleteAttachmentFailure())
    })
}

export const downloadAttachment = (workplaceId: string, projectId: string, fileId: string, fileName: string) => (
  dispatch: AppDispatch
) => {
  dispatch(downloadStarted())
  axiosInstance({
    responseType: 'blob',
    method: 'GET',
    url: `workplaces/${workplaceId}/projects/${projectId}/attachments/${fileId}`,
  })
    .then(res => {
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data as BlobPart]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      dispatch(downloadSuccess())
    })
    .catch(() => {
      dispatch(downloadFailure())
    })
}

export const getCasesByProjectId = (workplaceId: string, projectId: string) => (dispatch: AppDispatch) => {
  dispatch(fetchCasesByProjectIdStarted())
  axiosInstance.get(`workplaces/${workplaceId}/projects/${projectId}/cases`)
    .then(res => {
      dispatch(fetchCasesByProjectIdSuccess(res.data as { cases: ICase[]; canEdit: boolean }))
    })
    .catch(() => {
      dispatch(fetchCasesByProjectIdFailure())
    })
}

export const linkProjectCasesByProjectId = (workplaceId: string, projectId: string) => (dispatch: AppDispatch) => {
  dispatch(linkCasesStarted())
  axiosInstance.put(`workplaces/${workplaceId}/projects/${projectId}/linkCases`)
    .then(() => {
      dispatch(linkCasesSuccess())
      dispatch(getCasesByProjectId(workplaceId, projectId))
    })
    .catch(() => {
      dispatch(linkCasesFailure())
    })
}

export const deleteCaseFromProject = (workplaceId: string, projectId: string, caseId: string) => (
  dispatch: AppDispatch
) => {
  dispatch(deleteCaseFromProjectStarted(caseId))
  axiosInstance.put(`workplaces/workplaces/${workplaceId}/projects/${projectId}/case/${caseId}`)
    .then(() => {
      dispatch(deleteCaseFromProjectSuccess(caseId))
    })
    .catch(() => {
      dispatch(deleteCaseFromProjectFailure())
    })
}
