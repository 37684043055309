import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ActivityModel } from './Activities.model'

export interface ActivitiesStore {
  activities: ActivityModel[] | null
  activitiesLoaded: boolean
  deleteInProgress: boolean
  detailedActivity: ActivityModel
  loadFailed: boolean
  createOrUpdateActivityStarted?: boolean
  createActivityInProgress: boolean
  updateActivityInProgress: boolean
  createOrUpdateActivityFailed?: boolean
  createOrUpdateActivityErrorMessage?: string
}

export const initialState: ActivitiesStore = {
  activities: [],
  activitiesLoaded: false,
  deleteInProgress: false,
  createActivityInProgress: false,
  updateActivityInProgress: false,
  detailedActivity: null,
  loadFailed: false,
}

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    resetActivityTab: state => {
      state.activitiesLoaded = false
      state.detailedActivity = null
      state.loadFailed = false
    },
    fetchActivitiesStarted: state => {
      state.activitiesLoaded = false
    },
    fetchActivitiesSuccess: (state, action: PayloadAction<ActivityModel[]>) => {
      state.activitiesLoaded = true
      state.activities = action.payload
    },
    fetchActivitiesFailure: state => {
      state.loadFailed = true
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fetchDetailedActivityStarted: () => {},
    fetchDetailedActivitySuccess: (state, action: PayloadAction<ActivityModel>) => {
      state.detailedActivity = action.payload
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fetchDetailedActivityFailure: () => {},
    deleteActivityStarted: state => {
      state.deleteInProgress = true
    },
    deleteActivitySuccess: state => {
      state.deleteInProgress = false
    },
    deleteActivityFailure: state => {
      state.deleteInProgress = false
    },
    createActivityStarted: state => {
      state.createOrUpdateActivityStarted = true
      state.createActivityInProgress = true
    },
    createActivitySuccess: state => {
      state.detailedActivity = {}
      state.createOrUpdateActivityStarted = false
      state.createActivityInProgress = false
    },
    createActivityFailure: (state, action: PayloadAction<string>) => {
      state.createOrUpdateActivityFailed = true
      state.createOrUpdateActivityStarted = false
      state.createOrUpdateActivityErrorMessage = action.payload
      state.createActivityInProgress = false
    },
    updateActivityStarted: state => {
      state.createOrUpdateActivityStarted = true
      state.updateActivityInProgress = true
    },
    updateActivitySuccess: state => {
      state.createOrUpdateActivityStarted = false
      state.updateActivityInProgress = false
    },
    updateActivityFailure: (state, action: PayloadAction<string>) => {
      state.createOrUpdateActivityFailed = true
      state.createOrUpdateActivityStarted = false
      state.createOrUpdateActivityErrorMessage = action.payload
      state.updateActivityInProgress = false
    },
  },
})

export const { actions, reducer: activityReducer } = activitySlice
export const {
  fetchActivitiesStarted,
  createActivityStarted,
  deleteActivityStarted,
  createActivitySuccess,
  deleteActivityFailure,
  deleteActivitySuccess,
  fetchActivitiesSuccess,
  fetchActivitiesFailure,
  fetchDetailedActivityStarted,
  fetchDetailedActivitySuccess,
  fetchDetailedActivityFailure,
  updateActivityFailure,
  updateActivityStarted,
  updateActivitySuccess,
  createActivityFailure,
  resetActivityTab,
} = activitySlice.actions
export default activityReducer
