import { Alert, Stack, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { MAX_NUMBER_OF_WORKPLACE_SEARCH_RESULTS } from '@local/src/Utils/network/endpoints/ContactsSearch/contactsSearchApi'
import { GridDensity } from '@mui/x-data-grid'
import { useLocalStorage } from '@local/src/hooks/useLocalStorage'
import { IPagingModel } from '@local/src/Models/Misc/Paging.model'
import { ISortingModel } from '@local/src/Models/Misc/Sorting.model'


import { IContactSearchQueryData } from '../Hooks/Query/useContactSearchQuery'
import { AllContactsColumns, DefaultContactsColumns } from '../Columns/contactSearchColumns'

import { ContactsTable } from './Table/ContactsTable'


interface Props {
    paging: IPagingModel
    sorting: ISortingModel
    queryData: IContactSearchQueryData
    onPagingChanged: (paging: IPagingModel) => void
    onSortingChanged: (sorting: ISortingModel) => void
}

export const ContactSearchResult = ({ paging, sorting, queryData, onPagingChanged, onSortingChanged }: Props) => {
    const { result, isSearching, didSearchFail } = queryData

    const [visibleColumns, setVisibleColumns] = useLocalStorage('contact-search-visible-columns', DefaultContactsColumns)
    const [density, setDensity] = useLocalStorage('contact-search-density', 'standard' as GridDensity)

    const maxResultsReached = result?.totalCount >= MAX_NUMBER_OF_WORKPLACE_SEARCH_RESULTS

    return (
        <Stack direction='column' spacing={2}>
            <Typography variant='h4'>Sökresultat {result?.totalCount >= 0 ? `(${result.totalCount.toLocaleString('sv-SE')})` : ''}</Typography>
            {maxResultsReached && <Alert severity='warning'>Maximalt antal sökresultat uppnått. Förfina sökningen om du vill vara säker på att få med alla relevanta resultat.</Alert>}
            {didSearchFail && <Alert severity='error'>Kunde inte hämta kontaktpersoner - vänligen försök igen</Alert>}
            {useMemo(() => <ContactsTable
                result={result}
                columns={AllContactsColumns}
                isSearching={isSearching}
                paging={paging}
                onPagingChanged={onPagingChanged}
                sorting={sorting}
                onSortingChanged={onSortingChanged}
                visibleColumns={visibleColumns}
                onVisibleColumnsChanged={setVisibleColumns}
                density={density}
                onDensityChanged={setDensity}
                apiModel={queryData.apiModel}
            // eslint-disable-next-line react-hooks/exhaustive-deps
            />, [density, isSearching, paging, result, sorting, visibleColumns])}
        </Stack>
    )
}