import { useCallback } from 'react'

import { ICountyFilterData, useCountyFilter } from '../../../Common/Hooks/Filters/useCountyFilter'
import { IEligibilityFilterData, useEligibilityFilter } from '../../../Common/Hooks/Filters/useEligibilityFilter'
import {
  IFilterVisibilityToggleData,
  useFilterVisibilityToggle,
} from '../../../Common/Hooks/Filters/useFilterVisibilityToggle'
import { IFreeTextSearchFieldData, useFreeTextSearchField } from '../../../Common/Hooks/Filters/useFreeTextSearchField'
import { IMunicipalityFilterData, useMunicipalityFilter } from '../../../Common/Hooks/Filters/useMunicipalityFilter'
import { IWorkplaceTypeFilterData, useWorkplaceTypeFilter } from '../../../Common/Hooks/Filters/useWorkplaceTypeFilter'
import { ITrrIndustryFilterData, useTrrIndustryFilter } from '../../../Common/Hooks/Filters/useTrrIndustryFilter'

import { IContactResponsibleFilterData, useContactResponsibleFilter } from './useContactResponsibleFilter'
import { IContactStatusFilterData, useContactStatusFilter } from './useContactStatusFilter'
import { IContactCategoryFilterData, useContactCategoryFilter } from './useContactCategoryFilter'
import { IContactRoleFilterData, useContactRoleFilter } from './useContactRoleFilter'
import { IUnionFilterData, useUnionFilter } from './useUnionFilter'
import { IIsMainContactFilterData, useIsMainContactFilter } from './useIsMainContactFilter'

export interface IContactSearchFilterData {
  filterVisibilityData: IFilterVisibilityToggleData
  freeTextSearchData: IFreeTextSearchFieldData
  countyData: ICountyFilterData
  municipalityData: IMunicipalityFilterData
  workplaceTypeData: IWorkplaceTypeFilterData
  eligibilityData: IEligibilityFilterData
  contactResponsibleData: IContactResponsibleFilterData
  contactStatusData: IContactStatusFilterData
  contactCategoryData: IContactCategoryFilterData
  contactRoleData: IContactRoleFilterData
  unionData: IUnionFilterData
  trrIndustryData: ITrrIndustryFilterData
  isMainContactData: IIsMainContactFilterData
  wasAnyFilterLoadedFromUrl: boolean
  resetFilters: () => void
}

export const useContactSearchFilters = (): IContactSearchFilterData => {
  const filterVisibilityData = useFilterVisibilityToggle()
  const freeTextSearchData = useFreeTextSearchField()
  const countyData = useCountyFilter()
  const municipalityData = useMunicipalityFilter(countyData.selection)
  const workplaceTypeData = useWorkplaceTypeFilter()
  const eligibilityData = useEligibilityFilter()
  const contactResponsibleData = useContactResponsibleFilter()
  const contactStatusData = useContactStatusFilter()
  const contactCategoryData = useContactCategoryFilter()
  const contactRoleData = useContactRoleFilter(contactCategoryData.selection)
  const unionData = useUnionFilter()
  const trrIndustryData = useTrrIndustryFilter()
  const isMainContactData = useIsMainContactFilter()

  const wasAnyFilterLoadedFromUrl =
    freeTextSearchData.wasLoadedFromUrl ||
    countyData.wasLoadedFromUrl ||
    municipalityData.wasLoadedFromUrl ||
    workplaceTypeData.wasLoadedFromUrl ||
    eligibilityData.wasLoadedFromUrl ||
    contactResponsibleData.wasLoadedFromUrl ||
    contactStatusData.wasLoadedFromUrl ||
    contactCategoryData.wasLoadedFromUrl ||
    contactRoleData.wasLoadedFromUrl ||
    unionData.wasLoadedFromUrl ||
    trrIndustryData.wasLoadedFromUrl ||
    isMainContactData.wasLoadedFromUrl

  const resetFilters = useCallback(() => {
    freeTextSearchData.setValue('')
    countyData.setSelection([])
    municipalityData.setSelection([])
    workplaceTypeData.setSelection([])
    eligibilityData.setSelection([])
    contactResponsibleData.setSelection([])
    contactStatusData.setSelection([])
    contactCategoryData.setSelection([])
    contactRoleData.setSelection([])
    unionData.setSelection([])
    trrIndustryData.setSelection([])
    isMainContactData.setSelection('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    filterVisibilityData,
    freeTextSearchData,
    countyData,
    municipalityData,
    workplaceTypeData,
    eligibilityData,
    contactResponsibleData,
    contactStatusData,
    contactCategoryData,
    contactRoleData,
    unionData,
    trrIndustryData,
    isMainContactData,
    wasAnyFilterLoadedFromUrl,
    resetFilters,
  }
}
