import { connect } from 'react-redux'
import { RootState } from '@local/Store/configureStore'
import { formatUtcDate } from '@local/Utils/helpers'

import Activities, { IActivities } from './Activities'
import { ActivityModel } from './Activities.model'

const formatActivities = (activities: ActivityModel[]) =>
  !activities
    ? activities
    : activities.map(activity => {
      return {
        ...activity,
        range: {
          ...activity.range,
          start: formatUtcDate(activity.range.start),
        },
      }
    })

const getActivitiesQuantity = (activities: ActivityModel[]) => (activities ? activities.length : 0)

const mapStateToProps = (state: RootState) => ({
  activities: formatActivities(state.activity.activities),
  quantity: getActivitiesQuantity(state.activity.activities),
  loaded: state.activity.activitiesLoaded
} as IActivities)

export default connect(mapStateToProps)(Activities)
