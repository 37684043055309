import { ICounty } from '@local/src/Models/Domain/County.model'
import { CountryCode } from '@local/src/App.types'
import { IMunicipality } from '@local/src/Models/Domain/Municipality.model'

import { organisationsregistretApi } from '../organisationsregistretApi'

export const regionsApi = organisationsregistretApi.injectEndpoints({
  endpoints: (builder) => ({
    getCounties: builder.query<ICounty[], void>({
      query: () => ({ url: 'regions/counties', method: 'GET' }),
      transformResponse: (data: { countyName: string; countyId: string }[]) =>
        data.map((d) => ({ id: d.countyId, name: d.countyName }) as ICounty),
    }),
    getCountryCodes: builder.query<CountryCode[], void>({
      query: () => ({ url: 'regions/countryCodes', method: 'GET' }),
      transformResponse: (data: { countryCode: string; phoneCode: string; countryName: string }[]) =>
        data.map((d) => ({ value: d.countryCode, label: d.countryName, phoneCode: d.phoneCode }) as CountryCode),
    }),
    getMunicipalities: builder.query<IMunicipality[], void>({
      query: () => ({ url: 'regions/municipalities', method: 'GET' }),
      transformResponse: (data: { municipalityId: string; municipalityName: string; countyId: string }[]) =>
        data.map(
          (d) =>
            ({
              id: d.municipalityId,
              name: d.municipalityName,
              countyId: d.countyId,
            }) as IMunicipality
        ),
    }),
  }),
})

export const { useGetCountiesQuery, useGetCountryCodesQuery, useGetMunicipalitiesQuery } = regionsApi
