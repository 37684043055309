import React from 'react'
import { Box, Typography } from '@mui/material'

interface QuantityWithDateProps {
  quantity: number | string
  date: string
}

const QuantityWithDate = ({
  quantity,
  date,
}: QuantityWithDateProps): JSX.Element => {

  const isDateVisible = quantity !== null && quantity !== 0 && date !== '0001-01-01T11:00:00'

  return (
    <Box display='flex'>
      <Typography variant='body1' paddingRight={2} color='text.secondary'>{quantity || 'Data saknas'}</Typography>
      {isDateVisible && (
        <Typography variant='body1' paddingLeft={0.5} color='text.secondary'>{date.substring(0, 10)}</Typography>
      )}
    </Box>
  )
}

export default QuantityWithDate
