import { ISelectionData } from '@local/src/Components/Inputs/Selection/Common/ISelectionData'
import { IContactCategory } from '@local/src/Models/Domain/ContactCategory.model'
import { IContactRole } from '@local/src/Models/Domain/ContactRole.model'
import { IUrlSyncedData } from '@local/src/Utils/helpers/Url/IUrlSyncedData'
import { useUrlSynchedEntityOptionsSelection } from '@local/src/Utils/helpers/Url/useUrlSynchedEntityOptionsSelection'
import { useGetContactRolesQuery } from '@local/src/Utils/network/endpoints/workplacesApi'

export interface IContactRoleFilterData extends IUrlSyncedData, ISelectionData<IContactRole> {}

export const useContactRoleFilter = (selectedCategories: IContactCategory[]): IContactRoleFilterData => {
  const { selection, setSelection, options, isLoading, isError, wasLoadedFromUrl } =
    useUrlSynchedEntityOptionsSelection<IContactRole>('roles', useGetContactRolesQuery)

  const filteredOptions =
    selectedCategories.length === 0
      ? options
      : options.filter((o) => selectedCategories.map((c) => c.id).includes(o.categoryId))

  const deduplicatedOptions = filteredOptions.filter((o, i, a) => a.findIndex((entry) => entry.id === o.id) === i)
  const sortedOptions = deduplicatedOptions.sort((a, b) => a.id.localeCompare(b.id))

  return {
    selection,
    setSelection,
    options: sortedOptions,
    isLoading,
    isError,
    wasLoadedFromUrl,
  }
}
