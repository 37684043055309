import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@local/Store/configureStore'

export const projectCasesSelector = createSelector(
  (state: RootState) => state.project.cases,
  cases => cases
)

export const canEditProjectCasesSelector = createSelector(
  (state: RootState) => state.project.canEditCases,
  canEditCases => canEditCases
)

export const projectSelector = createSelector(
  (state: RootState) => state.project.project,
  project => project
)

export const fetchCasesFailureSelector = createSelector(
  (state: RootState) => state.project.caseFailure,
  caseFailure => caseFailure
)

export const linkingCasesSelector = createSelector(
  (state: RootState) => state.project.linkingCases,
  linkingCases => linkingCases
)

export const arendeIdUnderDeletionSelector = createSelector(
  (state: RootState) => state.project.arendeIdUnderDeletion,
  arendeIdUnderDeletion => arendeIdUnderDeletion
)

export const uploadFileInProgressSelector = createSelector(
  (state: RootState) => state.project.fileUploadInProgress,
  fileUploadInProgress => fileUploadInProgress
)

export const projectAttachmentsSelector = createSelector(
  (state: RootState) => state.project.projectAttachments,
  projectAttachments => projectAttachments
)

export const projectStatusesSelector = createSelector(
  (state: RootState) => state.project.projectStatuses,
  projectStatuses => projectStatuses
)

export const updateProjectInProgressSelector = createSelector(
  (state: RootState) => state.project.updateProjectInProgress,
  updateProjectInProgress => updateProjectInProgress
)

export const createProjectInProgressSelector = createSelector(
  (state: RootState) => state.project.createProjectInProgress,
  createProjectInProgress => createProjectInProgress
)

export const projectSaveFailedSelector = createSelector(
  (state: RootState) => state.project.saveFail,
  saveFail => saveFail
)

export const detailedProjectFetchFailureSelector = createSelector(
  (state: RootState) => state.project,
  project => project.detailedProjectFetchFailure
)

export const deleteAttachmentFailureSelector = createSelector(
  (state: RootState) => state.project,
  project => project.deleteFail
)

export const uploadAttachmentErrorSelector = createSelector(
  (state: RootState) => state.project,
  project => project.uploadAttachmentError
)

export const deleteProjectInProgressSelector = createSelector(
  (state: RootState) => state.project,
  project => project.deleteInProgress
)

export const fileNameValidationFailureSelector = createSelector(
  (state: RootState) => state.project,
  project => project.fileNameValidationFailure
)

export const downloadAttachmentFailureSelector = createSelector(
  (state: RootState) => state.project,
  project => project.downloadAttachmentFailure
)

export const fetchAttachmentsFailureSelector = createSelector(
  (state: RootState) => state.project,
  project => project.fetchAttachmentsFailure
)
