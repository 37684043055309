import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Home from '../Scenes/Home'
import Company from '../Scenes/Company'

import { RouteType } from './UI.types'
import LayoutContainer from './LayoutContainer/LayoutContainer'
import routes from './Routes'

const UI = (): JSX.Element => {
  const pageTypes = {
    Home,
    Company,
  }

  return (
    <LayoutContainer>
      <Switch>
        {routes.map(({ path, pagetype }: RouteType) => {
          return <Route key={path} path={path} component={pageTypes[pagetype]} exact />
        })}
        <Route
          render={() => (
            <div>
              <h1>404</h1>
            </div>
          )}
        />
      </Switch>
    </LayoutContainer>
  )
}

export default UI
