import { ISelectionData } from '@local/src/Components/Inputs/Selection/Common/ISelectionData'
import { IUnion } from '@local/src/Models/Domain/Union.model'
import { IUrlSyncedData } from '@local/src/Utils/helpers/Url/IUrlSyncedData'
import { useUrlSynchedEntityOptionsSelection } from '@local/src/Utils/helpers/Url/useUrlSynchedEntityOptionsSelection'
import { useGetUnionsQuery } from '@local/src/Utils/network/endpoints/unionsApi'

export interface IUnionFilterData extends IUrlSyncedData, ISelectionData<IUnion> {}

export const useUnionFilter = (): IUnionFilterData =>
  useUrlSynchedEntityOptionsSelection<IUnion>('unions', useGetUnionsQuery)
