import React from "react"
import { IMunicipality } from "@local/src/Models/Domain/Municipality.model"

import { SelectionInput } from "./Common/SelectionInput"
import { ISelectionData } from "./Common/ISelectionData"

interface Props extends ISelectionData<IMunicipality> {
    disabled?: boolean
}

export const MunicipalitySelectionInput = React.memo((props: Props) => {
    return <SelectionInput
        {...props}
        name="Kommun"
        getDisplayValue={o => o.name}
    />
})