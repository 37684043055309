import React, { useState } from 'react'
import DataGridWrapper from '@local/src/Components/DataGridWrapper/DataGridWrapper'
import { Box, Button, Stack, Typography } from '@mui/material'
import InformationPopover from '@local/src/Components/InformationPopover/InformationPopover'
import ConfirmationModal from '@local/src/Components/ConfirmationModal/ConfirmationModal'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@local/Store/configureStore'

import { ICase } from '../Case.types'
import { deleteCaseFromProject, linkProjectCasesByProjectId } from '../Projects.actions'
import {
  arendeIdUnderDeletionSelector,
  canEditProjectCasesSelector,
  fetchCasesFailureSelector,
  linkingCasesSelector,
  projectCasesSelector,
} from '../Projects.selectors'

import { casesColumns, getCaseColumnVisibilityModel } from './CasesTable.columns'

interface CasesTableProps {
  projektId: string
  huvudForetagId: string
}

const CasesTable = ({ projektId, huvudForetagId }: CasesTableProps) => {
  const dispatch = useAppDispatch()
  const [modalIsActive, setModalIsActive] = useState<boolean>(false)
  const [caseToBeDeleted, setCaseToBeDeleted] = useState<string>(null)

  const cases = useSelector(projectCasesSelector)
  const canEditCases: boolean = useSelector(canEditProjectCasesSelector)

  const caseFailure: boolean = useSelector(fetchCasesFailureSelector)
  const linkingCases: boolean = useSelector(linkingCasesSelector)
  const arendeIdUnderDeletion: string = useSelector(arendeIdUnderDeletionSelector)

  const numberOfSearchHits = cases?.length ? `${cases.length}` : '0'
  const tableText = caseFailure ? 'Något gick fel när ärenden skulle hämtas' : 'Inga ärenden funna'
  const buttonText = linkingCases ? 'Uppdaterar listan...' : 'Uppdatera listan'

  const linkCases = () => {
    dispatch(linkProjectCasesByProjectId(huvudForetagId, projektId))
  }

  const onDeleteBtnPressed = (arendeId: string) => {
    setCaseToBeDeleted(arendeId)
    setModalIsActive(true)
  }

  const handleDeleteCase = () => {
    setModalIsActive(false)
    dispatch(deleteCaseFromProject(huvudForetagId, projektId, caseToBeDeleted))
  }

  return (
    <Stack>
      <Box display="flex" justifyContent="space-between" marginBottom={1} marginTop={5}>
        <Typography variant="subtitle2" alignSelf="center">
          {numberOfSearchHits} ärenden
        </Typography>
        <Box display="flex" gap={1}>
          <Button variant="outlined" size="small" onClick={linkCases} disabled={linkingCases}>
            {buttonText}
          </Button>
          <InformationPopover infoText="Söker efter uppdateringar på ärenden som är kopplade till projektets Omställningskunder" />
        </Box>
      </Box>

      <DataGridWrapper
        rows={cases}
        columns={casesColumns(onDeleteBtnPressed, arendeIdUnderDeletion)}
        getRowId={(row: ICase) => row.arendeId}
        labelRowsPerPage="Ärenden per sida"
        labelNoRows={tableText}
        columnVisibilityModel={getCaseColumnVisibilityModel(canEditCases)}
      />

      <ConfirmationModal
        title="Bekräfta borttagning"
        message="Är du säker på att du vill ta bort detta ärende från projektet?"
        active={modalIsActive}
        close={() => setModalIsActive(false)}
        submit={handleDeleteCase}
      />
    </Stack>
  )
}

export default CasesTable
