import React from 'react'
import { Provider } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material'
import { themeOptions, defaultLocale } from '@trr/mui-theme'
import { svSE as svSEDataGrid } from '@mui/x-data-grid'
import { LocalizationProvider, svSE } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { sv } from 'date-fns/locale'

import Store from './Store/configureStore'
import UI from './UI/UI'

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {

  const theme = createTheme(themeOptions, defaultLocale, svSE, svSEDataGrid)

  return (
    <ThemeProvider theme={theme}>
      <AppShellDataProvider value={appShellData}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
          <Provider store={Store}>
            <UI />
          </Provider>
        </LocalizationProvider>
      </AppShellDataProvider>
    </ThemeProvider>
  )
}

export default App
