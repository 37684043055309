import { IPagingModel } from '@local/src/Models/Misc/Paging.model'
import { ISortingModel } from '@local/src/Models/Misc/Sorting.model'
import { IWorkplaceSearchResult } from '@local/src/Models/WorkplaceSearch/IWorkplaceSearchResult.model'
import { useLazySearchWorkplacesQuery } from '@local/src/Utils/network/endpoints/WorkplacesSearch/workplacesSearchApi'
import { isOrgNumber } from '@local/src/Utils/helpers'
import { IWorkplacesSearchApiModel } from '@local/src/Utils/network/endpoints/WorkplacesSearch/IWorkplacesSearchApiModel'
import { useCallback, useState } from 'react'

import { IWorkplaceSearchFilterData } from '../Filters/useWorkplaceSearchFilters'

export interface IWorkplaceSearchQueryData {
  result?: IWorkplaceSearchResult
  performSearch: (filters: IWorkplaceSearchFilterData, paging: IPagingModel, sorting: ISortingModel) => void
  isSearching: boolean
  didSearchFail: boolean
  hasSearched: boolean
  clearSearch: () => void
}

export const useWorkplaceSearchQuery = (): IWorkplaceSearchQueryData => {
  const [searchWorkplaces, { data, isFetching, isError }] = useLazySearchWorkplacesQuery()
  const [hasSearched, setHasSearched] = useState<boolean>(false)

  const performSearch = useCallback(
    (filters: IWorkplaceSearchFilterData, paging: IPagingModel, sorting: ISortingModel) => {
      setHasSearched(true)
      void searchWorkplaces(mapToWorkplacesSearchApiModel(filters, paging, sorting))
    },
    [searchWorkplaces]
  )

  const clearSearch = useCallback(() => {
    setHasSearched(false)
  }, [])

  return {
    result: data,
    performSearch,
    isSearching: isFetching,
    didSearchFail: isError,
    hasSearched,
    clearSearch,
  }
}

const mapToWorkplacesSearchApiModel = (
  filters: IWorkplaceSearchFilterData,
  paging: IPagingModel,
  sorting: ISortingModel
): IWorkplacesSearchApiModel => ({
  fullName: isOrgNumber(filters.freeTextSearchData.value) ? undefined : filters.freeTextSearchData.value,
  organizationalNumber: isOrgNumber(filters.freeTextSearchData.value) ? filters.freeTextSearchData.value : undefined,
  countyCode: filters.countyData.selection?.map((c) => c.id),
  companyStatuses: filters.workplaceStatusData.selection?.map((ws) => ws.id),
  companyTypes: filters.workplaceTypeData.selection?.map((wt) => wt.id),
  eligibilityStatuses: filters.eligibilityData.selection?.map((e) => e.id),
  localContact: filters.localContactData.selection?.map((lc) => lc.id),
  localCustomerManager: filters.localManagerData.selection?.map((lm) => lm.id),
  locallyImportant: filters.isLocallyImportantData.value,
  municipalityCode: filters.municipalityData.selection?.map((m) => m.id),
  nationalCustomerManager: filters.nationalManagerData.selection?.map((nm) => nm.id),
  payrollRange: filters.payrollData.value,
  whiteCollarRangeTrr: filters.whiteCollarData.value,
  trrIndustries: filters.trrIndustryData.selection?.map((ti) => ti.id),
  skip: paging.skip,
  count: paging.take,
  orderBy: [
    {
      property: sorting.property,
      order: sorting.order,
    },
  ],
})
