import { useLocalStorage } from '@local/src/hooks/useLocalStorage'
import { ISortingModel } from '@local/src/Models/Misc/Sorting.model'

import { COLUMN_NAMES } from '../../workplaceSearchResultColumns'

export interface IWorkplaceSearchSortingData {
  sorting: ISortingModel
  setSorting: (value: ISortingModel) => void
}

export const DEFAULT_SORTING = { property: COLUMN_NAMES[1], order: 'ascending' } as ISortingModel

export const useWorkplaceSearchSorting = (): IWorkplaceSearchSortingData => {
  const [sorting, setSorting] = useLocalStorage('workplace-search-sorting', DEFAULT_SORTING)

  return {
    sorting,
    setSorting,
  }
}
